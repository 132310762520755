import { type ReactElement } from 'react'
import { Alert, type AlertColor, Snackbar } from '@mui/material'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { isEmpty } from '../../../utils/common-methods'
import { namespaces } from '../../../core/i18n/i18n.constants'
import closeIcon from '../../../assets/img/alert-close-icon.svg'
import { type StyledComponentInterface } from '../../../types/styled-components'

const StyledAlert = styled.div<StyledComponentInterface>`
  .alert-snack-bar {
    width: calc(100% - 40%);
  }
  .alert-success {
    width: 100%;
    border: 1px solid var(--dark-green);
    color: var(--light-grey-600);
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    border-radius: 8px;
  }
  .alert-warning {
    border: 1px solid var(--dark-red);
    background: var(--light-red-100);
    color: var(--light-grey-600);
    width: calc(100% - 10%);
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    border-radius: 8px;
  }
  .alert-sub-text {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
  }

  .MuiSvgIcon-root {
    margin-top: 5px;
    color: ${(props: any) =>
      props.styles.warning === 'warning' ? 'var(--dark-red)' : ''};
  }
  .alert-close-icon {
    margin-top: 10px;
    margin-right: 16px;
    cursor: pointer;
  }
`

interface AlertProps {
  autoClose: boolean
  open: boolean
  message: string
  messageFormatting?: boolean
  severity: string
  onClose: () => void
}

const AlertMessage = ({
  open,
  message,
  severity,
  autoClose,
  messageFormatting = false,
  onClose,
}: AlertProps): ReactElement => {
  let messageFormatted: string[] = ['']
  const { t } = useTranslation([namespaces.common.server])

  if (!isEmpty(message) && messageFormatting) {
    messageFormatted = message?.split(/[.]/) ?? ''
  }

  return (
    <StyledAlert styles={{ warning: severity }}>
      <Snackbar
        open={open}
        autoHideDuration={autoClose ? 5000 : null}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={onClose}
        className="alert-snack-bar"
      >
        <Alert
          className={`alert-${severity}`}
          severity={severity as AlertColor}
          action={
            <img
              src={closeIcon}
              className="alert-close-icon"
              onClick={onClose}
            />
          }
        >
          {messageFormatting ? `${messageFormatted?.[0]}.` : message}

          {messageFormatting &&
            !isEmpty(messageFormatted?.[0]) &&
            messageFormatted?.[0].includes(t('emailSentSuccessfully')) && (
              <br />
            )}

          {messageFormatting && !isEmpty(messageFormatted?.[1]) && (
            <span className="alert-sub-text">{`${messageFormatted?.[1]}.`}</span>
          )}
        </Alert>
      </Snackbar>
    </StyledAlert>
  )
}

export default AlertMessage
