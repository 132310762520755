import { gql } from '@apollo/client'

const CREATE_AGENT = gql`
  mutation CreateAgent($input: CreateAgentInput!) {
    createAgent(input: $input) {
      success
      data {
        id
        email
        role
        firstName
        lastName
        state
        group
      }
      errors
    }
  }
`
export default CREATE_AGENT
