import { gql } from '@apollo/client'

export const BUILD_MODEL_STATISTICS = gql`
  query buildModelStatistic($input: BuildModelStatisticInput!) {
    buildModelStatistic(input: $input) {
      buildModel
      code
      mean
    }
  }
`
