import { type ReactElement, useEffect } from 'react'
import { Route, Switch, useHistory, useLocation } from 'react-router-dom'
import { Security, SecureRoute, LoginCallback } from '@okta/okta-react'

import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js'
import config, { getAvailableTenant } from '../../core/auth/config'

import Login from '../login'
import Dashboard from '../dashboard'
import PageNotFound from '../../components/page-not-found'
import { initMixpanel } from '../../core/analytics'
import { expireSession } from './app-slice'
import { useAppDispatch } from '../../store'
import { scrollToTop } from '../../utils/common-methods'

initMixpanel()

const oktaAuth = new OktaAuth(config.oidc)

const App = (): ReactElement => {
  const tenant = getAvailableTenant()

  const history = useHistory()
  const location = useLocation()

  const dispatch = useAppDispatch()

  const redirectFromLegacyUri = (): boolean => {
    const legacyUrlMapping = new Map<string, string>([
      [
        'https://xfinity.pocketgeek.com',
        'https://xfinity.agent.pocketgeek.com',
      ],
      ['https://my.pocketgeek.com', 'https://assurant.agent.pocketgeek.com'],
    ])

    const domainUrl = window.location.origin
    const legacyUrlMapped = legacyUrlMapping.get(domainUrl)

    if (legacyUrlMapped !== undefined) {
      console.info('Redirecting user from legacy domain')
      window.location.replace(legacyUrlMapped)
      return true
    } else {
      return false
    }
  }

  const onAuthResume = (): void => {
    history.push('/login')
  }
  const restoreOriginalUri = async (
    oktaAuth: any,
    originalUri: any
  ): Promise<void> => {
    history.replace(
      toRelativeUrl(
        (originalUri ?? '/') !== '' ? originalUri : '/',
        window.location.origin
      )
    )
  }
  const customAuthHandler = async (): Promise<void> => {
    const previousAuthState = oktaAuth.authStateManager.getPreviousAuthState()
    const currentAuthState = oktaAuth.authStateManager.getAuthState()
    if (
      previousAuthState == null ||
      previousAuthState.isAuthenticated === null ||
      currentAuthState?.isAuthenticated === false
    ) {
      console.warn('User session has expired or has been closed')
      dispatch(expireSession(false))
      history.replace('/login')
    }
  }
  const handleOktaService = (): void => {
    console.info('Starting oktaAuth as service')
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    oktaAuth.start()
  }

  useEffect(() => {
    scrollToTop()
  }, [location])

  useEffect(() => {
    if (redirectFromLegacyUri()) {
      return
    }
    handleOktaService()
  }, [])

  return (
    <div className="app">
      <Security
        oktaAuth={oktaAuth}
        restoreOriginalUri={restoreOriginalUri}
        onAuthRequired={customAuthHandler}
      >
        <Switch>
          <Route
            path="/"
            exact
            render={(props: any): React.ReactNode => (
              <Login {...props} tenant={tenant} />
            )}
          />
          <Route
            path="/authorization-code/callback"
            render={(props: any): React.ReactNode => (
              <LoginCallback {...props} onAuthResume={onAuthResume} />
            )}
          />
          <Route
            exact
            path="/login"
            render={(props: any): React.ReactNode => (
              <Login {...props} tenant={tenant} />
            )}
          />
          <SecureRoute
            path="/dashboard"
            render={(props: any): React.ReactNode => (
              <Dashboard {...props} tenant={tenant} />
            )}
          />
          <Route path="*" component={PageNotFound} />
        </Switch>
      </Security>
    </div>
  )
}

export default App
