export const languages = {
  es: 'es',
  en: 'en',
}

export const namespaces = {
  pages: {
    auth: 'auth',
    cookie: 'cookie',
    login: 'login',
    dashboard: 'dashboard',
    signal: 'signal',
    battery: 'battery',
    storage: 'storage',
    settings: 'settings',
    overview: 'overview',
    header: 'header',
    footer: 'footer',
    deviceStatus: 'device-status',
    searchDevices: 'search-devices',
    manageUser: 'manageUser',
    activity: 'activity,',
    audio: 'audio',
    manageAccount: 'manageAccount',
  },
  common: {
    button: 'button',
    server: 'server',
    text: 'text',
    error: 'error',
    calendar: 'calendar',
  },
}
