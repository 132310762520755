import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import { languages } from './i18n.constants'
import { es, en } from './i18n.translations'
import LanguageDetector from 'i18next-browser-languagedetector'

const i18n = i18next.createInstance().use(initReactI18next)
void i18n.use(LanguageDetector).init({
  fallbackLng: 'en',
  resources: {
    [languages.en]: en,
    [languages.es]: es,
  },
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: false,
  },
})

export const parseLang = (code: string): string => {
  let lang = ''
  if (code === 'en' || code === 'es') {
    lang = code
  } else {
    const formattedCode = code.split('-')
    lang = formattedCode[0]
  }

  return lang
}
export const getCurrentLanguage = (): string => {
  return parseLang(i18n.language)
}
export const handleUpdateLanguage = (lang: string): void => {
  void i18n.changeLanguage(parseLang(lang))
}

handleUpdateLanguage(i18n.language)

export default i18n
