import { type ReactElement, useState, type MouseEvent } from 'react'
import styled from 'styled-components'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { isEmpty } from '../../../utils/common-methods'
import { type StyledComponentInterface } from '../../../types/styled-components'

const StyledDropdown = styled.div<StyledComponentInterface>`
  .dropdown__button {
    color: ${(props: any) => props.styles.color};
    margin: 0px 10px;
    font-size: 16px;
    line-height: 26px;
    text-transform: initial;
  }
`

interface MenuDataInterface {
  id: number
  cb: () => void
  name: string
  url?: string
}
interface DropdownMenuInterface {
  menu: string
  menuData: MenuDataInterface[]
  color: string
}

const DropdownMenu = (props: DropdownMenuInterface): ReactElement => {
  const { menuData = [] } = props
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (): void => {
    setAnchorEl(null)
  }
  const handleListClick = (cb: Function): void => {
    if (typeof cb === 'function') {
      cb()
    }
    handleClose()
  }

  const paperStyle = {
    border: '1px solid var(--primary-blue)',
    width: 214,
    minHeight: 60,
    height: 'auto',
    left: 1190,
    top: 86,
    padding: 4,
    gap: 4,
    borderRadius: 6,
    background: 'var(--white)',
  }

  return (
    <StyledDropdown
      styles={{
        color: props.color,
      }}
    >
      <div className="dropdown">
        <Button
          id="dropdown__button"
          className="dropdown__button"
          aria-controls={open ? 'dropdown__menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          endIcon={<KeyboardArrowDownIcon />}
        >
          {props.menu}
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{ 'aria-labelledby': 'basic-button' }}
          PaperProps={{
            style: paperStyle,
          }}
        >
          {Array.isArray(menuData) &&
            menuData?.length > 0 &&
            menuData.map(({ name, id, url, cb }) => {
              return (
                <MenuItem
                  key={id?.toString()}
                  onClick={(): void => {
                    handleListClick(cb)
                  }}
                >
                  {!isEmpty(url) ? (
                    <a className="menu-item__a" href={url as string}>
                      {name}
                    </a>
                  ) : (
                    <span className="menu-item__a">{name}</span>
                  )}
                </MenuItem>
              )
            })}
        </Menu>
      </div>
    </StyledDropdown>
  )
}
export default DropdownMenu
