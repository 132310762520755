import { gql } from '@apollo/client'

const UPDATE_AGENT = gql`
  mutation UpdateAgent($input: UpdateAgentInput!) {
    updateAgent(input: $input) {
      success
      data {
        id
        email
        role
        firstName
        lastName
        state
        group
      }
      errors
    }
  }
`
export default UPDATE_AGENT
