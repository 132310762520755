export const DashboardTabRouteMapper = [
  {
    tabIndex: 0,
    route: 'overview',
  },
  {
    tabIndex: 1,
    route: 'battery',
  },
  {
    tabIndex: 2,
    route: 'signal',
  },
  {
    tabIndex: 3,
    route: 'storage',
  },
  {
    tabIndex: 4,
    route: 'audio',
  },
  {
    tabIndex: 5,
    route: 'settings',
  },
]
