import {
  type ReactElement,
  useEffect,
  useState,
  useContext,
  createContext,
} from 'react'
import { useOktaAuth } from '@okta/okta-react'
import styled from 'styled-components'
import ManageUserTable from './manage-user-table'
import { COLUMNS } from './columns'
import MainLayout from '../../layouts/main/main.layout'
import client from '../../services/apollo-client'
import AGENTS from '../../services/user-administration/queries/agents'
import { Mixpanel, isMixpanelAllowed } from '../../core/analytics'
import CustomEditButton from './custom-edit-button'
import { checkAuthError, isEmpty, isPtp } from '../../utils/common-methods'
import { RootContext } from '../dashboard'

const StyledEmptyActionField = styled.div`
  .edit_action {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    margin-left: 40px;
  }
`
const initialUserContext = {
  fetchUsers: (): void => {},
  manageLoader: (show: boolean): void => {
    console.info('Fetching users... ', show)
  },
  clearFilters: (clear: boolean): void => {
    console.info('Clearing filters... ', clear)
  },
  setSelectedPageNum: (pageNumber: number): void => {
    console.info('Updating pagination... ', pageNumber)
  },
}
export const UserContext = createContext(initialUserContext)

export const agentStateMapping = {
  RECOVERY: 'PASSWORD RESET',
  CSR_MANAGER: 'CSR MANAGER',
  LOCKED: 'LOCKED OUT',
}

const ManageUsers = (): ReactElement => {
  const { user } = useContext(RootContext)
  const [pageNum, setPageNum] = useState(1)
  const { authState } = useOktaAuth()
  const [showLoader, setShowLoader] = useState(false)
  const [usersList, setUsersList] = useState([])
  const [dataColumns, setDataColumns] = useState(COLUMNS)
  const [clearAllFilters, setClearFilters] = useState(false)
  const [selected, setSelected] = useState(1)

  const { handleAuthError } = useContext(RootContext)

  const oktaBlackListStates = ['DEPROVISIONED', 'SUSPENDED', 'PASSWORD_EXPIRED']

  const clearFilters = (clear: boolean): void => {
    setClearFilters(clear)
  }

  const setSelectedPageNum = (pageNumber: number): void => {
    setPageNum(pageNumber)
  }

  const stateTextFormatiing = (agentState: any): string => {
    if (isEmpty(agentState)) {
      return ''
    }
    if (agentState?.toUpperCase() === 'RECOVERY') {
      return agentStateMapping.RECOVERY
    } else if (agentState?.toUpperCase() === 'LOCKED_OUT') {
      return agentStateMapping.LOCKED
    } else {
      return agentState
    }
  }

  const EmptyActionField = (): ReactElement => {
    return (
      <StyledEmptyActionField>
        <div className="edit_action">{'-'}</div>
      </StyledEmptyActionField>
    )
  }

  const EditColumn = {
    Header: 'Action',
    Footer: 'Action',
    accessor: 'action',
    disableSortBy: true,
    disableGlobalFilter: true,
    Cell: (tableProps: any) =>
      user.email === tableProps?.row?.original?.email ? (
        <EmptyActionField />
      ) : (
        <CustomEditButton
          tableProps={tableProps}
          clearAllFilters={(): void => {
            setClearFilters(true)
          }}
        />
      ),
  }

  const fetchUsers = (): void => {
    clearFilters(true)
    setClearFilters(true)
    setShowLoader(true)
    client
      .query({
        query: AGENTS,
        fetchPolicy: 'network-only',
        context: {
          headers: {
            Authorization: authState?.accessToken?.accessToken,
          },
        },
      })
      .then((response: any) => {
        setSelectedPageNum(1)
        setUsersList(
          response?.data?.agents
            ?.filter(
              (agent: any) =>
                !oktaBlackListStates.includes(agent?.state?.toUpperCase())
            )
            ?.map((agent: any) => ({
              id: agent?.id,
              first_name: agent?.firstName,
              last_name: agent.lastName,
              role: agent?.role?.replace('_', ' '),
              state: stateTextFormatiing(agent?.state),
              action: true,
              email: agent?.email,
            }))
        )
      })
      .catch((error: unknown | any) => {
        console.error('Error fetching users list: ', error)

        if (checkAuthError(error)) {
          handleAuthError()
        }
      })
      .finally(() => {
        setSelected(1)
        setShowLoader(false)
        setClearFilters(false)
      })
  }

  const manageLoader = (show: boolean): void => {
    setShowLoader(show)
  }

  useEffect(() => {
    // Mixpanel
    if (isMixpanelAllowed()) {
      try {
        Mixpanel.track('User active in Manage-Users page')
      } catch (e) {
        Mixpanel.track('Not able to track User in Manage-Users page')
      }
    }
  }, [])

  useEffect(() => {
    if (!isPtp(user)) {
      const updatedColumns = [...dataColumns]
      updatedColumns.push(EditColumn)
      setDataColumns(updatedColumns)
    }
    fetchUsers()
  }, [])

  return (
    <MainLayout>
      <UserContext.Provider
        value={{
          fetchUsers,
          manageLoader,
          clearFilters,
          setSelectedPageNum,
        }}
      >
        <ManageUserTable
          columnsList={dataColumns}
          userDataList={usersList}
          showLoader={showLoader}
          setShowLoader={setShowLoader}
          clearAllFilters={clearAllFilters}
          selected={selected}
          setSelected={setSelected}
          pageNum={pageNum}
        />
      </UserContext.Provider>
    </MainLayout>
  )
}

export default ManageUsers
