import {
  type ReactElement,
  useState,
  useRef,
  useEffect,
  useContext,
} from 'react'
import styled from 'styled-components'
import { Backdrop } from '@mui/material'
import { useOktaAuth } from '@okta/okta-react'
import { useTranslation } from 'react-i18next'
import { namespaces } from '../../core/i18n/i18n.constants'
import { type StyledComponentInterface } from '../../types/styled-components'
import {
  DropDownDataActive,
  DropDownDataPending,
  DropDownPasswordReset,
  DropDownLokedOut,
} from './edit-dropdown-data'
import ConfirmationModal from '../../components/confirmation-modal'
import { ReactComponent as DownArrow } from '../../assets/img/down-arrow.svg'
import { ReactComponent as UpArrow } from '../../assets/img/up-arrow.svg'
import client from '../../services/apollo-client'
import DEACTIVATE_AGENT from '../../services/user-administration/mutations/deactivate-agent'
import RESEND_INVITATION from '../../services/user-administration/mutations/resend-invitation'
import RESET_AGENT_PASSWORD from '../../services/user-administration/mutations/reset-agent-password'
import { UserContext } from '.'
import Loader from '../../components/ui/loader'
import AlertMessage from '../../components/ui/alert'
import CreateEditUserModal from './create-edit-user-modal'

const StyledCustomEditButton = styled.div<StyledComponentInterface>`
  .custom-edit-button {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    margin-left: 6px;
  }
  .custom-edit-button__wrapper {
    background-color: var(--primary-blue);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 40px;
    border-radius: 6px;
  }
  .custom-edit-button__wrapper-text {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: var(--white);
    padding: 10px;
    text-align: center;
  }
  .custom-edit-button__wrapper-drop-down {
    width: 36%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 1px solid var(--white);
    height: 30px;
    margin-top: 1px;
    margin-left: 7px;
    padding-right: 5px;
  }
  .custom-edit-button__wrapper-drop-down-image {
    justify-content: center;
    margin-left: 10px;
    margin-right: 5px;
  }
  .custom-edit-button__wrapper-image {
    height: 8px;
    width: 12px;
  }
  .custom-edit-button__menu {
    position: absolute;
    margin-top: 4px;
    float: right;
    right: ${(props: any) => props?.styles?.right ?? '89px'};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px 1px;
    width: auto;
    height: auto;
    list-style-type: none;
    background: var(--white);
    border: 1px solid var(--primary-blue);
    box-shadow: 0px 4px 8px 2px var(--light-grey-50);
    border-radius: 6px;
    z-index: 20;
  }
  .custom-edit-button__menu-item__button {
    text-align: left;
    background: none;
    border: none;
    min-width: 190px;
    height: 35px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: var(--light-grey-600);
    padding-left: 10px;
    padding-right: 45px;
    cursor: pointer;
  }
  .custom-edit-button__menu-item__button: hover, .custom-edit-button__menu-item-drop-down-locked: hover {
    background: var(--light-grey-150);
  }
  .custom-edit-button__menu-item-drop-down-locked {
    text-align: center;
    background: none;
    border: none;
    width: 150px;
    height: 35px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: var(--light-grey-600);
    padding-right: 10px;
    cursor: pointer;
  }
`

interface CustomEditButtonInterface {
  tableProps: any
  clearAllFilters: () => void
}

const CustomEditButton = ({
  tableProps,
  clearAllFilters,
}: CustomEditButtonInterface): ReactElement => {
  const { fetchUsers } = useContext(UserContext)
  const { authState } = useOktaAuth()
  const [open, setOpen] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false)
  const [alertOpen, setAlertOpen] = useState(false)
  const [isResetPassword, setIsResetPassword] = useState(false)
  const [isResendInvite, setIsResendInvite] = useState(false)
  const { t } = useTranslation(namespaces.pages.manageUser)
  const ref: any = useRef(null)
  const [width, setWidth] = useState(window.innerWidth)
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState('')
  const [severityStatus, setSeverityStatus] = useState('success')
  const [autoClose, setAutoClose] = useState(true)

  const agentId = tableProps?.row?.original?.id

  const updateWidthAndHeight = (): void => {
    setWidth(window.innerWidth)
  }
  const handleOpen = (): void => {
    setOpen(!open)
  }
  const handleDropDown = (): void => {
    setOpen(false)
  }

  useEffect(() => {
    const severityStatusSet = (statusMessage: string): void => {
      if (
        statusMessage === t('resetResendError') ||
        statusMessage === t('deleteError')
      ) {
        setSeverityStatus('warning')
        setAutoClose(false)
      } else {
        setSeverityStatus('success')
        setAutoClose(true)
      }
    }
    severityStatusSet(message)
    window.addEventListener('resize', updateWidthAndHeight)

    const handleClickOutside = (event: MouseEvent): void => {
      const refData = ref?.current?.contains(event?.target)
      if (refData === false) {
        setOpen(false)
      }
    }

    document.addEventListener('mouseup', handleClickOutside)

    return () => {
      document.removeEventListener('mouseup', handleClickOutside)
    }
  }, [message])

  const dropDownContainerPosition = width < 1300 ? '76px' : '89px'

  const handleDeleteUser = (): void => {
    setConfirmationModalOpen(true)
  }

  const deleteUser = (agentId: string): void => {
    handleDropDown()
    setMessage('')
    setLoading(true)
    setConfirmationModalOpen(false)
    client
      .mutate({
        mutation: DEACTIVATE_AGENT,
        variables: { input: { agentId } },
        context: {
          headers: {
            authorization: authState?.accessToken?.accessToken,
          },
        },
      })
      .then((): void => {
        setLoading(false)
        setAlertOpen(true)
        setMessage(`${t('modalSuccessDescription')}`)
      })
      .catch((error: any | unknown) => {
        setLoading(false)
        setAlertOpen(true)
        setMessage(`${t('deleteError')}`)
        console.error('Error deleting Agent: ', error)
      })
  }

  const handleResendInvite = (agentId: string): void => {
    handleDropDown()
    setMessage('')
    setLoading(true)
    setConfirmationModalOpen(false)
    client
      .mutate({
        mutation: RESEND_INVITATION,
        variables: { input: { agentId } },
        context: {
          headers: {
            authorization: authState?.accessToken?.accessToken,
          },
        },
      })
      .then((): void => {
        setLoading(false)
        setAlertOpen(true)
        setMessage(`${t('modalInviteSuccessDescription')}`)
      })
      .catch((error: any | unknown) => {
        setLoading(false)
        setMessage(`${t('resetResendError')}`)
        setAlertOpen(true)
        setIsResetPassword(false)
        console.error('Error deleting Agent: ', error)
      })
  }

  const handleResetPassword = (agentId: string): void => {
    handleDropDown()
    setMessage('')
    setConfirmationModalOpen(false)
    setLoading(true)
    client
      .mutate({
        mutation: RESET_AGENT_PASSWORD,
        variables: { input: { agentId } },
        context: {
          headers: {
            authorization: authState?.accessToken?.accessToken,
          },
        },
      })
      .then((): void => {
        setAlertOpen(true)
        setLoading(false)
        setMessage(`${t('resetEmailMessage')}`)
      })
      .catch((error: any | unknown) => {
        setMessage(`${t('resetResendError')}`)
        setAlertOpen(true)
        setLoading(false)
        console.error('Error deleting Agent: ', error)
      })
  }

  const modalMessage = isResetPassword
    ? `${t('modalConfirmationDescriptionManager')}`
    : isResendInvite
    ? `${t('modalConfirmationDescriptionResend')}`
    : `${t('modalConfirmationDescription')}`

  const modalConfirmationButtonText = isResetPassword
    ? `${t('passwordReset')}`
    : isResendInvite
    ? `${t('resendInvite')}`
    : `${t('modalConfirmationBtnProceed')}`

  return (
    <StyledCustomEditButton
      ref={ref}
      styles={{ right: dropDownContainerPosition }}
    >
      <div className="custom-edit-button">
        <div className="custom-edit-button__wrapper">
          <div
            onClick={() => {
              setModalOpen(true)
            }}
            className="custom-edit-button__wrapper-text"
          >
            {t('edit')}
            {modalOpen && (
              <CreateEditUserModal
                modalOpen={modalOpen}
                setModalOpen={setModalOpen}
                tableProps={tableProps}
              />
            )}
          </div>
          <div
            className="custom-edit-button__wrapper-drop-down"
            onClick={handleOpen}
          >
            <div className="custom-edit-button__wrapper-drop-down-image">
              {open ? (
                <UpArrow className="custom-edit-button__wrapper-image" />
              ) : (
                <DownArrow className="custom-edit-button__wrapper-image" />
              )}
            </div>
          </div>
        </div>
      </div>
      {open ? (
        tableProps.row.original.state?.toLowerCase() === 'active' ? (
          <ul className="custom-edit-button__menu">
            {DropDownDataActive.map(option => {
              return (
                <li
                  key={option.id?.toString()}
                  className="custom-edit-button__menu-item"
                >
                  <button
                    className="custom-edit-button__menu-item__button"
                    onClick={(): void => {
                      if (option.name === 'delete') {
                        handleDeleteUser()
                      } else if (
                        option.name === 'passwordReset' ||
                        option.name === 'resetPassword'
                      ) {
                        setConfirmationModalOpen(true)
                        setIsResetPassword(true)
                      } else {
                        handleDropDown()
                      }
                    }}
                  >
                    {t(option.name)}
                  </button>
                </li>
              )
            })}
          </ul>
        ) : tableProps.row.original.state?.toLowerCase() === 'pending' ? (
          <ul className="custom-edit-button__menu">
            {DropDownDataPending.map(option => {
              return (
                <li
                  key={option.id?.toString()}
                  className="custom-edit-button__menu-item"
                >
                  <button
                    className="custom-edit-button__menu-item__button"
                    onClick={(): void => {
                      if (option.name === 'delete') {
                        handleDeleteUser()
                      } else if (
                        option.name === 'passwordReset' ||
                        option.name === 'resetPassword'
                      ) {
                        setConfirmationModalOpen(true)
                        setIsResetPassword(true)
                      } else {
                        setConfirmationModalOpen(true)
                        setIsResendInvite(true)
                        setIsResetPassword(false)
                      }
                    }}
                  >
                    {t(option.name)}
                  </button>
                </li>
              )
            })}
          </ul>
        ) : tableProps.row.original.state?.toLowerCase() === 'password reset' ||
          tableProps.row.original.state?.toLowerCase() === 'recovery' ? (
          <ul className="custom-edit-button__menu">
            {DropDownPasswordReset.map(option => {
              return (
                <li
                  key={option.id?.toString()}
                  className="custom-edit-button__menu-item"
                >
                  <button
                    className="custom-edit-button__menu-item__button"
                    onClick={(): void => {
                      if (option.name === 'delete') {
                        handleDeleteUser()
                      } else if (
                        option.name === 'passwordReset' ||
                        option.name === 'resetPassword'
                      ) {
                        setConfirmationModalOpen(true)
                        setIsResetPassword(true)
                      } else {
                        handleDropDown()
                      }
                    }}
                  >
                    {t(option.name)}
                  </button>
                </li>
              )
            })}
          </ul>
        ) : tableProps.row.original.state?.toLowerCase() === 'locked out' ? (
          <ul className="custom-edit-button__menu">
            {DropDownLokedOut.map(option => {
              return (
                <li
                  key={option.id?.toString()}
                  className="custom-edit-button__menu-item"
                >
                  <button
                    className="custom-edit-button__menu-item-drop-down-locked"
                    onClick={(): void => {
                      if (option.name === 'delete') {
                        handleDeleteUser()
                      } else if (
                        option.name === 'passwordReset' ||
                        option.name === 'resetPassword'
                      ) {
                        setConfirmationModalOpen(true)
                        setIsResetPassword(true)
                      } else {
                        handleDropDown()
                      }
                    }}
                  >
                    {t(option.name)}
                  </button>
                </li>
              )
            })}
          </ul>
        ) : null
      ) : null}

      <ConfirmationModal
        isOpen={confirmationModalOpen}
        closeModal={(): void => {
          setIsResendInvite(false)
          setIsResetPassword(false)
          setConfirmationModalOpen(false)
        }}
        header={`${t('modalConfirmationHeading')}`}
        message={modalMessage}
        confirmationBtnText={modalConfirmationButtonText}
        cancelBtnText={`${t('modalConfirmationBtnCancel')}`}
        handleConfirmation={(): void => {
          isResetPassword
            ? handleResetPassword(agentId)
            : isResendInvite
            ? handleResendInvite(tableProps?.row?.original?.id)
            : deleteUser(agentId)
        }}
      />

      {loading ? (
        <Backdrop open={loading}>
          <Loader />
        </Backdrop>
      ) : (
        <AlertMessage
          autoClose={autoClose}
          severity={severityStatus}
          open={alertOpen}
          message={message}
          messageFormatting={true}
          onClose={(): void => {
            setAlertOpen(false)
            setIsResetPassword(false)
            setIsResendInvite(false)
            if (severityStatus === 'success') {
              fetchUsers()
              clearAllFilters()
            }
          }}
        />
      )}
    </StyledCustomEditButton>
  )
}

export default CustomEditButton
