import { gql } from '@apollo/client'

const RESEND_INVITATION = gql`
  mutation ResendInvitation($input: ResendInvitationInput!) {
    resendInvitation(input: $input) {
      success
    }
  }
`

export default RESEND_INVITATION
