import { createSlice } from '@reduxjs/toolkit'

interface AppState {
  session: boolean | null
}

const initialState: AppState = {
  session: null,
}

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    startSession: state => {
      state.session = true
    },
    expireSession: (state, action) => {
      state.session = action.payload
    },
  },
})

export const { startSession, expireSession } = appSlice.actions

export default appSlice.reducer
