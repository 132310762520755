import { type ReactElement } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import oopsImage from '../../assets/img/page-not-found.svg'
import ArrowBack from '@mui/icons-material/ArrowBackRounded'

const StyledPageNotFound = styled.div`
  .page-not-found-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  .page-not-found-wrapper__text {
    color: var(--text-dark-grey);
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    line-height: 28px;
    margin-top: 30px;
  }
  .page-not-found-wrapper__sub-text {
    margin-top: 10px;
    color: var(--text-dark-grey);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
  }
  .page-not-found-wrapper__go-back {
    margin-top: 30px;
    display: flex;
    background-color: var(--light-blue-300);
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 40px;
    border-radius: 20px;
    cursor: pointer;
  }
  .page-not-found-wrapper__go-back__text {
    margin-left: 5px;
    color: var(--white);
    font-style: normal;
    font-weight: bolder;
    font-size: 14px;
    line-height: 28px;
  }
  .page-not-found-wrapper__go-back__arrow {
    color: var(--white);
    fontsize: 20px;
  }
`

const PageNotFound = (): ReactElement => {
  const history = useHistory()
  return (
    <StyledPageNotFound>
      <div className="page-not-found-wrapper">
        <img src={oopsImage} width={'160px'} height={'160px'} />
        <p className="page-not-found-wrapper__text">
          {' '}
          Oops. You seem a little lost.
        </p>
        <p className="page-not-found-wrapper__sub-text">
          We couldn&#39;t find the page you were looking for.
        </p>
        <div
          className="page-not-found-wrapper__go-back"
          onClick={() => {
            history.goBack()
          }}
        >
          <ArrowBack className="page-not-found-wrapper__go-back__arrow" />
          <p className="page-not-found-wrapper__go-back__text">GO BACK</p>
        </div>
      </div>
    </StyledPageNotFound>
  )
}

export default PageNotFound
