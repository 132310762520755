import { gql } from '@apollo/client'

const DEACTIVATE_AGENT = gql`
  mutation DeactivateAgent($input: DeactivateAgentInput!) {
    deactivateAgent(input: $input) {
      success
    }
  }
`

export default DEACTIVATE_AGENT
