import { useContext, useState, type ReactElement } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import search from '../../assets/img/search.png'
import { namespaces } from '../../core/i18n/i18n.constants'
import { isPtp } from '../../utils/common-methods'
import { RootContext } from '../dashboard'
import CreateEditUserModal from './create-edit-user-modal'

const StyledManageUserHeader = styled.div`
  .manage-user-header {
    height: 112px;
    left: 0%;
    right: 0%;
    top: 96px;
    background: var(--primary-blue);
    align-items: center;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .manage-user-header__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .manage-user-header__text {
    height: 42px;
    font-family: var(--font-family-primary);
    font-style: normal;
    font-weight: 600;
    font-size: 35px;
    line-height: 42px;
    color: var(--white);
    margin-left: 60px;
    margin-right: 30px;
    text-align: center;
  }

  .manage-user-header__button {
    justify-content: center;
    align-items: center;
    width: 126px;
    height: 42px;
    background: var(--light-white);
    border: 1px solid var(--primary-blue);
    border-radius: 6px;
    cursor: pointer;
  }
  .manage-user-header__button-text {
    font-family: var(--font-family-primary);
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    text-align: center;
    color: var(--primary-blue);
  }
  .manage-user-header__search-wrapper {
    display: flex;
    align-items: center;
    position: relative;
    margin-right: 60px;
  }
  .manage-user-header__icon-placeholder {
    display: flex;
    position: relative;
  }
  .manage-user-header__input {
    background-color: var(--white);
    border-radius: 8px;
    border: none;
    font-size: 16px;
    margin-top: 7px;
    min-width: 360px;
    padding: 8px 12px;
  }
  .manage-user-header__search-icon {
    position: absolute;
    right: 8px;
    margin-top: 17px;
  }
  @media screen and (min-device-width: 768px) and (max-device-width: 1020px) {
    .manage-user-header__search-wrapper {
      margin-right: 10px !important;
    }
    .manage-user-header__text {
      margin-left: 10px;
      margin-right: 10px;
    }
    .manage-user-header__input {
      margin-top: 0px !important;
    }
    .manage-user-header__search-icon {
      margin-top: 12px !important;
    }
  }
`
interface ManageUserHeaderInterface {
  filter: string
  setFilter: React.Dispatch<React.SetStateAction<string>>
  text: string
  setGlobalFilter: React.Dispatch<React.SetStateAction<string>>
}

const ManageUserHeader = ({
  filter,
  setFilter,
  text,
  setGlobalFilter,
}: ManageUserHeaderInterface): ReactElement => {
  const { t } = useTranslation(namespaces.pages.manageUser)
  const [modalOpen, setModalOpen] = useState(false)
  const { user } = useContext(RootContext)

  const handleChange = (e: any): void => {
    setGlobalFilter(e?.target?.value)
    setFilter(e?.target?.value)
  }

  return (
    <StyledManageUserHeader>
      <div className="manage-user-header">
        <section className="manage-user-header__wrapper">
          <p className="manage-user-header__text">{text}</p>
          {!isPtp(user) && (
            <button
              className="manage-user-header__button"
              onClick={() => {
                setModalOpen(true)
              }}
            >
              <p className="manage-user-header__button-text">{t('newUser')}</p>
              {modalOpen && (
                <CreateEditUserModal
                  modalOpen={modalOpen}
                  setModalOpen={setModalOpen}
                />
              )}
            </button>
          )}
        </section>
        <div className="manage-user-header__search-wrapper">
          <div className="manage-user-header__icon-placeholder">
            <input
              type="text"
              value={filter ?? ''}
              className="manage-user-header__input"
              placeholder={t('searchPlaceholder') ?? ''}
              onChange={handleChange}
            />
            <div className="manage-user-header__search-icon">
              <img src={search} />
            </div>
          </div>
        </div>
      </div>
    </StyledManageUserHeader>
  )
}

export default ManageUserHeader
