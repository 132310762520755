import { type ReactElement } from 'react'
import phoneIcon from '../../assets/img/phone.png'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { namespaces } from '../../core/i18n/i18n.constants'
import moment from 'moment'
import { isEmpty } from '../../utils/common-methods'

const StyledTabHeader = styled.div`
  .dashboard-header {
    font-family: var(--font-family-primary);
    color: var(--white);
    background-color: var(--primary-blue);
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--white);
    backgroundcolor: var(--primary-blue);
    padding: 29px 100px;
    line-height: 48px;
  }
  .dashboard-header__device-info {
    justify-content: flex-start;
    align-items: flex-start;
  }
  .dashboard-header__device-info__title {
    font-size: 35px;
    margin-bottom: 5px;
  }
  .dashboard-header__device-info__content {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
  }
  .dashboard-header__device-info__content__logo {
    padding-right: 5px;
  }
  .dashboard-header__updated-text {
    display: flex;
    font-style: normal;
    font-weight: 600;
    font-size: 22.5px;
    line-height: 31px;
    color: var(--white);
    text-align: center;
    column-gap: 8px;
  }
  .dashboard-header__updated-time {
    padding-top: 1px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
  }
`
interface TabHeaderDeviceDataInterface {
  id?: string
  name?: string
  phone?: string
  updated?: string
}

interface TabHeaderInterface {
  deviceData: TabHeaderDeviceDataInterface
}

const TabHeader = ({ deviceData }: TabHeaderInterface): ReactElement => {
  const { id = '', name = '', phone = '', updated = '' } = deviceData
  const { t } = useTranslation(namespaces.common.text)

  return (
    <StyledTabHeader>
      <section className="dashboard-header">
        <div key={id?.toString()} className="dashboard-header__device-info">
          <p className="dashboard-header__device-info__title">{name}</p>
          <div className="dashboard-header__device-info__content">
            <img
              className="dashboard-header__device-info__content__logo"
              src={phoneIcon}
            />
            <span>{phone}</span>
          </div>
        </div>
        {!isEmpty(updated) && (
          <div className="dashboard-header__updated-text">
            {t('updated')}
            <p className="dashboard-header__updated-time">
              {moment(updated).format('MM/DD/YYYY | hh:mm A')}
            </p>
          </div>
        )}
      </section>
    </StyledTabHeader>
  )
}

export default TabHeader
