import mixpanel from 'mixpanel-browser'
import { isCookieValid } from '../../utils/common-methods'
import {
  CONFIRMED,
  isAnalyticsConsentConfirmed,
} from '../../components/cookie-policy/cookie-consent'

export const initMixpanel = (): void => {
  mixpanel.init(`${process.env.REACT_APP_MIXPANEL as string}`)
}

export const isMixpanelAllowed = (): boolean => {
  if (isCookieValid('aiz_consent') && isCookieValid('mixpanel')) {
    const isConsentGiven = isAnalyticsConsentConfirmed('aiz_consent')
    return isConsentGiven === CONFIRMED
  }

  return false
}

// Re-exporting same type mixpanel uses mixpanel
// eslint-disable-next-line @typescript-eslint/consistent-indexed-object-style
export interface Dict {
  [key: string]: any
}

const actions = {
  identify: (id: string) => {
    mixpanel?.identify(id)
    console.info('mixpanel-identify-log', id)
  },
  track: (name: string, properties?: Dict) => {
    mixpanel?.track(name, properties)
    console.info('mixpanel-track-log', name)
  },
  people: {
    set: (props: any): void => {
      mixpanel?.people.set(props)
      console.info('mixpanel-people-set-log', props)
    },
  },
}

export const Mixpanel = actions
