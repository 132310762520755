export const footerLinks = [
  {
    id: 1,
    name: 'privacyNotice',
    link: 'https://legal.pocketgeek.com/en/dashboard/privacy/1',
  },
  {
    id: 2,
    name: 'cookies',
    link: 'https://legal.pocketgeek.com/en/dashboard/privacy/1#user_analytics_notice',
  },
  {
    id: 3,
    name: 'termsOfService',
    link: 'https://legal.pocketgeek.com/en/dashboard/terms/1',
  },
]
