import { type ReactElement, createContext, useEffect, useState } from 'react'
import Header from '../../components/header'
import Footer from '../../components/footer'
import { footerLinks } from '../../components/footer/footer-links'

import {
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useHistory,
} from 'react-router-dom'
import PageNotFound from '../../components/page-not-found'

import { useOktaAuth } from '@okta/okta-react'
import { useTranslation } from 'react-i18next'
import { useAppSelector, useAppDispatch } from '../../store'
import { setLoggedInUser, clearLoggedInUser } from './dashboard-slice'
import { api } from '../../services'
import ME from '../../services/user-administration/queries/me'
import SearchDevices from '../search-devices'
import ManageUsers from '../manage-users'
import Device from '../device'
import ManageAccount from '../manage-account'
import { isCSRM, isEmpty, checkAuthError } from '../../utils/common-methods'
import { namespaces } from '../../core/i18n/i18n.constants'
import LoaderPage from '../../components/loader-page'
import ErrorPage from '../../components/error-page'
import { expireSession } from '../app/app-slice'
import AnalyticsConsent from '../../components/cookie-policy'

export const RootContext = createContext({
  user: {
    id: '',
    email: '',
    name: '',
    role: '',
  },
  handleAuthError: (): void => {},
})

const Dashboard = (props: any): ReactElement => {
  const { tenant = { name: '', idp: '' } } = props

  const { t } = useTranslation(namespaces.common.text)

  const dispatch = useAppDispatch()
  const history = useHistory()
  const user = useAppSelector((state: any) => state.dashboard.user)

  const { path: PATH } = useRouteMatch()

  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<boolean>(false)
  const [notValidRoute, setNotValidRoute] = useState<boolean>(false)

  const { oktaAuth, authState } = useOktaAuth()
  const [authError, setAuthError] = useState<boolean>(false)

  const handleSessionExpire = (): void => {
    dispatch(expireSession(false))
    history.replace('/')
  }

  const updateAuthError = (isAuthError: boolean): void => {
    setAuthError(isAuthError)
  }

  const handleLogoutAPI = async (): Promise<void> => {
    setLoading(true)
    const isAuthenticated: boolean = authState?.isAuthenticated ?? false
    if (isAuthenticated) {
      console.info('Signing out...user', user)
      const postLogoutRedirectUri = window.location.origin
      oktaAuth?.signOut({ postLogoutRedirectUri }).finally(() => {
        setLoading(false)
        dispatch(expireSession(null))

        console.info('Stoping oktaAuth as service')
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        oktaAuth.stop()
      })
    }
  }

  const handleGetUserAPI = (): void => {
    api
      .query({
        query: ME,
        context: {
          headers: {
            authorization: authState?.accessToken?.accessToken,
          },
        },
        fetchPolicy: 'network-only',
      })
      .then((response: any) => {
        const user: any = {
          email: authState?.idToken?.claims?.email ?? '',
          name: authState?.idToken?.claims?.name ?? '',
          id: response?.data?.me?.id ?? '',
          role: response?.data?.me?.role ?? '',
          group: response?.data?.me?.group ?? [],
        }

        dispatch(setLoggedInUser(user))
        setError(false)
      })
      .catch((error: any) => {
        console.error('API ME - error ', error)
        if (checkAuthError(error)) {
          updateAuthError(true)
        } else {
          setError(true)
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const dashboardView = (
    child: ReactElement,
    header: boolean = true
  ): ReactElement => {
    if (notValidRoute) {
      return <PageNotFound />
    }
    return (
      <div className="dashboard__view">
        {header && <Header path={PATH} handleLogoutAPI={handleLogoutAPI} />}
        {child}
        <Footer footerLink={footerLinks} />
      </div>
    )
  }

  /* handle auth */
  useEffect(() => {
    if (!isEmpty(authState) && authState?.isAuthenticated === false) {
      console.warn('User is not authenticated, navigating to login')
      history.push('/login')
    }
  }, [authState])

  /* handle load the user */
  useEffect(() => {
    if (!authError && isEmpty(user)) {
      handleGetUserAPI()
    }

    return () => {
      if (!isEmpty(user)) {
        dispatch(clearLoggedInUser())
      }
    }
  }, [user, authError])

  useEffect(() => {
    if (authError) {
      handleSessionExpire()
    }
  }, [authError])

  /* handle session */
  useEffect(() => {
    if (isEmpty(tenant?.name)) {
      // it is valid for only no users with no SSO
      oktaAuth?.authStateManager?.subscribe((authState: any) => {
        if (
          authState.isAuthenticated === undefined ||
          authState.isAuthenticated === false
        ) {
          // render unathenticated view
          console.warn('User session has expired or has been closed')

          updateAuthError(true)
        }
      })
    }

    return () => {
      if (isEmpty(tenant?.name)) {
        oktaAuth?.authStateManager?.unsubscribe()
      }
    }
  }, [])

  if (error) {
    return dashboardView(<ErrorPage />, false)
  } // display error Page up, until user data is being fetched

  if (loading && isEmpty(user)) {
    return dashboardView(<LoaderPage title={t('loading')} />, false)
  } // display Loader Page up, until user data is being fetched

  return (
    <section className="dashboard">
      <RootContext.Provider
        value={{ user, handleAuthError: handleSessionExpire }}
      >
        <Switch>
          <Route
            exact
            path={`${PATH}`}
            render={() =>
              dashboardView(<Redirect to={`${PATH}/search-devices`} />)
            }
          />
          <Route
            exact
            path={`${PATH}/search-devices/:id/overview/:page`}
            render={() =>
              dashboardView(<Device setIsNotValidRoute={setNotValidRoute} />)
            }
          />
          <Route
            exact
            path={`${PATH}/search-devices/:id/:tab`}
            render={() =>
              dashboardView(<Device setIsNotValidRoute={setNotValidRoute} />)
            }
          />
          <Route
            exact
            path={`${PATH}/search-devices`}
            render={() => dashboardView(<SearchDevices />)}
          />
          <Route
            exact
            path={`${PATH}/user`}
            render={() =>
              isCSRM(user) ? dashboardView(<ManageUsers />) : <PageNotFound />
            }
          />
          <Route
            exact
            path={`${PATH}/manage-account`}
            render={() => dashboardView(<ManageAccount />)}
          />
          <Route path={`${PATH}/*`} render={() => <PageNotFound />} />
        </Switch>
      </RootContext.Provider>

      {!isEmpty(tenant?.name) && (
        <AnalyticsConsent isSSO={true} identity={authState?.accessToken} />
      )}
    </section>
  )
}

export default Dashboard
