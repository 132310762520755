import { type ReactElement } from 'react'
import styled from 'styled-components'
import { EmptyLayout } from '../../layouts'
import Loader from '../ui/loader'
import { isEmpty } from '../../utils/common-methods'

const StyledLoaderPage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90vh;

  .loader-page__title {
    text-align: center;
    font-size: 14px;
    font-weight: normal;
    font-family: var(--font-family-primary);
  }
`

interface LoaderPageInterface {
  title?: string | null
}

const LoaderPage = (props: LoaderPageInterface): ReactElement => {
  const { title = '' } = props

  return (
    <StyledLoaderPage className="loader-page">
      <EmptyLayout>
        <Loader styles="height: 50px;" />
        {!isEmpty(title) && (
          <p className="loader-page__title">{title as string}</p>
        )}
      </EmptyLayout>
    </StyledLoaderPage>
  )
}

export default LoaderPage
