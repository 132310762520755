import styled from 'styled-components'
import { type ReactElement, type Key } from 'react'
import { useTranslation } from 'react-i18next'
import CustomCheckBox from '../../components/ui/check-box'
import CustomButton from './custom-button'
import { namespaces } from '../../core/i18n/i18n.constants'
import { CheckBoxData } from './check-box-data'
import UserHeadingText from './user-heading-text'

const StyledScopingOptions = styled.div`
  .scoping-options {
    margin-right: 30px;
    margin-left: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 20px;
  }
  .scoping-options__wrapper-filter {
    display: flex;
    align-items: center;
    flex: 9;
    flex-wrap: wrap;
    row-gap: 20px;
    column-gap: 20px;
  }
  .scoping-options__wrapper-filter__role-wrapper,
  .scoping-options__wrapper-filter__state-wrapper {
    display: flex;
  }
  .scoping-options__wrapper-filter__role-wrapper__text {
    margin-right: 12px;
    font-family: var(--font-family-primary);
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    color: var(--dark-grey-100);
  }
  .scoping-options__wrapper-filter__state-wrapper__text-state {
    margin-right: 12px;
    font-family: var(--font-family-primary);
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    color: var(--dark-grey-100);
  }
  .scoping-options__wrapper-horizontal-line {
    margin-right: 30px;
    margin-left: 30px;
    background-color: var(--light-grey-300);
    height: 1px;
    margin: 30px 60px;
  }
  .scoping-options__wrapper-button {
    display: flex;
    margin-right: 30px;
    column-gap: 10px;
  }
  @media screen and (min-device-width: 768px) and (max-device-width: 1020px) {
    .scoping-options {
      margin-left: 15px;
      margin-right: 15px;
    }
    .scoping-options__wrapper-horizontal-line {
      margin-right: 15px;
      margin-left: 15px;
      margin: 30px 15px;
    }
  }
`
interface ScopingOptionsInterface {
  sendData: (buttonName: string) => void
  sendCheckBoxData: (selectedFilter: string, isSelected: boolean) => void
  checkboxClickStatus: boolean
}
const ScopingOptions = ({
  sendData,
  sendCheckBoxData,
  checkboxClickStatus,
}: ScopingOptionsInterface): ReactElement => {
  const { t } = useTranslation(namespaces.pages.manageUser)
  const { roles, states, filterButton } = CheckBoxData

  const handleData = (buttonName: string): void => {
    sendData(buttonName)
  }
  const handleCheckBoxOnChange = (data: string, isSelected: boolean): void => {
    sendCheckBoxData(data, isSelected)
  }

  return (
    <StyledScopingOptions>
      <UserHeadingText text={t('scopingOptions')} fontWeight={'600'} />
      <div className="scoping-options">
        <div className="scoping-options__wrapper-filter">
          <div className="scoping-options__wrapper-filter__role-wrapper">
            <p className="scoping-options__wrapper-filter__role-wrapper__text">
              {t('roles')}
            </p>
            {roles.map((item, index: Key) => {
              return (
                <CustomCheckBox
                  checkBoxString={item.name}
                  sendCheckBoxData={handleCheckBoxOnChange}
                  checkboxClickStatus={checkboxClickStatus}
                  key={index?.toString()}
                />
              )
            })}
          </div>
          <div className="scoping-options__wrapper-filter__state-wrapper">
            <p className="scoping-options__wrapper-filter__state-wrapper__text-state">
              {t('states')}
            </p>
            {states.map((item, index: Key) => {
              return (
                <CustomCheckBox
                  checkBoxString={item.name}
                  sendCheckBoxData={handleCheckBoxOnChange}
                  checkboxClickStatus={checkboxClickStatus}
                  key={index?.toString()}
                />
              )
            })}
          </div>
        </div>
        <div className="scoping-options__wrapper-button">
          {filterButton.map((item, index: Key) => {
            return (
              <CustomButton
                buttonString={t(item.name)}
                borderColor={item.borderColor}
                textColor={item.borderColor}
                sendData={handleData}
                key={index?.toString()}
              />
            )
          })}
        </div>
      </div>
      <div className="scoping-options__wrapper-horizontal-line"></div>
    </StyledScopingOptions>
  )
}

export default ScopingOptions
