import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import UserRoleFormField from '../../components/ui/text-field'
import { namespaces } from '../../core/i18n/i18n.constants'
import Loader from '../../components/ui/loader'
import { isEmpty } from '../../utils/common-methods'

const StyledCreateEditUserForm = styled.div`
  .create-edit-user-form__user-role-wrapper {
    margin-left: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 14px;
    gap: 4px;
    width: 452px;
    height: 74px;
  }

  .create-edit-user-form__form-fields {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    margin-top: 8px;
    margin-left: 16px;
  }

  .create-edit-user-form__form-fields__input-field {
    background: var(--light-grey-100);
    border: 1px solid var(--cyan);
    border-radius: 6px;
    padding: 8px 12px;
    gap: 8px;
    width: 420px;
    height: 44px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: var(--light-grey);
  }
  .create-edit-user-form__create-user-button {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px 16px;
    gap: 16px;
  }
  .justify-end {
    justify-content: flex-end;
  }
  .justify-center {
    justify-content: center;
  }
  .create-edit-user-form__create-user-button__button {
    margin-top: 32px;
    width: 200px;
    height: 44px;
    background: var(--primary-blue);
    border: 0;
    border-radius: 6px;
    elevation: 0;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: var(--white);
    cursor: pointer;
  }
  .create-edit-user-form__error-text {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: red;
    margin-left: 16px;
    padding-top: 3px;
  }

  .create-edit-user-form__form-fields__form-labels {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: var(--text-grey-black);
  }

  .create-edit-user-form__divider {
    margin-top: 13px;
    margin-bottom: 13px;
    height: 1px;
    background-color: var(--light-grey-150);
    border: none;
  }

  .create-edit-user-form__create-user-button__deactivate-button {
    margin-top: 32px;
    width: 200px;
    height: 44px;
    background: var(--white);
    border: 1px solid var(--primary-blue);
    border-radius: 6px;
    elevation: 0;
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: var(--primary-blue);
    cursor: pointer;
  }

  .create-edit-user-form__create-user-button__button.disabled {
    cursor: not-allowed;
    background: var(--light-grey-400);
  }
  .loader-wrapper {
    max-width: 40px;
    max-height: 40px;
    padding-top: 50px;
  }
  .server-message {
    color: red;
    margin: 12px 0 12px 16px;
  }
`

const roles = [
  {
    userRoleValue: 'CSR Manager',
    userRoleLabel: 'csrManager',
  },
  {
    userRoleValue: 'CSR',
    userRoleLabel: 'csr',
  },
]

interface CreateEditUserFormInterface {
  agentId: string
  handleSubmit: (e: { preventDefault: () => void }) => void
  closeModal: () => void
  formValues: {
    firstName: string
    lastName: string
    email: string
    userRole: string
  }
  handleTextFieldChange: (e: { target: HandleTextFieldChangeInterface }) => void
  formErrors: {
    firstNameError: string
    lastNameError: string
    emailError: string
    userRoleError: string
  }
  apiMessage: string
  spinner: boolean
}
interface HandleTextFieldChangeInterface {
  name: string
  value: string
}

const CreateEditUserForm = ({
  agentId,
  handleSubmit,
  formValues,
  handleTextFieldChange,
  formErrors,
  apiMessage,
  spinner,
  closeModal,
}: CreateEditUserFormInterface): ReactElement => {
  const isCreateUserFlow = isEmpty(agentId)
  const { t } = useTranslation([namespaces.pages.manageUser])
  const { t: t1 } = useTranslation([namespaces.common.server])
  const { firstName, lastName, email } = formValues
  const { firstNameError, lastNameError, emailError, userRoleError } =
    formErrors

  return (
    <StyledCreateEditUserForm>
      {/* create user modal form */}
      <form onSubmit={handleSubmit}>
        {!isEmpty(apiMessage) && apiMessage === 'userAlreadyExists' && (
          <p className="server-message">{t1(`${apiMessage}`)}</p>
        )}
        {/* UserRole text field */}
        <div className="create-edit-user-form__user-role-wrapper">
          <label className="create-edit-user-form__form-fields__form-labels">
            {t('userRole')}
          </label>

          {/* User Role form field  */}
          <UserRoleFormField
            formValues={formValues}
            roles={roles}
            handleTextFieldChange={handleTextFieldChange}
            disabled={spinner}
            placeholder={`${t('exempliGratia')} ${t('csr')}`}
          />
        </div>

        {/* User role error text if present */}
        <p className="create-edit-user-form__error-text">{userRoleError}</p>

        {/* divider after the user role select text field */}
        <hr className="create-edit-user-form__divider" />

        {/* First name text field */}
        <div className="create-edit-user-form__form-fields">
          <label className="create-edit-user-form__form-fields__form-labels">
            {`${t('firstName')} (${t('required')})`}
          </label>
          <input
            type="text"
            name="firstName"
            onChange={handleTextFieldChange}
            className={`create-edit-user-form__form-fields__input-field ${
              spinner ? 'disabled' : ''
            } `}
            placeholder={`${t('exempliGratia')} Tom`}
            value={firstName}
            maxLength={45}
            disabled={spinner}
          />
        </div>

        {/* First name error text */}
        <p className="create-edit-user-form__error-text">{firstNameError}</p>

        {/* Last name text field */}
        <div className="create-edit-user-form__form-fields">
          <label className="create-edit-user-form__form-fields__form-labels">
            {`${t('lastName')} (${t('required')})`}
          </label>
          <input
            type="text"
            name="lastName"
            onChange={handleTextFieldChange}
            className={`create-edit-user-form__form-fields__input-field ${
              spinner ? 'disabled' : ''
            }`}
            placeholder={`${t('exempliGratia')} Smith`}
            value={lastName}
            maxLength={45}
            disabled={spinner}
          />
        </div>

        {/* Last name error text */}
        <p className="create-edit-user-form__error-text">{lastNameError}</p>

        {/* email text field */}
        {isCreateUserFlow ? (
          <>
            <div className="create-edit-user-form__form-fields">
              <label className="create-edit-user-form__form-fields__form-labels">
                {`${t('email')} (${t('required')})`}
              </label>
              <input
                type="text"
                name="email"
                onChange={handleTextFieldChange}
                className={`create-edit-user-form__form-fields__input-field ${
                  spinner ? 'disabled' : ''
                }`}
                placeholder={`${t('exempliGratia')} tom.smith@mail.com`}
                value={email}
                maxLength={95}
                disabled={spinner}
              />
            </div>
            <p className="create-edit-user-form__error-text">{emailError}</p>
          </>
        ) : (
          <>
            <div className="create-edit-user-form__form-fields edit-user">
              <label className="create-edit-user-form__form-fields__form-labels">
                {`${t('email')}`}
              </label>
              <input
                type="text"
                className="create-edit-user-form__form-fields__input-field disabled"
                value={email}
                maxLength={95}
                disabled={true}
              />
            </div>
            <p className="create-edit-user-form__error-text">{emailError}</p>
          </>
        )}

        {/* Create user button */}
        <div
          className={`create-edit-user-form__create-user-button ${
            spinner ? 'justify-center' : 'justify-end'
          }`}
        >
          {spinner && <Loader />}

          {!spinner && (
            <button
              className="create-edit-user-form__create-user-button__deactivate-button"
              type="button"
              onClick={(): void => {
                closeModal()
              }}
            >
              {`${t('modalConfirmationBtnCancel')}`}
            </button>
          )}

          {!spinner && (
            <button
              disabled={spinner}
              type="submit"
              className={`create-edit-user-form__create-user-button__button ${
                spinner ? 'disabled' : ''
              }`}
            >
              {isCreateUserFlow ? `${t('createUser')}` : `${t('updateUser')}`}
            </button>
          )}
        </div>
      </form>
    </StyledCreateEditUserForm>
  )
}

export default CreateEditUserForm
