import { type ReactElement, useState, useEffect, type ChangeEvent } from 'react'
import { Checkbox, FormControlLabel } from '@mui/material'
import styled from 'styled-components'

const StyledCustomCheckBox = styled.div`
  .custom-check-box {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
  }
  .MuiFormControlLabel-label {
    font-family: var(--font-family-primary);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: var(--light-grey-600);
    margin-right: 10px;
    white-space: nowrap;
  }
`
interface CustomCheckBoxInterface {
  checkBoxString: string
  sendCheckBoxData: (data: string, isSelected: boolean) => void
  checkboxClickStatus: boolean
}

const CustomCheckBox = ({
  checkBoxString,
  sendCheckBoxData,
  checkboxClickStatus,
}: CustomCheckBoxInterface): ReactElement => {
  const [checked, setChecked] = useState<boolean>(false)

  useEffect(() => {
    if (!checkboxClickStatus) {
      setChecked(false)
    }
  }, [checkboxClickStatus])

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setChecked(event?.target?.checked)
    sendCheckBoxData(checkBoxString, event?.target?.checked)
  }

  return (
    <StyledCustomCheckBox>
      <div className="custom-check-box">
        <FormControlLabel
          control={
            <Checkbox
              checked={checkboxClickStatus ? checked : false}
              onChange={handleCheckboxChange}
              inputProps={{ 'aria-label': 'controlled' }}
              sx={{
                height: '18px',
              }}
            />
          }
          label={checkBoxString}
        />
      </div>
    </StyledCustomCheckBox>
  )
}

export default CustomCheckBox
