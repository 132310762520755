import { type ReactElement } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { getCookie, isEmpty } from '../../utils/common-methods'
import closeIcon from '../../assets/img/close-icon.png'
import { namespaces } from '../../core/i18n/i18n.constants'

export const CONFIRMED = 'CONFIRMED'
export const PENDING = 'PENDING'

export const isAnalyticsConsentConfirmed = (
  consentCookieName: string
): string => {
  if (!isEmpty(consentCookieName)) {
    const cookieValue = getCookie(consentCookieName)
    if (!isEmpty(cookieValue)) {
      return cookieValue
    }
  }

  return ''
}

const StyledCookieConsent = styled.div`
  display: flex;
  justify-content: center;
  .cookie-consent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    min-height: 224px;
    bottom: 60px;
    background: var(--white);
    border: 1px solid var(--light-grey-400);
    box-shadow: 0px 4px 8px 2px rgba(48, 57, 82, 0.1);
    border-radius: 6px;
    padding: 24px;
    z-index: 10;
  }
  .cookie-consent__title,
  .cookie-consent__wrapper {
    font-family: var(--font-family-primary);
  }
  .cookie-consent__title,
  .cookie-consent__link {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: var(--light-grey-600);
  }
  .cookie-consent__link {
    color: var(--primary-blue);
    text-decoration: unset;
  }
  .cookie-consent__wrapper {
    width: 100%;
    display: flex;
    gap: 16px;
    margin-top: 48px;
  }
  .cookie-consent__wrapper-btn {
    height: 44px;
    padding: 12px 24px;
    border-radius: 6px;
    font-family: var(--font-family-primary);
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    cursor: pointer;
    text-decoration: unset;
    text-align: center;

    display: flex;
    align-items: center;
    justify-content: center;
  }
  .cookie-consent__wrapper-btn.btn--manage {
    min-width: 239px;
    background: var(--light-white);
    border: 1px solid var(--primary-blue);
    color: var(--primary-blue);
  }
  .cookie-consent__wrapper-btn.btn--allow {
    min-width: 184px;
    background: var(--primary-blue);
    color: var(--white);
    border: none;
  }
  .cookie-consent__close-icon {
    align-self: end;
    margin-bottom: 10px;
    cursor: pointer;
  }
`
interface CookieInterface {
  handleConsent: Function
}
const CookieConsent = ({ handleConsent }: CookieInterface): ReactElement => {
  const { t } = useTranslation(namespaces.pages.cookie)

  const handleConfirmation = (status: string): void => {
    if (!isEmpty(handleConsent) && typeof handleConsent === 'function') {
      handleConsent(status)
    }
  }

  return (
    <StyledCookieConsent>
      <section className="cookie-consent">
        <img
          src={closeIcon}
          width="15px"
          height="15px"
          onClick={(): void => {
            handleConfirmation(PENDING)
          }}
          className="cookie-consent__close-icon"
        />
        <p className="cookie-consent__title">
          {t('textOne')}
          <a
            className="cookie-consent__link"
            href="https://legal.pocketgeek.com/en/dashboard/privacy/1#service_providers"
            rel="noopener noreferrer"
            target="_blank"
          >
            {t('linkOne')}
          </a>
          {t('textTwo')}
          <a
            className="cookie-consent__link"
            href="https://legal.pocketgeek.com/en/dashboard/privacy/1#user_analytics_notice"
            rel="noopener noreferrer"
            target="_blank"
          >
            {t('linkTwo')}
          </a>
          {t('textThree')}
        </p>
        <div className="cookie-consent__wrapper">
          <button
            className="cookie-consent__wrapper-btn btn--allow"
            onClick={(): void => {
              handleConfirmation(CONFIRMED)
            }}
          >
            {t('buttonAllowCookies')}
          </button>
        </div>
      </section>
    </StyledCookieConsent>
  )
}

export default CookieConsent
