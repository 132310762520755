import { getDomainUrl, isEmpty } from '../../utils/common-methods'
import pocketGeekLogo from '../../assets/img/pocketgeek-logo.svg'
import xfinityLogo from '../../assets/img/xfinity-logo.svg'

/*
  Logos must use a resolved path from an import rather than a literal
  path value due to how Webpack loads assets.
*/
const logos = {
  POCKET_GEEK: pocketGeekLogo,
  XFINITY: xfinityLogo,
}

const issuer =
  process.env.REACT_APP_AUTH_ISSUER !== null ||
  process.env.REACT_APP_AUTH_ISSUER !== ''
    ? (process.env.REACT_APP_AUTH_ISSUER as string)
    : ''

const clientId =
  process.env.REACT_APP_AUTH_CLIENT_ID !== null ||
  process.env.REACT_APP_AUTH_CLIENT_ID !== ''
    ? (process.env.REACT_APP_AUTH_CLIENT_ID as string)
    : ''

const oktaConfig = {
  oidc: {
    useClassicEngine: true,
    clientId: `${clientId}`,
    redirectUri: `${window.location.origin}/authorization-code/callback`,
    issuer: `${issuer}/oauth2/default`,
    authParams: {
      pkce: true,
      scopes: ['openid', 'profile', 'email', 'groups'],
    },
  },
  widget: {
    logo: '/logo.png',
    features: {
      rememberMe: true,
      passwordlessAuth: true,
    },
  },
}

const OKTA_IDP_XFINITY =
  process.env.REACT_APP_AUTH_IDP_XFINITY !== undefined
    ? process.env.REACT_APP_AUTH_IDP_XFINITY
    : ''

/* Tenant SSO Config */
const SSO_ENABLED_TENANT_LIST = [
  {
    name: 'xfinity',
    idp: OKTA_IDP_XFINITY,
    signout: false,
    scopes: ['openid', 'profile', 'email', 'groups'],
    logo: logos.XFINITY,
  },
]
export const getAvailableTenant = (): any => {
  const tenant = SSO_ENABLED_TENANT_LIST.find(tenant =>
    getDomainUrl().includes((tenant?.name).toLocaleLowerCase())
  )

  if (!isEmpty(tenant)) {
    // if a tenant found
    return tenant
  }

  return {
    name: null,
    idp: null,
    signout: true,
    logo: logos.POCKET_GEEK,
  }
}

export default oktaConfig
