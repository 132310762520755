import { type ReactElement } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import styled from 'styled-components'
import { type StyledComponentInterface } from '../../../types/styled-components'

const StyledLoader = styled.div<StyledComponentInterface>`
  ${(props: any) => props?.styles ?? ''}
`

const Loader = (props: any): ReactElement => {
  return (
    <StyledLoader className="loader-wrapper" styles={props?.styles}>
      <CircularProgress />
    </StyledLoader>
  )
}

export default Loader
