import { type ReactElement, useEffect, useState, useContext } from 'react'
import styled from 'styled-components'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import TabHeader from '../../components/tab-header'
import DashboardTab from '../../components/dashboard-tab'
import { useAppDispatch, useAppSelector } from '../../store'
import {
  checkAuthError,
  getDomainUrl,
  isEmpty,
} from '../../utils/common-methods'
import client from '../../services/apollo-client'
import { BUILD_MODEL_STATISTICS } from '../../services/queries/search-build-model-statistics'
import {
  setBatteryAverageChargeMean,
  setBatteryAverageLifeMean,
  setSearchedDevices,
  setSelectedDevice,
} from '../search-devices/search-devices-slice'
import SEARCH_BY_CLIENT_ID from '../../services/queries/search-clint-by-id'
import AlertMessage from '../../components/ui/alert'
import { namespaces } from '../../core/i18n/i18n.constants'
import { useOktaAuth } from '@okta/okta-react'
import LoaderPage from '../../components/loader-page'
import moment from 'moment'
import { RootContext } from '../dashboard'

const routesTabNames = [
  'overview',
  'battery',
  'signal',
  'storage',
  'audio',
  'settings',
  'activity',
  'status',
]
const checkTabNames = (tabName: string): boolean => {
  if (routesTabNames.includes(tabName)) {
    return true
  } else {
    return false
  }
}

const StyledDevice = styled.div`
  .device-container {
    max-width: 1240px;
    margin-right: auto;
    margin-left: auto;
    min-height: 60vh;
  }
  @media screen and (min-device-width: 1440px) and (max-device-width: 3860px) {
    .device-container {
      min-height: 75vh;
    }
  }
  @media (min-height: 1800px) {
    .device-container {
      min-height: 86vh;
    }
  }
`

interface DeviceInterface {
  setIsNotValidRoute: React.Dispatch<React.SetStateAction<boolean>>
}

interface DeviceRouteParamsInterface {
  id?: string
  name?: string
  phone?: string
  updated?: string
}

const Device = ({ setIsNotValidRoute }: DeviceInterface): ReactElement => {
  const { authState } = useOktaAuth()
  const params: any = useParams()
  const history = useHistory()
  const location = useLocation()
  const selectedTabName: string = !isEmpty(params.tab)
    ? params.tab.toLowerCase()
    : ''
  const selectedSubTabName: string = !isEmpty(params.page)
    ? params.page.toLowerCase()
    : ''
  const { t } = useTranslation(namespaces.common.server)
  const { t: t1 } = useTranslation(namespaces.common.text)
  const dispatch = useAppDispatch()
  const domainURL = getDomainUrl()
  const { selectedDevice, searchedDevices } = useAppSelector(
    state => state?.searchDevices
  )
  const { user } = useAppSelector(store => store?.dashboard)
  const [error, setError] = useState(null)
  const [alertOpen, setAlertOpen] = useState<boolean>(false)
  const [searching, setSearching] = useState<boolean>(true)
  const choosenDevice: DeviceRouteParamsInterface =
    location.state as DeviceRouteParamsInterface
  const [
    loadingAverageBatteryChargeMeanDataAPI,
    setLoadingAverageBatteryChargeMeanDataAPI,
  ] = useState<boolean>(true)
  const [
    loadingAverageBatteryLifeMeanDataAPI,
    setLoadingAverageBatteryLifeMeanDataAPI,
  ] = useState<boolean>(true)
  const [loadingDeviceByClientIdAPI, setLoadingDeviceByClientIdAPI] =
    useState<boolean>(true)

  const { handleAuthError } = useContext(RootContext)

  useEffect(() => {
    if (!isEmpty(selectedDevice) && !isEmpty(choosenDevice)) {
      tabNameValidation()
    } else if (isEmpty(choosenDevice?.id)) {
      if (
        params?.id !== selectedDevice?.id ||
        (selectedTabName.length > 0 && !checkTabNames(selectedTabName)) ||
        (selectedSubTabName.length > 0 && !checkTabNames(selectedSubTabName))
      ) {
        setIsNotValidRoute(true)
      }
    } else {
      history.push('/dashboard/search-devices')
    }
  }, [selectedDevice])

  /* Function that updates the lastSnapshot and lastCheckIn Time in the local storage */
  const syncSearchedDevicesCache = (): void => {
    const updatedSearchedDevices: any = searchedDevices?.map((item: any) => {
      if (item?.sub === user?.id && item?.domain === domainURL) {
        const updatedSearchedDevicesList = item?.searchedDevicesList?.map(
          (device: any) => {
            if (device.id === selectedDevice?.id) {
              return {
                ...device,
                lastSnapshotTime: selectedDevice?.lastSnapshotTime,
                lastCheckIn: selectedDevice?.lastCheckIn,
              }
            }
            return device
          }
        )
        return { ...item, searchedDevicesList: updatedSearchedDevicesList }
      }
      return item
    })
    dispatch(setSearchedDevices(updatedSearchedDevices))
  }

  /* To update the lastSnapshotTime and lastCheckInTime of recently viewed devices */
  useEffect(() => {
    const selectedDeviceData: any =
      searchedDevices
        ?.find(item => item?.sub === user?.id && item?.domain === domainURL)
        ?.searchedDevicesList?.filter(
          (device: any) => device?.id === selectedDevice?.id
        ) ?? []

    /* If lastSnapshotTime is not empty then the recently viewed selected device and lastest selected device
    lastSnapshotTime is compared OR if the lastSnapshotTime has updated from null to latest value then the
    lastSnapShotTime will be updated in the searchedDevices store */
    const validateLastSnapshotTime =
      (!isEmpty(selectedDeviceData[0]?.lastSnapshotTime) &&
        !isEmpty(selectedDevice?.lastSnapshotTime) &&
        moment(selectedDeviceData[0]?.lastSnapshotTime) <
          moment(selectedDevice?.lastSnapshotTime)) ||
      (isEmpty(selectedDeviceData[0]?.lastSnapshotTime) &&
        !isEmpty(selectedDevice?.lastSnapshotTime))

    /* If lastCheckInTime is not empty then the recently viewed selected device and latest selected device
    lastCheckInTime is compared OR if the lastCheckInTime has updated from null to latest value then the
    lastCheckIn will be updated in the searchedDevices store */
    const validateLastCheckInTime =
      (!isEmpty(selectedDeviceData[0]?.lastCheckIn) &&
        !isEmpty(selectedDevice?.lastCheckIn) &&
        moment(selectedDeviceData[0]?.lastCheckIn) <
          moment(selectedDevice?.lastCheckIn)) ||
      (isEmpty(selectedDeviceData[0]?.lastCheckIn) &&
        !isEmpty(selectedDevice?.lastCheckIn))

    if (validateLastSnapshotTime || validateLastCheckInTime) {
      syncSearchedDevicesCache()
    }
  }, [selectedDevice])

  /* API Implemention to load device details */
  const fetchAverageBatteryChargeMeanDataAPI = async (): Promise<void> => {
    await client
      .query({
        query: BUILD_MODEL_STATISTICS,
        context: {
          headers: {
            authorization: authState?.accessToken?.accessToken,
          },
        },
        variables: {
          input: {
            buildModel: selectedDevice?.buildModel,
            code: 'battery_charge_rate',
          },
        },
        fetchPolicy: 'network-only',
      })
      .then((response: any) => {
        dispatch(
          setBatteryAverageChargeMean(response?.data?.buildModelStatistic?.mean)
        )
      })
      .catch((err: any | undefined) => {
        console.error(
          'ERROR Fetching build model statistics battery charge rate data: ',
          err
        )

        if (checkAuthError(err)) {
          handleAuthError()
        } else {
          setError(err)
        }
      })
  }
  const fetchAverageBatteryLifeMeanDataAPI = async (): Promise<void> => {
    await client
      .query({
        query: BUILD_MODEL_STATISTICS,
        context: {
          headers: {
            authorization: authState?.accessToken?.accessToken,
          },
        },
        variables: {
          input: {
            buildModel: selectedDevice?.buildModel,
            code: 'battery_discharge_rate',
          },
        },
        fetchPolicy: 'network-only',
      })
      .then((response: any) => {
        dispatch(
          setBatteryAverageLifeMean(response?.data?.buildModelStatistic?.mean)
        )
      })
      .catch((err: any | undefined) => {
        console.error(
          'ERROR Fetching build model statistics battery discharge rate data: ',
          err
        )

        if (checkAuthError(err)) {
          handleAuthError()
        } else {
          setError(err)
        }
      })
  }
  const fetchDeviceByClientIdAPI = async (): Promise<void> => {
    await client
      .query({
        query: SEARCH_BY_CLIENT_ID,
        context: {
          headers: {
            authorization: authState?.accessToken?.accessToken,
          },
        },
        variables: {
          input: {
            id: selectedDevice?.id,
          },
        },
        fetchPolicy: 'network-only',
      })
      .then((response: any) => {
        dispatch(setSelectedDevice(response?.data?.client))
      })
      .catch((err: any) => {
        dispatch(setSelectedDevice(selectedDevice))
        console.error('ERR Fetching client by id ', err)

        if (checkAuthError(err)) {
          handleAuthError()
        } else {
          setError(err)
        }
      })
  }

  useEffect(() => {
    setLoadingAverageBatteryChargeMeanDataAPI(true)
    fetchAverageBatteryChargeMeanDataAPI()
      .then(() => {
        console.info('got data from averageBatteryChargeMeanDataAPI')
      })
      .catch(err => {
        console.warn(err)
      })
      .finally(() => {
        setLoadingAverageBatteryChargeMeanDataAPI(false)
      })
  }, []) // fetch the average battery charge mean data

  useEffect(() => {
    setLoadingAverageBatteryLifeMeanDataAPI(true)
    fetchAverageBatteryLifeMeanDataAPI()
      .then(() => {
        console.info('got data from averageBatteryLifeMeanDataAPI')
      })
      .catch(err => {
        console.warn(err)
      })
      .finally(() => {
        setLoadingAverageBatteryLifeMeanDataAPI(false)
      })
  }, []) // fetch the average battery life(discharge) mean data

  useEffect(() => {
    setLoadingDeviceByClientIdAPI(true)
    fetchDeviceByClientIdAPI()
      .then(() => {
        console.info('got data from fetchDeviceByClientIdAPI')
      })
      .catch(err => {
        console.warn(err)
      })
      .finally(() => {
        setLoadingDeviceByClientIdAPI(false)
      })
  }, []) // fetch the device by its client id

  useEffect(() => {
    if (
      searching &&
      !loadingAverageBatteryChargeMeanDataAPI &&
      !loadingAverageBatteryLifeMeanDataAPI &&
      !loadingDeviceByClientIdAPI
    ) {
      setSearching(false)
    }
  }, [
    searching,
    loadingAverageBatteryChargeMeanDataAPI,
    loadingAverageBatteryLifeMeanDataAPI,
    loadingDeviceByClientIdAPI,
  ]) // close loader

  useEffect(() => {
    if (!isEmpty(error) && !alertOpen) {
      setAlertOpen(true)
    }
  }, [error, alertOpen]) // Alert error message

  const tabNameValidation = (): void => {
    if (
      params?.id !== choosenDevice?.id ||
      (selectedTabName.length > 0 && !checkTabNames(selectedTabName)) ||
      (selectedSubTabName.length > 0 && !checkTabNames(selectedSubTabName))
    ) {
      setIsNotValidRoute(true)
    } else {
      setIsNotValidRoute(false)
    }
  }

  return (
    <StyledDevice>
      {selectedDevice.id === params?.id && !searching ? (
        <section id="device">
          <TabHeader
            deviceData={{
              id: selectedDevice?.id,
              name: selectedDevice?.buildModel,
              phone: selectedDevice?.lineNumber,
              updated: selectedDevice?.lastSnapshotTime,
            }}
          />
          <div className="device-container">
            <DashboardTab />
          </div>
        </section>
      ) : (
        <LoaderPage title={t1('loading')} />
      )}

      {alertOpen && (
        <AlertMessage
          severity={'warning'}
          message={t('genericAPIErrorMessage')}
          messageFormatting={true}
          open={alertOpen}
          autoClose={false}
          onClose={(): void => {
            setError(null)
            setAlertOpen(false)
          }}
        />
      )}
    </StyledDevice>
  )
}

export default Device
