export const CheckBoxData = {
  roles: [
    {
      id: 0,
      name: 'CSR',
    },
    {
      id: 1,
      name: 'CSR Manager',
    },
  ],

  states: [
    {
      id: 0,
      name: 'Active',
    },
    {
      id: 1,
      name: 'Pending',
    },
    {
      id: 2,
      name: 'Password Reset',
    },
    {
      id: 3,
      name: 'Locked Out',
    },
  ],
  filterButton: [
    {
      id: 0,
      name: 'clearFilters',
      borderColor: '#3d515a',
    },
    {
      id: 1,
      name: 'applyFilters',
      borderColor: '#0066cc',
    },
  ],
}
