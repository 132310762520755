import { type ReactElement, Suspense, lazy } from 'react'
import { isEmpty } from '../../utils/common-methods'

// eslint-disable-next-line @typescript-eslint/promise-function-async
const SignIn = lazy(() => import('./default-login'))

// eslint-disable-next-line @typescript-eslint/promise-function-async
const SSO = lazy(() => import('./sso-login'))

const SignInOrchestrator = (props: any): ReactElement => {
  const { tenant = { name: '', idp: '', scopes: [] } } = props

  return (
    <Suspense>
      {isEmpty(tenant.name) ? <SignIn /> : <SSO {...tenant} />}
    </Suspense>
  )
}

export default SignInOrchestrator
