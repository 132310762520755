export const DropDownDataActive = [
  {
    id: 0,
    name: 'passwordReset',
  },
  {
    id: 1,
    name: 'delete',
  },
]
export const DropDownDataPending = [
  {
    id: 0,
    name: 'resendInvite',
  },
  {
    id: 1,
    name: 'delete',
  },
]
export const DropDownPasswordReset = [
  {
    id: 0,
    name: 'passwordReset',
  },
  {
    id: 1,
    name: 'delete',
  },
]
export const DropDownLokedOut = [
  {
    id: 1,
    name: 'delete',
  },
]
