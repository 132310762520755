import React, { type ReactElement } from 'react'
import styled from 'styled-components'
import { type StyledComponentInterface } from '../../types/styled-components'

const StyledCustomButton = styled.div<StyledComponentInterface>`
  .custom-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 144px;
    height: 42px;
    background-color: #fafcff;
    border: 1px solid;
    border-color: ${(props: any) => props.styles.borderColor};
    border-radius: 6px;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .custom-button__text {
    font-family: var(--font-family-primary);
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    text-align: center;
    color: ${(props: any) => props.styles.textColor};
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .custom-button:hover {
    background-color: var(--primary-blue);
    border-radius: 8px;
    border-color: var(--white);
    cursor: pointer;
    width: 144px;
    height: 42px;
  }
  .custom-button:hover .custom-button__text {
    color: var(--white);
  }
`

interface CustomButtonInterface {
  buttonString: string
  borderColor: string
  textColor: string
  sendData: (buttonName: string) => void
}
const CustomButton = ({
  buttonString,
  borderColor,
  textColor,
  sendData,
}: CustomButtonInterface): ReactElement => {
  const handleClick = (): void => {
    sendData(buttonString)
  }
  return (
    <StyledCustomButton
      styles={{
        borderColor,
        textColor,
      }}
    >
      <button className="custom-button" onClick={handleClick}>
        <p className="custom-button__text">{buttonString}</p>
      </button>
    </StyledCustomButton>
  )
}

export default CustomButton
