import {
  type ReactElement,
  useEffect,
  useState,
  useContext,
  lazy,
  Suspense,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useOktaAuth } from '@okta/okta-react'
import styled from 'styled-components'
import Loader from '../../components/ui/loader'
import { isPtp } from '../../utils/common-methods'
import { RootContext } from '../dashboard'
import { namespaces } from '../../core/i18n/i18n.constants'

// eslint-disable-next-line @typescript-eslint/promise-function-async
const ManageAccountHeader = lazy(() => import('./manage-account-header'))

// eslint-disable-next-line @typescript-eslint/promise-function-async
const ManageAccountInformation = lazy(() => import('./manage-account-info'))

// eslint-disable-next-line @typescript-eslint/promise-function-async
const ManageAccountPassword = lazy(() => import('./manage-account-password'))

const StyledManageAccountContent = styled.div`
  .manage-account-content-wrapper {
    min-height: 350px;
  }
  .manage-account-content-wrapper__account-password-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    row-gap: 24px;
  }
`

const ManageAccountContent = (): ReactElement => {
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [userId, setUserId] = useState('')
  const [loading, setLoading] = useState(true)
  const { user } = useContext(RootContext)
  const { oktaAuth } = useOktaAuth()

  useEffect(() => {
    oktaAuth
      .getUser()
      .then((info: any) => {
        setEmail(info?.email)
        setName(info?.name)
        setUserId(info?.sub)
        setLoading(false)
      })
      .catch((err: any) => {
        console.error('Error fetching Okta user details: ', err)
        setLoading(false)
      })
  }, [])

  const { t } = useTranslation(namespaces.pages.manageAccount)

  return (
    <StyledManageAccountContent>
      <Suspense fallback={<Loader />}>
        <ManageAccountHeader text={t('manageAccount')} />
      </Suspense>
      <section className="manage-account-content-wrapper">
        {loading ? (
          <Loader />
        ) : (
          <div className="manage-account-content-wrapper__account-password-wrapper">
            <Suspense fallback={<Loader />}>
              <ManageAccountInformation name={name} email={email} />
            </Suspense>
            {!isPtp(user) ? (
              <Suspense fallback={<Loader />}>
                <ManageAccountPassword email={email} userId={userId} />
              </Suspense>
            ) : null}
          </div>
        )}
      </section>
    </StyledManageAccountContent>
  )
}

export default ManageAccountContent
