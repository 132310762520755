import { type ReactElement } from 'react'
import { Modal } from '@mui/material'
import styled from 'styled-components'
import { isEmpty } from '../../utils/common-methods'

interface ConfirmationModalProps {
  isOpen: boolean
  header: string | undefined
  message?: string | undefined
  confirmationBtnText: string | undefined
  cancelBtnText?: string | undefined
  handleConfirmation: () => void
  closeModal: () => void
}

const StyledConfirmationModal = styled.div`
  .confirmation-modal-wrapper {
    background-color: var(--white);
    border-radius: 10px;
    color: var(--black);
    display: flex;
    flex-direction: column;
    font-family: var(--font-family-primary);
    margin: 12% auto;
    padding: 20px;
    width: 452px;
  }

  .confirmation-modal-header {
    font-family: var(--font-family-primary);
    align-items: center;
    color: var(--black);
    display: flex;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    justify-content: center;
    line-height: 33px;
    width: 100%;
  }

  .confirmation-modal-message {
    font-family: var(--font-family-primary);
    align-items: center;
    padding-right: 25px;
    padding-left: 25px;
    display: flex;
    font-size: 20px;
    font-weight: 400;
    text-align: left;
    width: 100%;
  }

  .confirmation-modal__divider {
    margin-top: 14px;
    margin-bottom: 14px;
    height: 1px;
    background-color: var(--light-grey-150);
    border: none;
  }

  .confirmation-modal-actions {
    align-items: center;
    display: flex;
    justify-content: space-around;
    margin: 20px auto;
    width: 100%;
  }

  .confirmation-modal-actions button {
    font-family: var(--font-family-primary);
    border-radius: 6px;
    cursor: pointer;
    font-size: 16px;
    padding: 10px 20px;
    min-width: 150px;
    max-width: 200px;
  }

  .confirmation-button {
    background-color: var(--primary-blue);
    color: var(--white);
    border: none;
  }

  .cancel-button {
    background: var(--white);
    border: 1px solid var(--primary-blue);
    color: var(--primary-blue);
  }
`

const ConfirmationModal = ({
  isOpen,
  header,
  message,
  confirmationBtnText,
  cancelBtnText,
  handleConfirmation,
  closeModal,
}: ConfirmationModalProps): ReactElement => {
  return (
    <Modal open={isOpen} onClose={closeModal}>
      <StyledConfirmationModal>
        <div className="confirmation-modal-wrapper">
          {!isEmpty(header) && (
            <>
              <p className="confirmation-modal-header">{header}</p>
              <hr className="confirmation-modal__divider" />
            </>
          )}

          {!isEmpty(message) && (
            <p className="confirmation-modal-message">{message}</p>
          )}

          <div className="confirmation-modal-actions">
            {!isEmpty(confirmationBtnText) && (
              <button
                className="confirmation-button"
                onClick={handleConfirmation}
              >
                {confirmationBtnText}
              </button>
            )}

            {!isEmpty(cancelBtnText) && (
              <button className="cancel-button" onClick={closeModal}>
                {cancelBtnText}
              </button>
            )}
          </div>
        </div>
      </StyledConfirmationModal>
    </Modal>
  )
}

export default ConfirmationModal
