import { useEffect, type ReactElement, type ReactNode } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { useAppSelector } from '../../store'

/* Declare */
interface Props {
  children: ReactNode
}

/* Impl Component */
const MainLayout = (props: Props): ReactElement => {
  const history = useHistory()
  const { user } = useAppSelector(store => store?.dashboard)
  const { token } = useAppSelector(store => store?.login)

  useEffect(() => {
    if (user === null || token == null) {
      history.replace('/login')
    }
  }, [user, token])

  return (
    <StyledMainLayout>
      <div className="csr-layout">
        <main className="csr-layout_content">{props?.children}</main>
      </div>
    </StyledMainLayout>
  )
}

/* Declare Styles */
const StyledMainLayout = styled.div`
  .csr-layout {
    margin: 0 auto;
  }
  .csr-layout_content {
    margin: 0 0 64px 0;
    width: 100%;
  }
  @media screen and (min-device-width: 1441px) and (max-device-width: 3860px) {
    .csr-layout_content {
      min-height: 81vh;
    }
  }
  @media (min-height: 1800px) {
    .csr-layout_content {
      min-height: 90vh !important;
    }
  }
`

export default MainLayout
