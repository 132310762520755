import { Button } from '@mui/material'
import React, { type ReactElement } from 'react'
import styled from 'styled-components'

const StyledPageCountButton = styled.div`
  .page-count-button {
    font-family: var(--font-family-primary);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-decoration-line: underline;
    color: var(--primary-blue);
    display: inline-block;
    padding: 0;
    min-height: 0;
    min-width: 0;
    text-transform: none;
    @media screen and (min-width: 2880px) {
      font-size: 24px;
      padding: 20px;
    }

    @media screen and (min-width: 3840px) {
      font-size: 32px;
      padding: 30px;
    }
  }

  .page-count-button-selected {
    background: var(--primary-blue);
    color: var(--white);
    border: 1px solid var(--light-grey-300);
    text-decoration-line: none !important;
    padding: 5px 10px;
    border-radius: 8px;
  }

  .page-count-button:hover {
    color: var(--primary-blue);
    text-decoration-line: underline;
  }
`

interface PageCountButtonInterface {
  onClick: React.MouseEventHandler<HTMLButtonElement>
  value: number
  children: string
  isSelected?: boolean
}

const PageCountButton = ({
  onClick,
  value,
  children,
  isSelected = false,
}: PageCountButtonInterface): ReactElement => {
  return (
    <StyledPageCountButton>
      <Button
        variant="text"
        onClick={onClick}
        value={value}
        className={`page-count-button ${
          isSelected ? 'page-count-button-selected' : ''
        }`}
      >
        {children}
      </Button>
    </StyledPageCountButton>
  )
}

export default PageCountButton
