import { ApolloClient, HttpLink, InMemoryCache, from } from '@apollo/client'
import { onError } from '@apollo/client/link/error'

const errorLink = onError(({ graphQLErrors = [], networkError }) => {
  if (graphQLErrors != null) {
    graphQLErrors?.forEach(({ message, locations, path }) => {
      console.error('[GraphQL error]: Message: ', message)
      console.info('[GraphQL error]: Location: ', locations)
      console.info('[GraphQL error]: Path: ', path)
    })
  }
  if (networkError != null) {
    console.error('[Network error]: ', networkError)
  }
})

const httpLink = new HttpLink({ uri: process.env.REACT_APP_SERVER_URL })

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([errorLink, httpLink]),
})

export default client
