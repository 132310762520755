import { gql } from '@apollo/client'

const AGENTS = gql`
  query Agents {
    agents {
      id
      email
      role
      firstName
      lastName
      state
      group
    }
  }
`

export default AGENTS
