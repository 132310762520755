import { type ReactElement, useContext, useMemo } from 'react'
import styled from 'styled-components'
import { Link, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Dropdown from '../ui/dropdown'
import { namespaces } from '../../core/i18n/i18n.constants'
import { RootContext } from '../../pages/dashboard'
import { isCSRM, removeKebabCase } from '../../utils/common-methods'
import { getAvailableTenant } from '../../core/auth/config'

const StyledHeaderSection = styled.div`
  .header__section {
    font-family: var(--font-family-primary);
    font-style: normal;
    max-width: 100%;
    padding: 10px 30px;
    margin: 0 auto;
    align-items: center;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .header__section__logo {
    display: flex;
    padding-top: 10px;
  }
  .header__section__link {
    text-decoration: none;
    color: var(--text-dark-grey);
    cursor: pointer;
  }
  .header__section__logo__img {
    max-width: 100%;
    height: auto;
    margin-right: 17px;
  }
  .header__section__logo__text {
    margin: 10px 15px auto 10px;
    padding-left: 20px;
    color: var(--text-dark-grey);
    font-size: 16px;
    line-height: 28px;
    cursor: pointer;
  }
  .header__section__logo__text__right {
    margin: 4px 15px auto 10px;
    padding-left: 20px;
    color: var(--text-dark-grey);
    font-size: 16px;
    line-height: 28px;
    cursor: pointer;
  }
  .header__section__nav__list {
    margin: 1em 0 0.5em;
    text-align: center;
    display: flex;
  }
  .header__section__nav__list__border {
    border-left: 1px solid var(--light-grey-300);
  }
  .header__section__nav__list__border__logo {
    border-left: 1px solid var(--light-grey-300);
    max-height: 40px;
    margin-top: 5px;
  }
`

interface HeaderSectionProps {
  path: string
  handleLogoutAPI: Function
}

const HeaderSection = (props: HeaderSectionProps): ReactElement => {
  const { t } = useTranslation(namespaces.pages.header)
  const { user } = useContext(RootContext)
  const history = useHistory()
  const { path: PATH, handleLogoutAPI } = props

  const logo = useMemo(() => getAvailableTenant().logo, [])

  const handleLinkToAccountSettings = (): void => {
    history.push(`${PATH}/manage-account`)
  }
  const handleLogout = (): void => {
    handleLogoutAPI()
  }
  const tenant = getAvailableTenant()

  const userDropDownMenu = [
    {
      id: 1,
      cb: handleLinkToAccountSettings,
      name: t('accountSettings'),
    },
  ]

  if (tenant.signout === true) {
    userDropDownMenu.push({
      id: 2,
      name: t('logout'),
      cb: handleLogout,
    })
  }

  return (
    <StyledHeaderSection>
      <header className="header">
        <div className="header__section">
          <div className="header__section__logo">
            <div className="mh-logo">
              <Link to={`${PATH}/search-devices`}>
                <img className="header__section__logo__img" src={logo} />
              </Link>
            </div>
            <span className="header__section__nav__list__border__logo"></span>
            <div className="header__section__logo__text">
              <Link
                to={`${PATH}/search-devices`}
                className="header__section__link"
              >
                {t('search')}
              </Link>
            </div>
          </div>
          <nav className="header__section__nav">
            <ul className="header__section__nav__list">
              {isCSRM(user) && (
                <>
                  <div className="header__section__logo__text__right">
                    <Link to={`${PATH}/user`} className="header__section__link">
                      {t('manageUsers')}
                    </Link>
                  </div>
                  <span className="header__section__nav__list__border"></span>
                </>
              )}
              <Dropdown
                menu={`${removeKebabCase(user?.name)}`}
                color={'var(--text-blue)'}
                menuData={userDropDownMenu}
              />
            </ul>
          </nav>
        </div>
      </header>
    </StyledHeaderSection>
  )
}

export default HeaderSection
