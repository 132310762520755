import { createSlice } from '@reduxjs/toolkit'

interface DashboardUser {
  user?: any
}

const initialState: DashboardUser = {
  user: null,
}

export const dashboardUserSlice = createSlice({
  name: 'dashboardUser',
  initialState,
  reducers: {
    setLoggedInUser: (state: any, action: any) => {
      state.user = action.payload
    },
    clearLoggedInUser: (state: any) => {
      state.user = null
    },
  },
})

export default dashboardUserSlice.reducer

export const { setLoggedInUser, clearLoggedInUser } = dashboardUserSlice.actions
