import React from 'react'
import ReactDOM from 'react-dom/client'

import App from './pages/app'
import GlobalStyle from './global'

import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import store, { persistor } from './store'

import { ApolloProvider } from '@apollo/client'
import client from './services/apollo-client'

import { BrowserRouter as Router } from 'react-router-dom'

import './core/i18n'

/**
 * prevent verbose logs getting printed in the production.
 */
if (process.env.NODE_ENV === 'production') {
  console.log = () => {} // noop
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <GlobalStyle />
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ApolloProvider client={client}>
          <Router>
            <App />
          </Router>
        </ApolloProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
)
