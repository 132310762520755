import {
  type SyntheticEvent,
  type ReactNode,
  type ReactElement,
  type Key,
  useState,
  Suspense,
  lazy,
} from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import Loader from '../ui/loader'
import { namespaces } from '../../core/i18n/i18n.constants'
import { DashboardTabRouteMapper } from './dashboard-tab-route-mapper'
import { useAppSelector } from '../../store'
import { isEmpty, isIphone } from '../../utils/common-methods'
import { type ParamsInterface } from '../../types/params-interface'

// eslint-disable-next-line @typescript-eslint/promise-function-async
const AudioTab = lazy(() => import('../../pages/audio'))

// eslint-disable-next-line @typescript-eslint/promise-function-async
const Battery = lazy(() => import('../../pages/battery'))

// eslint-disable-next-line @typescript-eslint/promise-function-async
const Overview = lazy(() => import('../../pages/overview'))

// eslint-disable-next-line @typescript-eslint/promise-function-async
const Settings = lazy(() => import('../../pages/settings'))

// eslint-disable-next-line @typescript-eslint/promise-function-async
const Signal = lazy(() => import('../../pages/signal'))

// eslint-disable-next-line @typescript-eslint/promise-function-async
const Storage = lazy(() => import('../../pages/storage'))

const StyledDashboardTab = styled.div`
  .Mui-selected {
    border-color: var(--primary-blue);
  }
  .dashboard__tab {
    min-height: 65vh;
    margin-top: 32px;
    margin-bottom: 32px;
  }
  @media screen and (min-width: 3840px) {
    .dashboard__tab {
      min-height: 79vh;
    }
  }
  .dashboard__tab__main {
    width: 100%;
  }
  .dashboard__tab__main__section {
    margin-bottom: 30px;
  }
  .dashboard__tab__main__section__tab-button {
    margin-bottom: 0px;
    border-bottom: 2px solid;
    border-color: var(--light-grey-250);
    text-transform: initial;
    font-weight: 600 !important;
    font-size: 16px;
    color: var(--text-dark-grey);
  }
  @media screen and (min-width: 2880px) {
    .dashboard__tab {
      min-height: 73vh !important;
    }
  }
  @media screen and (min-device-width: 768px) and (max-device-width: 1020px) {
    .dashboard__tab__main {
      width: 97% !important;
      margin: 11px !important;
    }
  }
`

interface TabPanelInterface {
  children?: ReactNode
  index: number
  value: number
}

const TabPanel = (props: TabPanelInterface): ReactElement => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div className="tab__section">
          <div>{children}</div>
        </div>
      )}
    </div>
  )
}

const a11yProps = (index: number): any => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const DashboardTabs = (): ReactElement => {
  const { t } = useTranslation(namespaces.pages.dashboard)
  const { selectedDevice } = useAppSelector(state => state.searchDevices)
  const history = useHistory()
  const params = useParams<ParamsInterface>()
  const selectedTabName = !isEmpty(params.tab) ? params.tab.toLowerCase() : ''

  let currentPath = 0
  DashboardTabRouteMapper.forEach(mapper => {
    if (mapper.route === (selectedTabName.length > 0 && selectedTabName)) {
      currentPath = mapper.tabIndex
    }
  })

  const [selectedTab, setSelectedTab] = useState(currentPath)

  const handleChange = (
    event: SyntheticEvent,
    selectedTabIndex: number
  ): void => {
    let route = ''
    DashboardTabRouteMapper.forEach(mapper => {
      if (selectedTabIndex === mapper.tabIndex) {
        route = mapper.route
      }
    })
    history.push(`/dashboard/search-devices/${params.id}/${route}`)
    setSelectedTab(selectedTabIndex)
  }

  const isValidIphoneRoute = (route: string): boolean => {
    const iphoneInvalidRoutes = ['audio', 'signal', 'battery']
    if (!iphoneInvalidRoutes.includes(route)) {
      return true
    }
    return false
  }

  return (
    <StyledDashboardTab>
      <div className="dashboard__tab">
        <div className="dashboard__tab__main">
          <div className="dashboard__tab__main__section">
            <Tabs
              value={selectedTab}
              onChange={handleChange}
              aria-label="basic tabs example"
              variant="fullWidth"
            >
              {!isEmpty(DashboardTabRouteMapper) &&
                DashboardTabRouteMapper.map((option, index: Key) => {
                  if (
                    !isValidIphoneRoute(option.route) &&
                    isIphone(selectedDevice)
                  ) {
                    return <div key={index.toString()}></div>
                  }
                  return (
                    <Tab
                      key={index?.toString()}
                      label={t(option.route)}
                      {...a11yProps(option.tabIndex)}
                      className="dashboard__tab__main__section__tab-button"
                    />
                  )
                })}
            </Tabs>
          </div>
          <TabPanel value={selectedTab} index={0}>
            <Suspense fallback={<Loader />}>
              <Overview />
            </Suspense>
          </TabPanel>
          <TabPanel value={selectedTab} index={1}>
            <Suspense fallback={<Loader />}>
              <Battery />
            </Suspense>
          </TabPanel>
          <TabPanel value={selectedTab} index={2}>
            <Suspense fallback={<Loader />}>
              <Signal />
            </Suspense>
          </TabPanel>
          <TabPanel value={selectedTab} index={3}>
            <Suspense fallback={<Loader />}>
              <Storage />
            </Suspense>
          </TabPanel>
          <TabPanel value={selectedTab} index={4}>
            <Suspense fallback={<Loader />}>
              <AudioTab />
            </Suspense>
          </TabPanel>
          <TabPanel value={selectedTab} index={5}>
            <Suspense fallback={<Loader />}>
              <Settings />
            </Suspense>
          </TabPanel>
        </div>
      </div>
    </StyledDashboardTab>
  )
}

export default DashboardTabs
