import { namespaces } from './i18n.constants'

const Spanish = {
  [namespaces.common.button]: {
    ok: 'Aceptar',
    cancel: 'Cancelar',
    login: 'Iniciar sesión',
  },
  [namespaces.common.server]: {
    genericAPIErrorMessage: 'Algo salió mal. Inténtalo de nuevo.',
    userCreatedSuccess: 'Usuario creado exitosamente',
    userUpdatedSuccess: 'Usuario actualizado exitosamente.',
    userAlreadyExists: 'El usuario ya existe en el sistema.',
    emailSentSuccessfully: 'Correo electrónico enviado exitosamente',
  },
  [namespaces.common.text]: {
    loading: 'Cargando',
    retry: 'Reintentar',
    updated: 'Última actualización: ',
    ifOff: 'Si desactivada',
    ifOffState: 'Si está desactivada',
    ifOffWifiState: 'Siel punto de',
  },
  [namespaces.common.calendar]: {
    january: 'Enero',
    february: 'Febrero',
    march: 'Marzo',
    april: 'Abril',
    may: 'Mayo',
    june: 'Junio',
    july: 'Julio',
    august: 'Agosto',
    september: 'Septiembre',
    october: 'Octubre',
    november: 'Noviembre',
    december: 'Diciembre',
    sunday: 'Domingo',
    monday: 'Lunes',
    tuesday: 'Martes',
    wednesday: 'Miércoles',
    thursday: 'Jueves',
    friday: 'Viernes',
    saturday: 'Sábado',
  },
  [namespaces.pages.auth]: {
    username: 'LAN ID o correo electrónico de Assurant',
    forgotPassword: '¿Ha olvidado su contraseña?',
    email: 'Correo electrónico de Assurant',
    internalUserGuide:
      'Los empleados de Assurant deben utilizar https://pwreset.assurant.com',
  },
  [namespaces.common.error]: {
    errorMessageTitleStandard: '¡Parece que algo salió mal en este momento!',
    errorMessageDescriptionStandard:
      'Por favor, inténtelo de nuevo después de un tiempo.',
    sessionExpiredTitle: 'Sesión de Usuario Expirada',
    sessionExpiredMessage:
      '¡Su sesión ha expirado! Por favor, inicie sesión de nuevo para continuar.',
    sessionExpireMessageGeneric:
      'Has sido desconectado. Por favor inicia sesión de nuevo para continuar.',
  },
  [namespaces.pages.cookie]: {
    textOne:
      'Usamos cookies para brindarle una mejor experiencia y empleamos análisis para estadísticas y mediciones. Esta información puede ser sobre usted, sus preferencias, su dispositivo o usarse para hacer que el sitio web funcione. Puede elegir permitir ciertos tipos de cookies. Haga clic a continuación para aceptar dichos fines y compartir sus datos con ',
    linkOne: 'nuestros socios de confianza. ',
    textTwo: 'Más información sobre cómo usamos ',
    linkTwo: 'cookies',
    textThree: '.',
    buttonAllowCookies: 'Permitir todas las cookies',
  },
  [namespaces.pages.login]: {
    welcome: 'Bienvenido al panel de control del agente',
  },
  [namespaces.pages.header]: {
    manageUsers: 'Administrar usuarias',
    search: 'Buscar',
    logout: 'Cerrar sesión',
    accountSettings: 'Ajustes de cuenta',
  },
  [namespaces.pages.footer]: {
    select_language: 'Selecciona un idioma :',
    assurant_copyright:
      'Assurant, Inc. Todos los derechos reservados. Patentes otorgadas y pendientes.',
    privacyNotice: 'PrivacidadAviso',
    cookies: 'Cookies',
    termsOfService: 'Condiciones del servicio',
  },
  [namespaces.pages.dashboard]: {
    overview: 'Información general',
    battery: 'Batería',
    signal: 'Señal',
    storage: 'Espacio de almacenamiento',
    settings: 'Ajustes',
    audio: 'Audio',
  },
  [namespaces.pages.overview]: {
    deviceStatus: 'Estado del dispositivo',
    activity: 'Actividad',
  },
  [namespaces.pages.activity]: {
    filterByType: 'Filtrado por tipo',
    filterByDate: 'Filtrado por Fecha',
    reset: 'Reiniciar',
    apply: 'Aplicar',
    view: 'Vista',
    from: 'de',
    all: 'Toda',
    currentActivityInfo:
      'La información de la actividad no está disponible en este momento. Estará disponible una vez que el dispositivo se comunique con el servidor.',
    power_connected_event: 'Evento conectado a la energía',
    wifi_connected_to: 'wifi conectado a',
    registered_this_device_with_our_erver:
      'Registrado este dispositivo con nuestro servidor',
    alert_expired_event: 'Alerta de evento caducado',
    alert_notified_event: 'Alerta de evento notificado',
    alert_triggered_event: 'Evento activado por alerta',
    app_inventory_event: 'Evento de inventario de aplicaciones',
    attempted_unnecessary_sdk_initialization_event:
      'Intento de evento de inicialización de sdk innecesario',
    backup_event: 'Evento de copia de seguridad',
    boot_event: 'Evento de arranque',
    checkin_requested_event: 'Registrar evento solicitado',
    commands_executed_event: 'Evento de comandos ejecutados',
    connectivity_change_event: 'Evento de cambio de conectividad',
    deactivate_event: 'Desactivar evento',
    device_update_event: 'Evento de actualización del dispositivo',
    email_activated_event: 'Evento activado por correo electrónico',
    email_changed_event: 'Evento de cambio de correo electrónico',
    escalation_event: 'Evento de escalada',
    lost_connectivity_event: 'Evento de pérdida de conectividad',
    metrics_upload_event: 'Evento de carga de métricas',
    mobile_connected_event: 'Evento móvil conectado',
    monitor_device_health_disabled_event:
      'Monitorear el evento de salud del dispositivo deshabilitado',
    monitor_device_health_enabled_event:
      'Supervisar el evento de salud del dispositivo habilitado',
    package_installed_event: 'Evento de paquete instalado',
    package_uninstalled_event: 'Evento de paquete desinstalado',
    power_disconnected_event: 'Evento de corte de energía',
    reconnect_event: 'Evento de reconexión',
    restore_event: 'Restaurar evento',
    sdk_initialized_event: 'Evento inicializado SDK',
    setting_changed_event: 'Configuración de evento modificado',
    shutdown_event: 'Evento de apagado',
    wifi_connected_event: 'Evento de conexión Wi-Fi',
    demote_event: 'Evento de degradación',
    device_health_email_event:
      'Evento de correo electrónico de estado del dispositivo',
    device_update_failed_event:
      'Evento de error de actualización del dispositivo',
    enrollment_email_event: 'Evento de correo electrónico de inscripción',
    gcm_registered_event: 'Evento registrado en GCM',
    located_device_email_event:
      'Evento de correo electrónico del dispositivo localizado',
    lock_disabled_event: 'Evento de bloqueo desactivado',
    lock_enabled_event: 'Evento de bloqueo habilitado',
    login_event: 'evento de inicio de sesión',
    malware_alert_suppressed_event: 'Evento suprimido de alerta de malware',
    password_changed_event: 'Evento de cambio de contraseña',
    password_reset_event: 'Evento de restablecimiento de contraseña',
    register_event: 'Registrar evento',
    track_disabled_event: 'Seguir evento deshabilitado',
    track_enabled_event: 'Seguimiento de eventos habilitados',
    verify_email_event: 'Verificar evento de correo electrónico',
    welcome_email_event: 'Evento de correo electrónico de bienvenida',
  },
  [namespaces.pages.deviceStatus]: {
    advancedSystemProperties: 'Propiedades avanzadas del sistema',
    currentlyViewing: 'Visualización actual',
    version: 'Versión impulsada por Pocket Geek®',
    battery: 'Batería',
    powerAdapter: 'Adaptador de corriente',
    dataUsage: 'Uso de datos',
    signal: 'Señal',
    storage: 'Espacio de almacenamiento',
    system: 'Sistema',
    operatingSystem: 'Sistema operativo',
    coreProcessors: 'Procesadores Core',
    averageCPULoad: 'Carga promedio de la CPU (últimos 5 minutos)',
    currentLevel: 'Nivel actual',
    currentLevelDescription: 'Carga actual de la batería',
    firmwareHealth: 'Condición del firmware',
    firmwareHealthDescription:
      'Estado informado por el firmware de la batería del OEM',
    batteryHogs: 'Batería de cerdos',
    batteryHogsDescription:
      'Rastrea las aplicaciones que han provocado un agotamiento excesivo de la batería durante un período de 30 minutos en los últimos 7 días',
    batteryTemperature: 'Temperatura de la batería',
    batteryTemperatureDescription:
      'Comprueba si la temperatura de la batería está fuera del rango recomendado en los últimos 7 días',
    averageBatteryLife: 'Duración promedio de la batería',
    averageBatteryLifeDescription:
      'Duración media de la batería de una carga completa en comparación con los usuarios con el mismo dispositivo',
    notAvailable: 'Desconocido',
    averageBatteryCharge: 'Tiempo de carga promedio',
    averageBatteryChargeDescription: 'Tiempo promedio para una carga completa',
    adapterChargeType: 'Tipo de cargador',
    adapterChargeTypeDescription:
      'Tipo de puerto de carga utilizado en el dispositivo',
    batteryChargingState: 'Estado de carga',
    batteryChargingStateDescription:
      'Si la batería se está cargando actualmente o no',
    appDataHogs: 'Acaparadores de datos de aplicaciones',
    appDataHogsDescription:
      'Aplicaciones que utilizan una cantidad excesiva de datos móviles',
    signalStrength: 'Intensidad de señal actual',
    signalStrengthDescription:
      'Intensidad de señal de la conexión móvil actual',
    internalStorage: 'Espacio de almacenamiento interno',
    internalStorageDescription: 'Espacio libre disponible en el dispositivo',
    externalStorage: 'Espacio de almacenamiento externo',
    externalStorageDescription: 'Espacio libre disponible en la tarjeta SD',
    deviceTampering: 'Manipulación de dispositivos',
    deviceTamperingDescription:
      'Comprueba si el dispositivo está rooteado o si tiene modificaciones personalizadas instaladas',
    daysSinceRebooted: 'Días desde el reinicio',
    daysSinceRebootedDescription:
      'Reiniciar un dispositivo regularmente puede mejorar el rendimiento del dispositivo',
    noneDetected: 'Ninguno detectado',
    ok: 'OK',
    warning: 'Advertencia!',
    warnings: 'Advertencias!',
    notAvailables: 'No disponible',
    noSignal: 'Sin señal',
    operatingSystemLabel: 'Nombre y versión de la plataforma móvil.',
    coreProcessorsLabel: 'Número de procesadores centrales en el dispositivo.',
    averageCPULoadLabel:
      'Carga promedio de CPU durante los últimos cinco minutos.',
    deviceStatusEmpty:
      'La información de estado del dispositivo no está disponible en este momento. Estará disponible una vez que el dispositivo se comunique con el servidor.',
    advancedSystemPropertiesText:
      'La información de Propiedades Avanzadas del Sistema no está disponible en este momento. Estará disponible una vez que el dispositivo se comunique con el servidor.',
    poor: 'Deficiente',
    fair: 'Regular',
    good: 'Bueno',
    excellent: 'Excelente',
    great: 'Muy buena',
    moderate: 'Moderada',
    charging: 'Cargando',
    discharging: 'Descargando',
    full: 'Llena',
    unplugged: 'Desconectada',
    more: ' más que el promedio',
    less: ' menos que el promedio',
    normal: 'Normal',
    excessiveBatteryPower:
      'aplicación(es) consumía(n) una cantidad excesiva de batería',
    excessiveData: 'aplicación(es) consumía(n) una cantidad excesiva de datos',
    hours: 'horas',
    minutes: 'minutos',
    faster: ' más rápido que el promedio',
    slower: ' más lento que el promedio',
    rooted: 'Rooteado',
    notRooted: 'No Enraizado',
    days: ' días',
  },
  [namespaces.pages.signal]: {
    wifiInfo:
      'La información sobre Wi-Fi y redes móviles se mostrará cuando esté disponible, generalmente en un plazo de 24 horas.',
    averageSignalQuality: 'Calidad de señal promedio',
    currentSignal: 'Señal actual',
    currentSignalInfo:
      'La información sobre la señal actual no está disponible en este momento. Estará disponible una vez que el dispositivo se conecte con el servidor.',
    averageSignalInfo:
      'La información de la señal actual no está disponible en este momento. Estará disponible una vez que el dispositivo se comunique con el servidor.',
    averageSignalQualityText:
      'La información sobre la calidad de la señal promedio no está disponible en este momento. Estará disponible una vez que el dispositivo se comunique con el servidor.',
    you: 'Tú  ',
    others: 'Otros  ',
    noSignal: 'Sin señal',
    poor: 'Deficiente',
    moderate: 'Moderado',
    fair: 'Regular',
    good: 'Bueno',
    great: 'Muy buena',
    excellent: 'Excelente',
    rssi: 'Indicador de señal de fuerza de recepción (RSSI)',
    rsrp: 'Señal de referencia de potencia recibida (RSRP)',
    rsrq: 'Calidad de señal recibida de referencia (RSRQ)',
    rssnr: 'Señal de referencia Relación señal/ruido (RSSNR)',
    cqi: 'Indicador de calidad del canal (CQI)',
    pci: 'ID de celda física (PCI)',
    tac: 'Código de área de seguimiento (TAC)',
    rsNrSsRsrp:
      'Potencia recibida de la señal de referencia de sincronización NR (5G)',
    rsNrSsRsrq:
      'Calidad recibida de la señal de referencia de sincronización NR (5G)',
    rsNrSsSinr:
      'Relación de la señal más interferencia más ruido de la señal de sincronización NR (5G)',
    gsmLac: 'Código de área de ubicación (LAC)',
    gsmCid: 'ID de celda (CID)',
    rsEcio: 'Ec/Io',
    rsEvdoSnr: 'Relación señal/ruido (SNR)',
    cdmaBasestationId: 'ID de estación base',
    cdmaBasestationLatitude: 'Latitud de estación base',
    cdmaBasestationLongitude: 'Longitud de estación base',
    cdmaBasestationNid: 'ID de red de estación base',
    cdmaBasestationSid: 'ID de sistema de estación base',
    informationAbout:
      'La información acerca de la comparación de este dispositivo con otros',
    informationAbout24Hours:
      'se mostrará una vez que esté disponible, generalmente dentro de las 24 horas.',
    averageOver: 'Promedio en los últimos 30 días, en comparación con otros',
    user: 'usuaria',
    rssiLabel: 'Medida de la potencia presente en una señal de radio recibida.',
    rsrpLabel:
      'Mide la potencia promedio recibida sobre los elementos de recursos que transportan señales de referencia específicas de la celda dentro de cierto ancho de banda de frecuencia.',
    rsrqLabel:
      'Tipo de medida C/I e indica la calidad de la señal de referencia recibida.',
    rssnrLabel:
      'Relación señal/ruido de la señal de referencia actual en unidades de 0,1 dB * Rango: -200 a +300 (-200 = -20,0 dB, +300 = 30 dB).',
    cqiLabel:
      'Se calcula sobre la marcha y se utiliza para optimizar la asignación de recursos entre los dispositivos finales de los usuarios que solicitan el servicio.',
    pciLabel:
      'La identificación de celda física tiene un rango de 0 a 503 y se usa para codificar los datos para ayudar al móvil a separar la información de los diferentes transmisores.',
    tacLabel: 'Código de área de seguimiento de 16 bits.',
    rsNrSsRsrpLabel:
      'NR(5G) Señal de sincronización Señal de referencia Potencia recibida.',
    rsNrSsRsrqLabel:
      'NR(5G) Señal de sincronización Señal de referencia Calidad recibida.',
    rsNrSsSinrLabel:
      'Señal de sincronización NR(5G) Relación señal/interferencia más ruido Medición de la intensidad de la señal deseada en comparación con la interferencia y el ruido no deseados.',
    gsmLacLabel:
      'Location Area Code (LAC) Conjunto de estaciones base para GSM que se agrupan para optimizar la señalización.',
    gsmCidLabel:
      'ID de celda (CID) Se utiliza para identificar cada estación transceptora base (BTS) o sector de una BTS.',
    cdmaBasestationIdLabel: 'Número de identificación de la estación base.',
    cdmaBasestationLatitudeLabel: 'Latitud de la estación base.',
    cdmaBasestationLongitudeLabel: 'Longitud de la estación base.',
    cdmaBasestationNidLabel:
      'Número de identificación de la red de la estación base.',
    cdmaBasestationSidLabel:
      'Número de identificación del sistema de la estación base.',
    rsCdmaEcioLabel:
      'Medida que representa la calidad/limpieza de la señal de.',
    rsEvdoRssiLabel: 'Indicador de señal de fuerza de recepción (RSSI).',
    rsEvdoSnrLabel:
      'Relación señal-ruido (SNR) Cuanto mayor sea la SNR, mayor rendimiento (mejor velocidad de carga/descarga) debe tener la conexión de los dispositivos.',
  },
  [namespaces.pages.storage]: {
    storageOverview: 'Información general sobre el espacio de almacenamiento',
    used: 'Utilizado',
    free: 'Libre',
    total: 'Total',
    application: 'Aplicacione',
    audio: 'Audio',
    others: 'Otros',
    videos: 'Vídeos',
    pictures: 'Imágenes',
    documents: 'Documentos',
    storageDisplay:
      'La información sobre el uso del espacio de almacenamiento se mostrará cuando esté disponible, generalmente en un plazo de 24 horas',
    storageTab:
      'La información general de almacenamiento no está disponible en este momento. Estará disponible una vez que el dispositivo se comunique con el servidor.',
  },
  [namespaces.pages.battery]: {
    batteryLevel: 'Nivel de bateria',
    averageBatteryLife: 'Duración promedio de la batería',
    you: 'Tú  ',
    others: 'Otros  ',
    averageOverLast30DaysComparedWithOther:
      'Promedio en los últimos 30 días, en comparación con otros',
    users: 'usuarios',
    noInformationText:
      'La información sobre la duración promedio de la batería no está disponible en este momento. Estará disponible una vez que el dispositivo se comunique con el servidor.',
    firmwareHealth: 'Condición del firmware',
    batteryState: 'Estado de la batería',
    batteryChargingType: 'Tipo de carga de la batería',
    batteryChargingTechnology: 'Tecnología de carga de batería',
    averageCharge: 'Tiempo de carga promedio',
    unplugged: 'Desconectada',
    unknown: 'Desconocido',
    learning: 'Aprendiendo',
    notAvailable: 'No disponible',
    firmwareHealthLabel: 'Estado informado por el firmware de la batería OEM.',
    batteryStateLabel:
      "Estado actual de la batería, ya sea 'Cargando' o 'Sin cargar'.",
    currentBatteryInfo:
      'La información de la batería no está disponible en este momento. Estará disponible una vez que el dispositivo se comunique con el servidor.',
    currentBattery: 'Información de la Batería',
    batteryChargingTypeLabel:
      'Fuente de carga del dispositivo, si se está cargando en ese momento. Las fuentes son A/C (cargador de pared), USB o desconocida.',
    batteryChargingTechnologyLabel:
      'Tipo de batería registrado por el dispositivo.',
    averageChargeLabel: 'Tiempo promedio para una carga completa.',
    good: 'Bueno',
    charging: 'Cargando',
    discharging: 'Descargando',
    full: 'Llena',
    ac: 'A/C',
    usb: 'USB',
    wireless: 'Inalámbrico',
    liion: 'Iones de litio',
  },
  [namespaces.pages.settings]: {
    audio: 'Audio',
    connectivity: 'Conectividad',
    display: 'Pantalla',
    details: 'Detalles',
    wifi: 'Wi-Fi',
    silentMode: 'Modo silencio',
    vibrateMode: 'Modo vibrar',
    hapticFeedback: 'Respuesta háptica',
    alarmVolume: 'Volumen de alarma',
    dtmfVolume: 'Volumen de DTMF',
    inCallVolume: 'Volumen durante llamadas',
    musicVolume: 'Volumen de música',
    notificationVolume: 'Volumen de notificaciones',
    ringerVolume: 'Volumen de tono de llamada',
    systemVolume: 'Volumen del sistema',
    airplaneMode: 'Modo avión',
    mobileData: 'Datos móviles',
    dataRoaming: 'Itinerancia de datos',
    bluetooth: 'Bluetooth',
    wiFiApEnabled: 'La aplicación de Wi-Fi está habilitada',
    wiFiMacAddress: 'Dirección Mac de Wi-Fi',
    wiFiNetwork: 'Red Wi-Fi',
    wiFiSignalStrength: 'Potencia de la señal Wi-Fi',
    wiFiStaticDns1: 'Dns estático de Wi-Fi 1',
    wiFiStaticDns2: 'Dns estático de Wi-Fi 2',
    wiFiStaticGateway: 'Puerta de enlace estática de Wi-Fi',
    wiFiStaticNetmask: 'Máscara de red estática de Wi-Fi',
    wiFiUseStaticIp: 'Ip estática de uso de Wi-Fi',
    bluetoothDiscoverable: 'Bluetooth Detectable',
    bluetoothDevicesPaired: 'Dispositivos Bluetooth emparejados',
    googleAccountLink: 'Vínculo a cuenta de Google',
    autoRotateScreen: 'Pantalla con rotación automática',
    screenTimeout: 'Tiempo de espera de pantalla',
    automaticBrightness: 'Brillo automático',
    on: 'Activada',
    off: 'Desactivada',
    minutes: '5 segundos',
    screenBrightness: 'Brillo de la pantalla',
    apnName: 'nombre apn',
    gps: 'GPS',
    hotspotActive: 'Punto de acceso activo',
    notAvailable: 'No disponible',
    noInformationText:
      'La información se mostrará una vez que esté disponible, generalmente dentro de las 24 horas',
    silentModeLabel:
      'Si activada, todos los sonidos se silencian y la vibración está inhabilitada. Si desactivada, los sonidos o la vibración están habilitados.',
    vibrateModeLabel:
      'Si activada, el dispositivo vibra en lugar de emitir sonidos. Si está desactivada, se habilitan los sonidos (el modo silencio puede estar activado incluso si la vibración está habilitada).',
    hapticFeedbackLabel:
      'Si activada, el dispositivo vibrará cuando se presionen los botones del teclado en la pantalla.',
    alarmVolumeLabel:
      'Nivel de volumen de las alarmas establecidas en el dispositivo.',
    dtmfVolumeLabel:
      'Volumen de los tonos DTMF (marcación multifrecuencia bitono). Esta es la sigla de los tonos de marcación.',
    inCallVolumeLabel:
      'Nivel de volumen utilizado durante las llamadas telefónicas.',
    musicVolumeLabel:
      'Nivel de volumen utilizado cuando se reproduce música en el dispositivo.',
    notificationVolumeLabel:
      'Nivel de volumen utilizado para diversas notificaciones, como SMS recibidos, nuevo correo electrónico, nuevo mensaje de voz, etc.',
    systemVolumeLabel:
      'Nivel de volumen predeterminado utilizado para cualquier función del dispositivo que no tenga sus propios ajustes de volumen.',
    ringerVolumeLabel:
      'Nivel de volumen utilizado para el tono de llamada cuando se reciben llamadas.',
    airplaneModeLabel:
      'Si activada, se inhabilitará la transmisión de señal al dispositivo. Mientras el modo avión esté activado, el dispositivo no permitirá realizar ni recibir llamadas, así como tampoco enviar ni recibir SMS.',
    mobileDataLabel:
      'Si activada, la red del proveedor de telefonía móvil se utiliza para transmitir y recibir datos (la conexión Wi-Fi no se usa o no está disponible). Si desactivada, la red del operador de telefonía móvil no se utiliza para transmitir datos (se usa una conexión Wi-Fi, se activó el modo avión o se inhabilitó la red de datos móviles).',
    dataRoamingLabel:
      'Si activada, se puede acceder a los datos desde un lugar fuera del área de servicio del operador. Si desactivada, no se utiliza la itinerancia de datos.',
    bluetoothLabel:
      'Si activada, el Bluetooth está actualmente habilitado en el dispositivo.  Si desactivada, el Bluetooth no está habilitado en el dispositivo.',
    wifiLabel:
      'Si activada, la Wi-Fi está habilitada en el dispositivo. Si desactivada, la Wi-Fi no está habilitada en el dispositivo.',
    googleAccountLinkLabel:
      'Si activada, se registró una cuenta de Google en el dispositivo.',
    gpsLabel:
      'Si está activada, las aplicaciones pueden tener acceso a la ubicación del dispositivo. La ubicación puede utilizar fuentes como GPS, Wi-Fi, redes móviles y sensores para ayudar a calcular la ubicación del dispositivo. Si está desactivada, las aplicaciones no tienen acceso a la ubicación del dispositivo.',
    hotspotActiveLabel:
      'Si está activada, el punto de acceso está actualmente habilitado en el dispositivo. Si está desactivada, el punto de acceso no está habilitado en el dispositivo.',
    autoRotateScreenLabel:
      'Si activada, la orientación de pantalla cambiará automáticamente para cualquier aplicación que permita tanto la visualización vertical como horizontal. Si desactivada, la orientación de pantalla se bloqueará.',
    screenTimeoutLabel:
      'Si activada, el dispositivo apagará la pantalla después de un período determinado. Si desactivada, no se agotará el tiempo de espera de la pantalla, lo que la mantendrá encendida.',
    automaticBrightnessLabel:
      'Si activada, la pantalla ajustará automáticamente los niveles de brillo. Si desactivada, puede ser que el brillo haya sido configurado manualmente.',
    screenBrightnessLabel: 'Nivel de brillo configurado manualmente.',
    wiFiApEnabledLabel:
      'Si activada, la función de punto de acceso Wi-Fi está habilitada en el dispositivo. Siel punto de acceso está desactivado, Wi-Fi no está habilitado en el dispositivo.',
    wiFiMacAddressLabel:
      'Identificador único para la radio Wi-Fi en el dispositivo.',
    wiFiNetworkLabel:
      'Nombre de la red Wi-Fi a la que el dispositivo está conectado.',
    wiFiSignalStrengthLabel: 'Potencia de la conexión Wi-Fi utilizada',
    wiFiStaticDns1Label:
      'Si el dispositivo utiliza una IP estática, esta es la dirección IP del DNS principal.',
    wiFiStaticDns2Label:
      'Si el dispositivo utiliza una IP estática, esta es la dirección IP del DNS secundario.',
    wiFiStaticGatewayLabel:
      'Si el dispositivo utiliza una IP estática, esta es la dirección IP de la puerta de enlace.',
    wiFiStaticNetmaskLabel:
      'Si el dispositivo utiliza una IP estática, esta es la máscara de red.',
    wiFiUseStaticIpLabel:
      'Indica si el dispositivo está configurado para utilizar una IP estática yotros atributos de la red estáticos.',
    bluetoothDiscoverableLabel:
      'Cantidad de dispositivos Bluetooth emparejados actualmente con este dispositivo.',
    bluetoothDevicesPairedLabel:
      'Si activada, el dispositivo puede realizar la detección o el emparejamiento mediante Bluetooth. Si desactivada, el período de detección no se habilitó o el tiempo de espera se agotó en el dispositivo.',
    poor: 'Deficiente',
    fair: 'Regular',
    good: 'Bueno',
    excellent: 'Excelente',
    great: 'Muy buena',
    moderate: 'Moderada',
    noSignal: 'Sin señal',
    min: 'min',
    sec: 'seg',
  },
  [namespaces.pages.searchDevices]: {
    title: 'Buscar dispositivos',
    searchPlaceholder: 'Buscar por Número de línea p. ej 999-999-9999',
    searchResults: 'Resultados de la búsqueda',
    recentlyViewed: 'Dispositivos visualizados recientemente',
    search: 'Buscar',
    showingResults: 'Mostrando resultados',
    from: 'de',
    all: 'Todo',
    view: 'Vista:',
    matchingRecords: 'Se encontraron 0 registros coincidentes',
    recentlyViewedDevices: '0 Dispositivos visualizados recientemente',
    clientId: 'ID de cliente',
    model: 'Modelo',
    application: 'Aplicación',
    lineNumber: 'Número de línea',
    lastCheckIn: 'Último registro',
  },
  [namespaces.pages.manageUser]: {
    manageUsers: 'Administrar usuarios',
    newUser: 'Nuevo usuario',
    searchByName: 'Buscar por correo electrónico o nombre...',
    scopingOptions: 'Opciones de ámbito',
    roles: 'Funciones',
    csr: 'CSR',
    csrManager: 'Gerente de CSR',
    states: 'Estados',
    active: 'Activos',
    suspended: 'Suspendido',
    pending: 'Pendiente',
    clearFilters: 'Borrar filtros',
    applyFilters: 'Aplicar filtros',
    result: 'Resultados',
    edit: 'Editar',
    resetPassword: 'Restablecer la contraseña',
    deactivate: 'Desactivar',
    createEntitlement: 'Crear derecho',
    view: 'Vista:',
    showingResults: 'Mostrando resultados',
    from: 'de',
    firstName: 'Nombre',
    lastName: 'Apellido',
    role: 'Role',
    email: 'Correo electrónico',
    state: 'Estado',
    client: 'Cliente',
    action: 'Acción',
    Action: 'Acción',
    all: 'Todo',
    userInformation: 'Información del usuario',
    userRole: 'Rol del usuario',
    required: 'Requerido',
    createUser: 'Crear usuario',
    isRequired: 'se requiere.',
    isNotValid: 'no es válido',
    userRoleRequired: 'Se necesita la función del usuario',
    firstNameRequired: 'Se necesita el nombre',
    lastNameRequired: 'Se necesita el apellido',
    emailRequired: 'Se necesita el correo electrónico',
    invalidEmailAddress: 'Dirección de correo electrónico no válida.',
    editUser: 'editar usuario',
    delete: 'eliminar',
    updateUser: 'Actualizar usuario',
    resendInvite: 'Reenviar invitacíon',
    passwordReset: 'Restablecimiento de contraseña',
    lockedOut: 'Bloqueado',
    close: 'Cerrar',
    exempliGratia: 'p. ej',
    modalConfirmationHeading: '¡Alerta!',
    modalConfirmationDescription:
      '¿Estás seguro de que quieres eliminar este usuario?',
    modalConfirmationDescriptionResend:
      '¿Estás seguro de que deseas reenviar la invitación?',
    modalConfirmationBtnProceed: 'eliminar',
    modalConfirmationBtnCancel: 'Cancelar',
    modalSuccessDescription: 'Usuario eliminado exitosamente.',
    modalInviteSuccessDescription:
      'La invitación al agente ha sido enviada exitosamente.',
    modalResetPasswordSuccessDescription:
      'Correo electrónico de restablecimiento de contraseña enviado con éxito.',
    modalSuccessBtnOk: 'Vale',
    modalConfirmationDescriptionManager:
      '¿Estás seguro de que deseas restablecer la contraseña del usuario? Si la contraseña no se restablece dentro de una hora, el usuario deberá comunicarse con un supervisor para recuperar el acceso al portal.',
    resetResendError:
      'No se pudo enviar el correo electrónico. Vuelve a intentarlo.',
    deleteError: 'Hubo un error al eliminar el usuario. Inténtalo de nuevo.',
    resetEmailMessage:
      'Correo electrónico enviado correctamente. Si tu contraseña no se restablece en el plazo de una hora, deberás comunicarte con tu supervisor para recuperar el acceso al portal.',
    searchPlaceholder: 'Buscar por correo electrónico o nombre...',
    matchingRecords: 'Se encontraron 0 registros coincidentes',
  },
  [namespaces.pages.audio]: {
    ringerVolume: 'Volumen de tono de llamada',
    alarmVolume: 'Volumen de alarma',
    inCallVolume: 'Volumen durante llamadas',
    bluetooth: 'Bluetooth',
    musicVolume: 'Volumen de la música',
    updatedTo: 'Actualizado a',
    view: 'Ver',
    from: 'de',
    noChanges: 'Sin cambios',
    noAdudioDataFound:
      'La información de audio no está disponible en este momento. Estará disponible una vez que el dispositivo se comunique con el servidor.',
    ringerVolumeText:
      'Si esta configuración se ajustó en baja o 0, esto podría explicar por qué el teléfono no suena durante una llamada. Solicita al usuario que ajuste el volumen del Timbre al 100 % y que realice una prueba solicitando que alguien lo llame para ver si esto resuelve el problema.',
    alarmVolumeText:
      'Si esta configuración se ajustó en baja o 0, esto podría explicar por qué la alarma no suena cuando se establece. Solicita al usuario que ajuste el volumen de la Alarma al 100 % y que realice una prueba para ver si esto resuelve el problema.',
    inCallVolumeText:
      'Si esta configuración se ajustó en baja o 0, esto podría explicar por qué no hay sonido al recibir una llamada. Solicita al usuario que ajuste el volumen de la Llamada al 100 % y que realice una prueba llamando a alguien para ver si esto resuelve el problema.',
    bluetoothText:
      'Si Bluetooth está activado y hay varios dispositivos conectados al mismo tiempo, esto podría explicar por qué el audio no se reproduce a través del dispositivo Bluetooth esperado. Solicita al usuario que active Bluetooth y asegúrate de que el dispositivo de audio esté encendido y conectado mediante Bluetooth. Luego asegúrate de que no haya ningún otro dispositivo Bluetooth seleccionado como salida de audio y realiza una prueba para ver si el audio se reproduce a través de los dispositivos Bluetooth.',
    musicVolumeText:
      'Si esta configuración se ajustó en baja o 0, esto podría explicar la falta de audio cuando se reproduce música o video. Solicita al usuario que ajuste el volumen de la Música al 100 % y que realice una prueba reproduciendo música o un video para ver si esto resuelve el problema.',
  },
  [namespaces.pages.manageAccount]: {
    manageAccountInformation: 'Administrar la información de la cuenta',
    fullName: 'Nombre completo',
    email: 'Correo electrónico',
    changePasswordText:
      'Usa el botón de abajo para enviar un correo electrónico y restablecer tu contraseña.',
    resetPassword: 'Restablecimiento de contraseña',
    manageAccount: 'Administrar cuenta',
    resetEmailMessage:
      'Correo electrónico enviado correctamente. Si tu contraseña no se restablece en el plazo de una hora, deberás comunicarte con tu supervisor para recuperar el acceso al portal.',
    resetError: 'No se pudo enviar el correo electrónico. Vuelve a intentarlo.',
    modalConfirmationHeading: '¡Alerta!',
    modalConfirmationDescriptionUser:
      '¿Estás seguro de que deseas restablecer tu contraseña? Si tu contraseña no se restablece en el plazo de una hora, deberás comunicarte con tu supervisor para recuperar el acceso al portal.',
    modalConfirmationBtnCancel: 'Cancelar',
  },
}

export default Spanish
