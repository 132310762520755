import { namespaces } from './i18n.constants'

const English = {
  [namespaces.common.button]: {
    ok: 'Ok',
    cancel: 'Cancel',
    login: 'Sign In',
  },
  [namespaces.common.server]: {
    genericAPIErrorMessage: 'Something went wrong. Please try again.',
    userCreatedSuccess: 'User successfully created.',
    userUpdatedSuccess: 'User successfully updated.',
    userAlreadyExists: 'User already exists in the system.',
    emailSentSuccessfully: 'Email sent successfully',
  },
  [namespaces.common.text]: {
    loading: 'Loading...',
    retry: 'Retry',
    updated: 'Last Updated: ',
    login: 'Login',
    ifOff: 'If off',
  },
  [namespaces.common.error]: {
    errorMessageTitleStandard: 'Seems like something went wrong at this time!',
    errorMessageDescriptionStandard: 'Please try again after sometime.',
    sessionExpiredTitle: 'User Session Expired',
    sessionExpiredMessage:
      'Your session got expired! Please login again to resume.',
    sessionExpireMessageGeneric:
      'You have been logged out. Please login again to resume.',
  },
  [namespaces.common.calendar]: {
    january: 'January',
    february: 'February',
    march: 'March',
    april: 'April',
    may: 'May',
    june: 'June',
    july: 'July',
    august: 'August',
    september: 'September',
    october: 'October',
    november: 'November',
    december: 'December',
    sunday: 'Sunday',
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
  },
  [namespaces.pages.auth]: {
    username: 'Assurant LAN ID or Email',
    forgotPassword: 'Forgot Password?',
    email: 'Email',
    internalUserGuide:
      'Assurant employees should use https://pwreset.assurant.com instead.',
  },
  [namespaces.pages.cookie]: {
    textOne:
      'We use cookies to provide you with a better experience, and employ analytics for statistics and measurements. This information may be about you, your preferences, your device or used to make the website work. You may choose to allow certain types of cookies. Click below to agree to such purposes and the sharing of your data with ',
    linkOne: 'our trusted partners. ',
    textTwo: 'Learn about how we use ',
    linkTwo: 'cookies',
    textThree: '.',
    buttonAllowCookies: 'Allow All Cookies',
  },
  [namespaces.pages.login]: {
    welcome: 'Welcome to Agent Dashboard',
  },
  [namespaces.pages.header]: {
    manageUsers: 'Manage Users',
    search: 'Search',
    logout: 'Logout',
    accountSettings: 'Account Settings',
  },
  [namespaces.pages.footer]: {
    select_language: 'Select a language:',
    assurant_copyright:
      'Assurant, Inc. All rights reserved. Patents granted and pending',
    privacyNotice: 'Privacy Notice',
    cookies: 'Cookies',
    termsOfService: 'Terms of Service',
  },
  [namespaces.pages.dashboard]: {
    overview: 'Overview',
    battery: 'Battery',
    signal: 'Signal',
    storage: 'Storage',
    settings: 'Settings',
    audio: 'Audio',
  },
  [namespaces.pages.overview]: {
    deviceStatus: 'Device Status',
    activity: 'Activity',
  },
  [namespaces.pages.activity]: {
    filterByType: 'Filter by Type',
    filterByDate: 'Filter by Date',
    reset: 'Reset',
    apply: 'Apply',
    view: 'View',
    from: 'from',
    all: 'All',
    currentActivityInfo:
      'Activity information is not available at this time. It will become available once the device communicates with the server.',
    power_connected_event: 'Power connected event',
    wifi_connected_to: 'Wi-Fi connected to',
    registered_this_device_with_our_erver:
      'Registered this device with our Server',
    alert_expired_event: 'Alert expired event',
    alert_notified_event: 'Alert notified event',
    alert_triggered_event: 'Alert triggered event',
    app_inventory_event: 'App inventory event',
    attempted_unnecessary_sdk_initialization_event:
      'Attempted unnecessary sdk initialization event',
    backup_event: 'Backup event',
    boot_event: 'Boot event',
    checkin_requested_event: 'Checkin requested event',
    commands_executed_event: 'Commands executed event',
    connectivity_change_event: 'Connectivity change event',
    deactivate_event: 'Deactivate event',
    device_update_event: 'Device update event',
    email_activated_event: 'Email activated event',
    email_changed_event: 'Email changed event',
    escalation_event: 'Escalation event',
    lost_connectivity_event: 'Lost connectivity event',
    metrics_upload_event: 'Metrics upload event',
    mobile_connected_event: 'Mobile connected event',
    monitor_device_health_disabled_event:
      'Monitor device health disabled event',
    monitor_device_health_enabled_event: 'Monitor device health enabled event',
    package_installed_event: 'Package installed event',
    package_uninstalled_event: 'Package uninstalled event',
    power_disconnected_event: 'Power disconnected event',
    reconnect_event: 'Reconnect event',
    restore_event: 'Restore event',
    sdk_initialized_event: 'Sdk initialized event',
    setting_changed_event: 'Setting changed event',
    shutdown_event: 'Shutdown event',
    wifi_connected_event: 'Wi-Fi connected event',
    demote_event: 'Demote event',
    device_health_email_event: 'Device health email event',
    device_update_failed_event: 'Device update failed event',
    enrollment_email_event: 'Enrollment email event',
    gcm_registered_event: 'Gcm registered event',
    located_device_email_event: 'Located device email event',
    lock_disabled_event: 'Lock disabled event',
    lock_enabled_event: 'Lock enabled event',
    login_event: 'Login event',
    malware_alert_suppressed_event: 'Malware alert suppressed event',
    password_changed_event: 'Password changed event',
    password_reset_event: 'Password reset event',
    register_event: 'Register event',
    track_disabled_event: 'Track disabled event',
    track_enabled_event: 'Track enabled event',
    verify_email_event: 'Verify email event',
    welcome_email_event: 'Welcome email event',
  },
  [namespaces.pages.deviceStatus]: {
    battery: 'Battery',
    powerAdapter: 'Power Adapter',
    dataUsage: 'Data Usage',
    signal: 'Signal',
    storage: 'Storage',
    system: 'System',
    operatingSystem: 'Operating System',
    coreProcessors: 'Core Processors',
    averageCPULoad: 'Average CPU Load (last 5 minutes)',
    advancedSystemProperties: 'Advanced System Properties',
    currentlyViewing: 'Currently Viewing',
    version: 'Powered by Pocket Geek® Version',
    currentLevel: 'Current Level',
    currentLevelDescription: 'Current charge of the battery',
    firmwareHealth: 'Firmware Health',
    firmwareHealthDescription: 'Status reported by OEM battery firmware',
    batteryHogs: 'Battery Hogs',
    batteryHogsDescription:
      'Tracks down apps that have caused excessive battery drain during a 30-minute window within the last 7 days',
    batteryTemperature: 'Battery Temperature',
    batteryTemperatureDescription:
      'Checks for battery temperature outside the recommended range within the last 7 days',
    averageBatteryLife: 'Average Battery Life',
    averageBatteryLifeDescription:
      'Average battery life of a full charge compared to users with the same device',
    notAvailable: 'Unknown',
    averageBatteryCharge: 'Average Charge Time',
    averageBatteryChargeDescription: 'Average time to reach full charge',
    adapterChargeType: 'Charger Type',
    adapterChargeTypeDescription: 'Type of charging port used in the device',
    batteryChargingState: 'Charging State',
    batteryChargingStateDescription:
      'If the battery is currently charging or not',
    appDataHogs: 'App Data Hogs',
    appDataHogsDescription:
      'Apps that use an excessive amount of cellular data',
    signalStrength: 'Current Signal Strength',
    signalStrengthDescription: 'Signal strength of current cellular connection',
    internalStorage: 'Internal Storage',
    internalStorageDescription: 'Free space available on device',
    externalStorage: 'External Storage',
    externalStorageDescription: 'Free space available on SD card',
    deviceTampering: 'Device Tampering',
    deviceTamperingDescription:
      'Checks if the device is rooted or has custom modifications installed',
    daysSinceRebooted: 'Days Since Reboot',
    daysSinceRebootedDescription:
      'Rebooting a device regularly can improve device performance',
    noneDetected: 'None Detected',
    ok: 'OK',
    warning: 'Warning!',
    warnings: 'Warnings!',
    notAvailables: 'Not available',
    operatingSystemLabel: 'Name and version of the mobile platform.',
    coreProcessorsLabel: 'Number of core processors in the device.',
    averageCPULoadLabel: 'Average CPU load for the last five minutes.',
    deviceStatusEmpty:
      'Device status information is not available at this time. It will become available once the device communicates with the server.',
    advancedSystemPropertiesText:
      'Advanced System Properties information is not available at this time. It will become available once the device communicates with the server.',
    noSignal: 'No signal',
    poor: 'Poor',
    fair: 'Fair',
    good: 'Good',
    excellent: 'Excellent',
    moderate: 'Moderate',
    great: 'Great',
    charging: 'Charging',
    discharging: 'Discharging',
    unplugged: 'Unplugged',
    full: 'Full',
    more: ' more than average',
    less: ' less than average',
    normal: 'Normal',
    excessiveBatteryPower: 'app(s) were using excessive battery power',
    excessiveData: 'app(s) used excessive data',
    hours: 'hour(s)',
    minutes: 'minute(s)',
    faster: ' faster than average',
    slower: ' slower than average',
    rooted: 'Rooted',
    notRooted: 'Not Rooted',
    days: ' days',
  },
  [namespaces.pages.signal]: {
    wifiInfo:
      'Wi-Fi and mobile network information will be displayed once it becomes available, typically within 24 hours ',
    averageSignalQuality: 'Average Signal Quality',
    currentSignal: 'Current Signal ',
    currentSignalInfo:
      'Current signal information is not available at this time. It will become available once the device communicates with the server.',
    averageSignalInfo:
      'Average signal information is not available at this time. It will become available once the device communicates with the server.',
    averageSignalQualityText:
      'Average signal quality information is not available at this time. It will become available once the device communicates with the server.',
    you: 'You  ',
    others: 'Others  ',
    noSignal: 'No signal',
    poor: 'Poor',
    moderate: 'Moderate',
    fair: 'Fair',
    good: 'Good',
    excellent: 'Excellent',
    great: 'Great',
    rssi: 'Receive Strength Signal Indicator (RSSI)',
    rsrp: 'Reference Signal Received Power (RSRP)',
    rsrq: 'Reference Signal Received Quality (RSRQ)',
    rssnr: 'Reference Signal Signal-to-Noise Ratio (RSSNR)',
    cqi: 'Channel Quality Indicator (CQI)',
    pci: 'Physical Cell ID (PCI)',
    tac: 'Tracking Area Code (TAC)',
    rsNrSsRsrp: 'NR(5G) Synchronization Signal Reference Signal Received Power',
    rsNrSsRsrq:
      'NR(5G) Synchronization Signal Reference Signal Received Quality',
    rsNrSsSinr:
      'NR(5G) Synchronization Signal Signal-to-Interference-plus-Noise Ratio',
    gsmLac: 'Location Area Code (LAC)',
    gsmCid: 'Cell ID (CID)',
    rsEcio: 'Ec/Io',
    rsEvdoSnr: 'Signal-to-Noise Ratio (SNR)',
    cdmaBasestationId: 'Base Station ID',
    cdmaBasestationLatitude: 'Base Station Latitude',
    cdmaBasestationLongitude: 'Base Station Longitude',
    cdmaBasestationNid: 'Base Station Network ID',
    cdmaBasestationSid: 'Base Station System ID',
    informationAbout: 'Information about how this device compares with other',
    informationAbout24Hours:
      'will be displayed once it becomes available, typically within 24 hours.',
    averageOver: 'Average over the last 30 days compared with other',
    user: 'user',
    rssiLabel: 'Measurement of the power present in a received radio signal.',
    rsrpLabel:
      'Measures the average received power over the resource elements that carry cell-specific reference signals within certain frequency bandwidth.',
    rsrqLabel:
      'C/I type of measurement and it indicates the quality of the received reference signal.',
    rssnrLabel:
      'Current reference signal signal-to-noise ratio in 0.1 dB units * Range: -200 to +300 (-200 = -20.0 dB, +300 = 30dB).',
    cqiLabel:
      'Computed on the fly and used to optimize resource allocation among user end devices that are requesting service.',
    pciLabel:
      'Physical Cell ID has a range of 0 to 503 and it is used to scramble the data to help the mobile separate information from the different transmitters.',
    tacLabel: '16-bit Tracking Area Code.',
    rsNrSsRsrpLabel:
      'NR(5G) Synchronization Signal Reference Signal Received Power.',
    rsNrSsRsrqLabel:
      'NR(5G) Synchronization Signal Reference Signal Received Quality.',
    rsNrSsSinrLabel:
      'NR(5G) Synchronization Signal Signal-to-Interference-plus-Noise Ratio Measurement of the  the strength of the wanted signal compared to the unwanted interference and noise.',
    gsmLacLabel:
      'Location Area Code (LAC) Set of base stations for GSM that are grouped together to optimize signaling.',
    gsmCidLabel:
      'Cell ID (CID) Used to identify each base transceiver station (BTS) or sector of a BTS.',
    cdmaBasestationIdLabel: 'Identification number for base station.',
    cdmaBasestationLatitudeLabel: 'Latitude of base station.',
    cdmaBasestationLongitudeLabel: 'Longitude of base station.',
    cdmaBasestationNidLabel: 'Base station network identification number.',
    cdmaBasestationSidLabel: 'Base station system identification number.',
    rsCdmaEcioLabel:
      'Measurement representing quality/cleanliness of the signal.',
    rsEvdoRssiLabel: 'Receive Strength Signal Indicator (RSSI).',
    rsEvdoSnrLabel:
      'Signal-to-Noise Ratio (SNR) The higher the SNR, the more throughput (better download/upload speed) the devices connection should have.',
  },
  [namespaces.pages.storage]: {
    storageOverview: 'Storage Overview',
    used: 'Used',
    free: 'Free',
    total: 'Total',
    application: 'Application',
    audio: 'Audio',
    others: 'Others',
    videos: 'Videos',
    pictures: 'Pictures',
    documents: 'Documents',
    storageDisplay:
      'Storage usage information will be displayed once it becomes available, typically within 24 hours',
    storageTab:
      'Storage overview information is not available at this time. It will become available once the device communicates with the server.',
  },
  [namespaces.pages.battery]: {
    batteryLevel: 'Battery Level',
    averageBatteryLife: 'Average Battery Life',
    you: 'You  ',
    others: 'Others  ',
    averageOverLast30DaysComparedWithOther:
      'Average over the last 30 days compared with other',
    users: 'users',
    noInformationText:
      'Average battery life information is not available at this time. It will become available once the device communicates with the server.',
    firmwareHealth: 'Firmware Health',
    batteryState: 'Battery State',
    batteryChargingType: 'Battery Charging Type',
    batteryChargingTechnology: 'Battery Charging Technology',
    averageCharge: 'Average Charge Time',
    unplugged: 'Unplugged',
    unknown: 'Unknown',
    learning: 'Learning',
    notAvailable: 'Not available',
    firmwareHealthLabel: 'Status reported by OEM battery firmware.',
    batteryStateLabel:
      "Current state of the battery; either 'Charging' or  'Not Charging'.",
    currentBatteryInfo:
      'Battery information is not available at this time. It will become available once the device communicates with the server.',
    currentBattery: 'Battery Information',
    batteryChargingTypeLabel:
      'The charging source for a device, if it is currently charging. The sources are either A/C (wall charger), USB, or unknown.',
    batteryChargingTechnologyLabel: 'Type of battery reported by the device.',
    averageChargeLabel: 'Average time to reach full charge.',
    good: 'Good',
    charging: 'Charging',
    discharging: 'Discharging',
    full: 'Full',
    ac: 'A/C',
    usb: 'USB',
    wireless: 'Wireless',
    liion: 'Li-ion',
  },
  [namespaces.pages.settings]: {
    audio: 'Audio',
    connectivity: 'Connectivity',
    display: 'Display',
    details: 'Details',
    wifi: 'Wi-Fi',
    silentMode: 'Silent Mode',
    vibrateMode: 'Vibrate Mode',
    hapticFeedback: 'Haptic Feedback',
    alarmVolume: 'Alarm Volume',
    dtmfVolume: 'DTMF Volume',
    inCallVolume: 'In-call Volume',
    musicVolume: 'Music Volume',
    notificationVolume: 'Notification Volume',
    ringerVolume: 'Ringer Volume',
    systemVolume: 'System Volume',
    airplaneMode: 'Airplane Mode',
    mobileData: 'Mobile Data',
    dataRoaming: 'Data Roaming',
    bluetooth: 'Bluetooth',
    wiFiApEnabled: 'Wi-Fi Hotspot',
    wiFiMacAddress: 'Wi-Fi MAC Address',
    wiFiNetwork: 'Wi-Fi Network',
    wiFiSignalStrength: 'Wi-Fi Signal Strength',
    wiFiStaticDns1: 'Wi-Fi Static DNS 1',
    wiFiStaticDns2: 'Wi-Fi Static DNS 2',
    wiFiStaticGateway: 'Wi-Fi Static Gateway',
    wiFiStaticNetmask: 'Wi-Fi Static Netmask',
    wiFiUseStaticIp: 'Wi-Fi Static IP',
    bluetoothDiscoverable: 'Bluetooth Discoverable',
    bluetoothDevicesPaired: 'Bluetooth Devices Paired',
    googleAccountLink: 'Google Account Link',
    autoRotateScreen: 'Auto Rotate Screen',
    screenTimeout: 'Screen Timeout',
    automaticBrightness: 'Automatic Brightness',
    on: 'On',
    off: 'Off',
    minutes: '5 minutes',
    screenBrightness: 'Screen Brightness',
    apnName: 'Apn Name',
    gps: 'GPS',
    hotspotActive: 'Hotspot Active',
    notAvailable: 'Not available',
    noInformationText:
      'Information will be displayed once it becomes available, typically within 24 hours',
    silentModeLabel:
      'If on, all sounds are muted and vibrate is disabled. If off, sounds or vibration are enabled.',
    vibrateModeLabel:
      'If on, device will vibrate instead of making any sounds. If off, sounds are enabled; silent mode may be on even if vibration is enabled.',
    hapticFeedbackLabel:
      'If on, device will vibrate when on-screen keyboard buttons are pressed.',
    alarmVolumeLabel: 'The volume level of any alarms set on the device.',
    dtmfVolumeLabel:
      'The volume for DTMF (dual tone multi frequency) tones. This is the acronym for touch tones.',
    inCallVolumeLabel: 'The volume level used during phone calls.',
    musicVolumeLabel: 'The volume level used when playing music on the device.',
    notificationVolumeLabel:
      'The volume level used for various notifications, such as SMS received, new email, new voicemail, etc.',
    systemVolumeLabel:
      'The default volume level used for anything on the device that does not have its own volume settings.',
    ringerVolumeLabel:
      'The volume level used for the ringer when a call is received.',
    airplaneModeLabel:
      'If on, device signal transmitting will be disabled. The device will not have the ability to place and receive calls or send and receive SMS while Airplane Mode is on.',
    mobileDataLabel:
      'If on, the mobile carriers network is being used to transmit and receive data; a Wi-Fi connection is not being used or is unavailable. If off, the mobile carriers network is not being used for data; either a Wi-Fi connection is being used, Airplane Mode is being used, or the Mobile Data Network has been disabled.',
    dataRoamingLabel:
      'If on, data is being accessed from outside the carrier service area. If off, data roaming is not being used.',
    bluetoothLabel:
      'If on, Bluetooth is currently enabled on the device.  If off, Bluetooth is not enabled on the device.',
    wifiLabel:
      'If on, Wi-Fi is enabled on the device. If off, Wi-Fi is not enabled on the device.',
    googleAccountLinkLabel:
      'If on, a Google account is registered on the device.',
    gpsLabel:
      'If on, apps may have access to the device location. Location may use sources like GPS, Wi-Fi, mobile networks, and sensors to help estimate the devices location. If off, app do not have access to the device location.',
    hotspotActiveLabel:
      'If on, Hotspot is currently enabled on the device. If off, Hotspot is not enabled on the device.',
    autoRotateScreenLabel:
      'If on, screen orientation will change automatically for any apps that enable both portrait and landscape display modes. If off, screen orientation will be locked.',
    screenTimeoutLabel:
      'If on, device will turn off the screen after a set time period. If off, screen will not timeout and will remain on.',
    automaticBrightnessLabel:
      'If on, screen will automatically adjust brightness levels. If off, brightness has been manually configured.',
    screenBrightnessLabel:
      'The level of brightness that has been manually configured.',
    wiFiApEnabledLabel:
      'If on, Wi-Fi hotspot functionality is enabled on the device. If off, Wi-Fi hotspot is not enabled on the device.',
    wiFiMacAddressLabel: 'Unique identifier for the Wi-Fi radio in the device.',
    wiFiNetworkLabel:
      'The name of the Wi-Fi network the device is connected to.',
    wiFiSignalStrengthLabel: 'Strength of the Wi-Fi connection being used.',
    wiFiStaticDns1Label:
      'If the device is using a static IP, this is the IP address of the primary DNS.',
    wiFiStaticDns2Label:
      'If the device is using a static IP, this is the IP address of the secondary DNS.',
    wiFiStaticGatewayLabel:
      'If the device is using a static IP, this is the gateways IP address.',
    wiFiStaticNetmaskLabel:
      'If the device is using a static IP, this is the net mask.',
    wiFiUseStaticIpLabel:
      'Indicates whether or not the device is set to use a static IP and other static network attributes.',
    bluetoothDiscoverableLabel:
      'The number of Bluetooth devices currently paired to this device.',
    bluetoothDevicesPairedLabel:
      'If on, device is able to be discovered/paired via Bluetooth. If off, discovery period has not been enabled, or has timed out on the device.',
    noSignal: 'No signal',
    poor: 'Poor',
    fair: 'Fair',
    good: 'Good',
    excellent: 'Excellent',
    moderate: 'Moderate',
    great: 'Great',
    min: 'min',
    sec: 'sec',
  },
  [namespaces.pages.searchDevices]: {
    title: 'Search Devices',
    searchPlaceholder: 'Search by line number e.g. 999-999-9999',
    searchResults: 'Search Results',
    recentlyViewed: 'Recently Viewed Devices',
    search: 'Search',
    showingResults: 'Showing results',
    from: 'from',
    all: 'All',
    view: 'View:',
    matchingRecords: '0 Matching Records Found',
    recentlyViewedDevices: '0 Recently Viewed Devices',
    clientId: 'Client Id',
    model: 'Model',
    application: 'Application',
    lineNumber: 'Line Number',
    lastCheckIn: 'Last Check In',
  },
  [namespaces.pages.manageUser]: {
    manageUsers: 'Manage Users',
    newUser: 'New User',
    searchByName: 'Search by email or name...',
    scopingOptions: 'Scoping Options',
    roles: 'Roles',
    csr: 'CSR',
    csrManager: 'CSR Manager',
    states: 'States',
    active: 'Active',
    suspended: 'Suspended',
    pending: 'Pending',
    clearFilters: 'Clear Filters',
    applyFilters: 'Apply Filters',
    result: 'Results',
    edit: 'Edit',
    resetPassword: 'Reset Password',
    deactivate: 'Deactivate',
    createEntitlement: 'Create Entitlement',
    view: 'View:',
    showingResults: 'Showing results',
    from: 'from',
    firstName: 'First Name',
    lastName: 'Last Name',
    role: 'Role',
    email: 'Email',
    state: 'State',
    client: 'Client',
    action: 'Action',
    all: 'All',
    userInformation: 'User Information',
    userRole: 'User Role',
    required: 'Required',
    createUser: 'Create User',
    isRequired: 'is required.',
    isNotValid: 'is not valid.',
    userRoleRequired: 'User Role is required',
    firstNameRequired: 'First Name is required',
    lastNameRequired: 'Last Name is required',
    emailRequired: 'Email is required',
    editUser: 'Edit User',
    delete: 'Delete',
    updateUser: 'Update User',
    resendInvite: 'Resend Invitation',
    passwordReset: 'Password Reset',
    lockedOut: 'Locked Out',
    close: 'Close',
    exempliGratia: 'e.g',
    modalConfirmationHeading: 'Alert!',
    modalConfirmationDescription: 'Are you sure you want to delete this user?',
    modalConfirmationDescriptionResend:
      'Are you sure you want to resend invitation?',
    modalConfirmationBtnProceed: 'Delete',
    modalConfirmationBtnCancel: 'Cancel',
    modalSuccessDescription: 'User successfully deleted.',
    modalInviteSuccessDescription:
      'Invite to agent has been sent successfully.',
    modalResetPasswordSuccessDescription:
      'Reset password email sent successfully.',
    modalSuccessBtnOk: 'Ok',
    modalConfirmationDescriptionManager:
      "Are you sure you want to reset the user's password? If the password is not reset within an hour, the user will need to contact a supervisor to regain access to the portal.",
    resetResendError: 'Email failed to send. Please try again.',
    deleteError: 'There was some error deleting user. Please try again.',
    resetEmailMessage:
      'Email sent successfully. If the password is not reset within an hour, the user will need to contact a supervisor to regain access to the portal.',
    searchPlaceholder: 'Search by email or name...',
    matchingRecords: '0 Matching Records Found',
  },
  [namespaces.pages.audio]: {
    ringerVolume: 'Ringer Volume',
    alarmVolume: 'Alarm Volume',
    inCallVolume: 'In-Call Volume',
    bluetooth: 'Bluetooth',
    musicVolume: 'Music Volume',
    updatedTo: 'Updated to',
    view: 'View',
    from: 'from',
    noChanges: 'No Changes',
    noAdudioDataFound:
      'Audio information is not available at this time. It will become available once the device communicates with the server.',
    ringerVolumeText:
      "If this setting was set to low or 0, this could explain why the phone doesn't ring during a call. Ask the user to set their Ringer volume to 100% and test having someone call them to see if this resolves their problem.",
    alarmVolumeText:
      'If this setting was set to low or 0, this could explain why the alarm is not sounding when set. Ask the user to set their Alarm volume to 100% and test an alarm to see if this resolves their problem.',
    inCallVolumeText:
      'If this setting was set to low or 0, this could explain why there is no sound when receiving a call. Ask the user to set their Call volume to 100% and test calling someone to see if this resolves their problem.',
    bluetoothText:
      "If Bluetooth was turned ON and there are multiple devices connected at the same time, this could explain why the audio isn't playing through an expected Bluetooth device. Ask the user to turn their Bluetooth ON, ensure the audio device is powered ON and connected by Bluetooth. Then ensure there are no other Bluetooth devices selected to output the audio and test to see if the audio plays through the Bluetooth devices.",
    musicVolumeText:
      'If this setting was set to low or 0, this could explain the lack of audio during music or video playback. Ask the user to set their Music volume to 100% and test it by playing music or a video to see if this resolves their problem.',
  },
  [namespaces.pages.manageAccount]: {
    manageAccountInformation: 'Manage Account Information',
    fullName: 'Full Name',
    email: 'Email',
    changePasswordText:
      'Use the button below to send an email to reset your password.',
    resetPassword: 'Password Reset',
    manageAccount: 'Manage Account',
    resetEmailMessage:
      'Email sent successfully. If your password is not reset within an hour, you will need to contact your supervisor to regain access to the portal.',
    resetError: 'Email failed to send. Please try again.',
    modalConfirmationHeading: 'Alert!',
    modalConfirmationDescriptionUser:
      'Are you sure you want to reset your password? If your password is not reset within an hour, you will need to contact your supervisor to regain access to the portal.',
    modalConfirmationBtnCancel: 'Cancel',
  },
}

export default English
