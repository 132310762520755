export const COLUMNS = [
  {
    Header: 'firstName',
    accessor: 'first_name',
  },
  {
    Header: 'lastName',
    accessor: 'last_name',
  },
  {
    Header: 'email',
    accessor: 'email',
  },
  {
    Header: 'roles',
    accessor: 'role',
    disableGlobalFilter: true,
  },
  {
    Header: 'state',
    accessor: 'state',
    disableGlobalFilter: true,
  },
]
