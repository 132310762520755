import { createSlice } from '@reduxjs/toolkit'

export interface RecentlyViewedDevices {
  domain: string
  sub: string
  searchedDevicesList: []
}

interface SearchDevices {
  selectedDevice?: any
  searchedDevices?: RecentlyViewedDevices[] | []
  batteryAverageLifeMean?: string | null
  batteryAverageChargeMean?: string | null
}

const initialState: SearchDevices = {
  selectedDevice: null,
  searchedDevices: [],
  batteryAverageLifeMean: null,
  batteryAverageChargeMean: null,
}

export const searchDeviceSlice = createSlice({
  name: 'searchDevices',
  initialState,
  reducers: {
    setSelectedDevice: (state: any, action: any) => {
      state.selectedDevice = action.payload
    },
    setSearchedDevices: (state: any, action: any) => {
      state.searchedDevices = action.payload
    },
    setBatteryAverageLifeMean: (state: any, action: any) => {
      state.batteryAverageLifeMean = action.payload
    },
    setBatteryAverageChargeMean: (state: any, action: any) => {
      state.batteryAverageChargeMean = action.payload
    },
  },
})

export default searchDeviceSlice.reducer

export const {
  setSelectedDevice,
  setSearchedDevices,
  setBatteryAverageLifeMean,
  setBatteryAverageChargeMean,
} = searchDeviceSlice.actions
