import { gql } from '@apollo/client'

const RESET_AGENT_PASSWORD = gql`
  mutation ResetAgentPassword($input: ResetAgentPasswordInput!) {
    resetAgentPassword(input: $input) {
      success
    }
  }
`

export default RESET_AGENT_PASSWORD
