import { type ReactElement, useEffect, useState } from 'react'
import Cookie, {
  CONFIRMED,
  PENDING,
  isAnalyticsConsentConfirmed,
} from './cookie-consent'
import {
  deleteCookie,
  getCookie,
  hasCookie,
  isEmpty,
  setCookie,
  removePrefix,
} from '../../utils/common-methods'
import { Mixpanel, isMixpanelAllowed } from '../../core/analytics'

interface AnalyticsConsentProps {
  isSSO?: boolean
  identity: object | undefined | null
}
const AnalyticsConsent = (props: AnalyticsConsentProps): ReactElement => {
  const { isSSO = false, identity = null } = props

  const [displayConsentCookie, setDisplayConsentCookie] =
    useState<boolean>(false)

  const handlePostLoginAnalytics = (target: any): void => {
    if (isMixpanelAllowed()) {
      if (isEmpty(target)) {
        Mixpanel.track('User not able to Login Successfully')
      } else {
        const userId: string = target?.identity?.claims?.uid
        const email: string = removePrefix(
          target?.identity?.claims?.sub,
          'ADB__'
        )

        try {
          Mixpanel.identify(userId)
          Mixpanel.track('User able to Login Successfully')
          Mixpanel.people.set({
            $email: email,
          })
        } catch (e) {
          Mixpanel.track('Not able to track email', {
            email,
            where: isSSO ? 'Dashboard' : 'Login',
          })
        }
      }
    }
  }

  const handleAnalytics = (status?: string): void => {
    status === PENDING
      ? setCookie('aiz_consent', PENDING, 1)
      : setCookie('aiz_consent', CONFIRMED, 365)

    setDisplayConsentCookie(false)
  }

  useEffect(() => {
    // handle negative scenario
    if (!displayConsentCookie) {
      if (!hasCookie('aiz_consent')) {
        setDisplayConsentCookie(true)
      } else {
        Mixpanel.track('User viewed consent banner', {
          where: isSSO ? 'Dashboard' : 'Login',
        })

        if (getCookie('aiz_consent') === CONFIRMED) {
          Mixpanel.track('User has provided consent for analytics.')
        } else {
          Mixpanel.track('User has not provided consent for analytics yet.')
        }
      }
    }

    return () => {
      const isConsentGiven = isAnalyticsConsentConfirmed('aiz_consent')
      if (!displayConsentCookie && isConsentGiven === PENDING) {
        deleteCookie('aiz_consent')
      }
    }
  }, [displayConsentCookie])

  useEffect(() => {
    if (isEmpty(identity)) {
      handlePostLoginAnalytics(null)
    } else {
      handlePostLoginAnalytics({
        identity,
      })
    }
  }, [identity])

  if (!displayConsentCookie) return <></>

  return <Cookie handleConsent={handleAnalytics} />
}

export default AnalyticsConsent
