import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
	* {
		margin: 0;
		padding: 0;
		outline: 0;
		box-sizing: border-box;
		font-family: 'Open Sans';
	}

	:root {
    --font-family-primary: 'Open Sans';

		--black: #13223c;
		--white: #ffffff;
		--red: #de0000;
		--cyan: #7d898d;
		--green: #059c2f;
		--orange: #e18801;
		--primary-black: #000000;
		--primary-blue: #0066cc;

		--light-orange: #e16d011a;
		--lime-green: #eafff0;
		--dark-green: #035e1d;

    --light-blue: #f7fafd;
		--light-blue-100: #ecf9ff;
		--light-blue-150: #b2c2cb;
    --light-blue-200: #f8fafb;
		--light-blue-300: #1f56a6;

    --text-blue: #2972c8;
		--text-blue-100: #2490EE;
		--text-blue-150: #004f9e;
		--text-blue-200: #3039521a;
		--text-blue-250: #007dfa;

		--silver-grey: #bdc4c7;

		--light-grey: #68758b;
		--light-grey-50: #3039521a;
		--light-grey-100: #fcfcfc;
		--light-grey-150: #f2f2f2;
    --light-grey-200: #30334080;
		--light-grey-250: #0000001f;
    --light-grey-300: #dbe1e2;
		--light-grey-350: #303340;
		--light-grey-400: #bdc4c7;
		--light-grey-500: #52636b;
		--light-grey-600: #3D515A;

		--dark-grey: #4d4d4d;
		--dark-grey-100: #52636b;
		--dark-grey-400: #666666;
		--dark-grey-500: #7d898d;

		--text-dark-grey: #3c4d6a;
		--text-grey-black: #596981;
		--text-dark-grey-100: #3d515a;

		--light-white: #fafcff;
		--light-orange-200: #de0000;
		--light-green: #4aba7b;
		--light-purple: #6212a1;
		--light-red: #ffaa2b;
		--dark-red: #B90000;
		--light-red-100: #FBF2F2;

    --disabled-bg: #dfdfdf;
    --disabled-text: #303340;
	}

	#root{
		margin: 0 auto;
	}

  input.disabled {
    background-color: var(--light-grey-50) !important;
    cursor: not-allowed;
  }

  table tr td {
	  border-left: none;
  }

	/* Chrome, Safari, Edge, Opera */
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	/* Firefox */
	input[type=number] {
	-moz-appearance: textfield;
	}

  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
		color: var(--light-grey);
		font-weight: 400;
	}
	::-ms-input-placeholder { /* Microsoft Edge */
		color: var(--light-grey);
		font-weight: 400;
		font-size: 12px;
	}

  /* MUI Dropdown Specific */
	.menu-item__a {
	  text-decoration: none;
	  color: var(--black);
	  width: 100%;
	}

	/* MUI Pagination button specifc */
	.MuiPaginationItem-root {
		font-family: var(--font-family-primary) !important;
		font-style: normal;
		font-weight: 700 !important;
		font-size: 16px !important;
		margin-right: 10px;
		width: 40px;
		height: 40px;
		background: var(--light-white) !important;
		border: 1px solid var(--light-grey-300);
		border-radius: 8px !important;
		cursor: pointer;
		color: var(--primary-blue) !important;
	}

	.MuiPaginationItem-ellipsis {
		border: none;
		color: var(--dark-grey) !important;
	}

	.Mui-selected.MuiPaginationItem-page {
		background: var(--primary-blue) !important;
		color: var(--light-white) !important;
	}
`

export const BREAKPOINT_BATTERY_LIFE_CHART_XYLABLES = 1230
/* Phone number validation */
export const PHONE_NUMBER_VALIDATION =
  /^(\+\d{1})?[.\s]?\(?(\d{3})\)?[-.\s]?(\d{3})[-.\s]?(\d{4})$/
/* Only allows numbers + () - . and space characters in the phone number */
export const DEVICE_SEARCH_CHAR_ALLOWED = /^[0-9+()-.\s]+$/
/* Does not allow the * and , character (special case) */
export const DEVICE_SEARCH_CHAR_SPECIALCASE = /[,*]+$/
/* Used to extract the valid phone number of format 1234567890 an +12345678909 */
export const FORMAT_DEVICE_SEARCH_PHONE_NUMBER = /[^0-9+]/g

export default GlobalStyle
