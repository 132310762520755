import { Modal } from '@mui/material'
import { type ReactElement } from 'react'
import styled from 'styled-components'
import closeIcon from '../../../assets/img/close-icon.png'
import { useTranslation } from 'react-i18next'
import { namespaces } from '../../../core/i18n/i18n.constants'
import { isEmpty } from '../../../utils/common-methods'

interface ModalInterface {
  modalOpen: boolean
  tableProps?: any
  agentId: string
  children: ReactElement
  closeModal: () => void
}

const StyledModal = styled.div`
  .modal {
    background: var(--white);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 6px;
    border: 1px solid var(--silver-grey);
    border-radius: 6px;
    height: auto;
    max-height: calc(100% - 30px);
    overflow-y: auto;
    overflow-x: hidden;
  }

  .modal__main-wrapper {
    width: 500px;
    background: var(--white);
    display: flex;
    flex-direction: column;
    padding: 24px 24px 50px 24px;
  }

  .modal__main-wrapper__close-icon {
    align-self: flex-end;
    cursor: pointer;
  }
  .modal__main-wrapper__title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 16px;
    gap: 16px;
    width: 452px;
    height: 46px;
  }
  .modal__main-wrapper__modal-title__text {
    margin-left: 16px;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    color: var(--black);
  }

  .modal__main-wrapper__modal-title__divider {
    height: 1px;
    background-color: var(--light-grey-150);
    border: none;
    margin-top: 8px;
  }

  .modal__main-wrapper__user-information {
    margin-left: 16px;
    margin-top: 14px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    color: var(--black);
  }
`

const ModalManageUsers = ({
  modalOpen,
  agentId,
  children,
  closeModal,
}: ModalInterface): ReactElement => {
  const { t } = useTranslation(namespaces.pages.manageUser)
  const isCreateUserFlow = isEmpty(agentId)

  return (
    <Modal
      open={modalOpen}
      sx={{ background: 'var(--light-grey-200)' }}
      disableScrollLock
      onClose={(): void => {
        closeModal()
      }}
    >
      <StyledModal>
        <section className="modal">
          <div className="modal__main-wrapper">
            {/* modal close Icon */}
            <img
              className="modal__main-wrapper__close-icon"
              width="16px"
              height="16px"
              src={closeIcon}
              title={`${t('close')}`}
              onClick={() => {
                closeModal()
              }}
            />

            {/* modal title */}
            <div className="modal__main-wrapper__modal-title">
              <p className="modal__main-wrapper__modal-title__text">
                {isCreateUserFlow ? `${t('newUser')}` : `${t('editUser')}`}
              </p>
              <hr className="modal__main-wrapper__modal-title__divider"></hr>
            </div>

            {/* modal user info text */}
            <p className="modal__main-wrapper__user-information">
              {`${t('userInformation')}`}
            </p>

            {/* create user modal form */}
            {children}
          </div>
        </section>
      </StyledModal>
    </Modal>
  )
}

export default ModalManageUsers
