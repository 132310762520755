import { gql } from '@apollo/client'

const SEARCH_BY_CLIENT_ID = gql`
  query getClientByID($input: ClientInput!) {
    client(input: $input) {
      id
      lastSnapshotTime
      alerts {
        code
        data
        status
      }
      application
      applicationVersion
      buildModel
      deviceProperties {
        audio {
          history {
            alarmVolume {
              code
              newValue
              oldValue
              snapshotTime
            }
            bluetoothAudioAvailable {
              code
              newValue
              oldValue
              snapshotTime
            }
            dtmfVolume {
              code
              newValue
              oldValue
              snapshotTime
            }
            inCallVolume {
              code
              newValue
              oldValue
              snapshotTime
            }
            musicVolume {
              code
              newValue
              oldValue
              snapshotTime
            }
            notificationVolume {
              code
              newValue
              oldValue
              snapshotTime
            }
            ringerModeSilent {
              code
              newValue
              oldValue
              snapshotTime
            }
            ringerModeVibrate {
              code
              newValue
              oldValue
              snapshotTime
            }
            ringerVolume {
              code
              newValue
              oldValue
              snapshotTime
            }
            systemVolume {
              code
              newValue
              oldValue
              snapshotTime
            }
            vibrateOnKeypress {
              code
              newValue
              oldValue
              snapshotTime
            }
          }
          latest {
            alarmVolume
            bluetoothAudioAvailable
            dtmfVolume
            inCallVolume
            musicVolume
            notificationVolume
            ringerModeSilent
            ringerModeVibrate
            ringerVolume
            snapshotTime
            systemVolume
            vibrateOnKeypress
          }
        }
        battery {
          averageBatteryLife
          averageCharge
          latest {
            chargerType
            chargingState
            chargingTechnology
            currentLevel
            firmwareHealth
            levelDifference
          }
        }
        connectivity {
          latest {
            airplaneMode
            bluetoothDevicesPaired
            bluetoothDiscoverability
            bluetoothEnabled
            dataRoamingEnabled
            googleAccount
            gpsEnabled
            hotspotActive
            mobileNetworkEnabled
            wifiApEnabled
            wifiEnabled
            wifiMacAddress
            wifiSignalStrength
            wifiStaticDns1
            wifiStaticDns2
            wifiStaticGateway
            wifiStaticGateway
            wifiStaticNetmask
            wifiUseStaticIp
          }
        }
        deviceDisplay {
          latest {
            autoBrightnessOn
            autoScreenRotation
            screenBrightness
            screenOffTimeout
          }
        }
        signal {
          averageSignal
          history {
            cdmaBasestationId
            cdmaBasestationLatitude
            cdmaBasestationLongitude
            cdmaBasestationNid
            cdmaBasestationSid
            gsmArfcn
            gsmBsic
            gsmCid
            gsmLac
            lteBands
            lteBandwidth
            lteCi
            lteEarfcn
            ltePci
            lteTac
            mobileSignalStrength
            networkType
            nrArfcn
            nrNci
            nrPci
            nrTac
            rsGsmBer
            rsGsmCsq
            rsGsmDbm
            rsGsmRssi
            rsLteCqi
            rsLteDbm
            rsLteRsrp
            rsLteRsrq
            rsLteRssi
            rsLteRssnr
            rsNrDbm
            rsNrSsRsrp
            rsNrSsRsrq
            rsNrSsSinr
            snapshotTime
          }
          latest {
            cdmaBasestationId
            cdmaBasestationLatitude
            cdmaBasestationLongitude
            cdmaBasestationNid
            cdmaBasestationSid
            gsmArfcn
            gsmBsic
            gsmCid
            gsmLac
            lteBands
            lteBandwidth
            lteCi
            lteEarfcn
            ltePci
            lteTac
            mobileSignalStrength
            mobileNetworkOperatorName
            networkType
            nrArfcn
            nrNci
            nrPci
            nrTac
            rsGsmBer
            rsGsmCsq
            rsGsmDbm
            rsGsmRssi
            rsLteCqi
            rsLteDbm
            rsLteRsrp
            rsLteRsrq
            rsLteRssi
            rsLteRssnr
            rsNrDbm
            rsNrSsRsrp
            rsNrSsRsrq
            rsNrSsSinr
            rsEvdoEcio
            rsCdmaEcio
            rsCdmaRssi
            rsEvdoRssi
            rsEvdoSnr
          }
        }
        storage {
          latest {
            application
            audio
            document
            freeExternal
            freeInternal
            other
            picture
            snapshotTime
            totalExternal
            totalInternal
            video
          }
        }
        system {
          history {
            endTime
            mobileData
            startTime
            totalData
            wifiData
          }
          latest {
            fiveLoadAvg
            numCpus
            uptime
          }
        }
      }
      e164LineNumber
      events {
        code
        data
        time
      }
      lastCheckIn
      lineNumber
      os
      osVersion
    }
  }
`

export default SEARCH_BY_CLIENT_ID
