import React from 'react'
import type { ReactElement } from 'react'
import styled from 'styled-components'
import { type StyledComponentInterface } from '../../types/styled-components'

const StyledManageUserHeader = styled.div<StyledComponentInterface>`
  .user-heading-text {
    height: 35px;
    font-family: var(--font-family-primary);
    font-style: normal;
    font-weight: ${(props: any) => props.styles.fontWeight};
    font-size: 20px;
    color: var(--black);
    margin-left: 60px;
    margin-right: 30px;
    margin-top: 20px;
  }
  @media screen and (min-device-width: 768px) and (max-device-width: 1020px) {
    .user-heading-text {
      margin-left: 15px;
      margin-right: 15px;
    }
  }
`
interface UserHeadingTextInterface {
  text: string
  fontWeight: number | string
}

const UserHeadingText = ({
  text,
  fontWeight,
}: UserHeadingTextInterface): ReactElement => {
  return (
    <StyledManageUserHeader
      styles={{
        fontWeight,
      }}
    >
      <div className="user-heading-text">{text}</div>
    </StyledManageUserHeader>
  )
}

export default UserHeadingText
