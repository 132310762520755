import { useEffect, type ReactElement } from 'react'
import MainLayout from '../../layouts/main/main.layout'
import ManageAccountContent from './manage-account-content'
import { Mixpanel, isMixpanelAllowed } from '../../core/analytics'

const ManageAccount = (): ReactElement => {
  useEffect(() => {
    // Mixpanel
    if (isMixpanelAllowed()) {
      try {
        Mixpanel.track('User active in Manage-Account page')
      } catch (e) {
        Mixpanel.track('Not able to track User in Manage-Account page')
      }
    }
  }, [])

  return (
    <MainLayout>
      <ManageAccountContent />
    </MainLayout>
  )
}

export default ManageAccount
