import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ArrowBack, Replay } from '@mui/icons-material'
import { useHistory } from 'react-router-dom'
import { EmptyLayout } from '../../layouts'
import { isEmpty } from '../../utils/common-methods'
import oopsImage from '../../assets/img/page-not-found.svg'
import { namespaces } from '../../core/i18n/i18n.constants'

const StyledErrorPage = styled.div`
  font-family: var(--font-family-primary);

  .error-page__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 90vh;
  }

  .error-page__wrapper__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .error-page__wrapper__body__heading {
    color: var(--text-dark-grey);
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    margin: 30px 0 20px 0;
  }
  .error-page__wrapper__body__subheading {
    margin-top: 10px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
  }
  .error-page__wrapper__reload__btn {
    margin-top: 30px;
    display: flex;
    background-color: var(--light-blue-300);
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 40px;
    border-radius: 20px;
    cursor: pointer;
  }
  .error-page__wrapper__reload__btn__icon {
    color: var(--white);
    font-size: 20px;
  }
  .error-page__wrapper__reload__btn__text {
    margin-left: 5px;
    color: var(--white);
    font-style: normal;
    font-weight: bolder;
    font-size: 14px;
    line-height: 28px;
  }
`

interface ErrorPageInterface {
  isSessionExpired?: boolean | undefined
  title?: string | undefined
  message?: string | undefined
}

const ErrorPage = (props: ErrorPageInterface): ReactElement => {
  const { t } = useTranslation([
    namespaces.common.text,
    namespaces.common.error,
    namespaces.common.button,
  ])

  const history = useHistory()
  const { isSessionExpired = false, title = '', message = '' } = props

  let heading: string = t('error:errorMessageTitleStandard')
  let description: string = t('error:errorMessageDescriptionStandard')

  if (!isEmpty(title)) {
    heading = t(`${title}`)
  }

  if (!isEmpty(message)) {
    description = t(`${message}`)
  }

  if (isEmpty(title) && isSessionExpired) {
    heading = t('error:sessionExpiredTitle')
    description = t('error:sessionExpiredMessage')
  }

  if (isEmpty(title) && !isSessionExpired) {
    heading = t('error:errorMessageTitleStandard')
    description = t('error:errorMessageDescriptionStandard')
  }

  return (
    <StyledErrorPage className="error-page">
      <EmptyLayout>
        <div className="error-page__wrapper">
          <div className="error-page__wrapper__body">
            <img src={oopsImage} width={'160px'} height={'160px'} />

            <h1 className="error-page__wrapper__body__heading">{heading}</h1>

            <h3 className="error-page__wrapper__body__subheading">
              {description}
            </h3>
          </div>

          {isSessionExpired ? (
            <div className="error-page__wrapper__reload">
              <a
                className="error-page__wrapper__reload__btn"
                onClick={() => {
                  history.push('/')
                }}
              >
                <ArrowBack className="error-page__wrapper__reload__btn__icon" />
                <p className="error-page__wrapper__reload__btn__text">
                  {t('login')}
                </p>
              </a>
            </div>
          ) : (
            <div className="error-page__wrapper__reload">
              <a
                className="error-page__wrapper__reload__btn"
                onClick={() => {
                  history.go(0)
                }}
              >
                <Replay className="error-page__wrapper__reload__btn__icon" />
                <p className="error-page__wrapper__reload__btn__text">
                  {t('retry')}
                </p>
              </a>
            </div>
          )}
        </div>
      </EmptyLayout>
    </StyledErrorPage>
  )
}

export default ErrorPage
