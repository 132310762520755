import { gql } from '@apollo/client'

const SEARCH_DEVICES = gql`
  query getClientsByLineNumber($input: ClientsByLineNumberInput!) {
    clientsByLineNumber(input: $input) {
      id
      application
      applicationVersion
      buildModel
      e164LineNumber
      lastCheckIn
      lastSnapshotTime
      lineNumber
      os
      osVersion
    }
  }
`

export default SEARCH_DEVICES
