import { MenuItem } from '@mui/material'
import { namespaces } from '../../../core/i18n/i18n.constants'
import { useTranslation } from 'react-i18next'

interface UserRoleMenuItemInterface {
  userRoleValue: string
  userRoleLabel: string
}
const UserRoleMenuItem = ({
  userRoleValue,
  userRoleLabel,
}: UserRoleMenuItemInterface): JSX.Element => {
  const { t } = useTranslation(namespaces.pages.manageUser)

  return (
    <MenuItem
      key={userRoleValue}
      value={userRoleValue}
      sx={{
        color: 'var(--text-dark-grey-100)',
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '28px',
      }}
    >
      {t(userRoleLabel)}
    </MenuItem>
  )
}

export default UserRoleMenuItem
