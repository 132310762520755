import React, {
  type ReactElement,
  type Key,
  type ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import {
  useTable,
  usePagination,
  useSortBy,
  useGlobalFilter,
} from 'react-table'
import Pagination from '@mui/material/Pagination'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { UserContext } from '.'
import ManageUserHeader from './manage-user-header'
import ScopingOptions from './scoping-options'
import UserHeadingText from './user-heading-text'
import Loader from '../../components/ui/loader'
import PageCountButton from './page-count-button'
import { namespaces } from '../../core/i18n/i18n.constants'
import { ReactComponent as SortIcon } from '../../assets/img/sort.svg'
import { ReactComponent as UpSortIcon } from '../../assets/img/up-sort.svg'
import { isEmpty, scrollToTop, sleep } from '../../utils/common-methods'

const StyledManageUserTable = styled.div`
  table {
    width: 100%;
    border-spacing: 0px;
  }
  table th {
    height: 45px;
    width: 15%;
  }
  table td {
    vertical-align: middle;
    padding: 0.5rem;
    text-align: left;
    padding: 15px;
    border-left: none;
    border-bottom: 1px solid var(--light-grey-300);
  }
  table tr {
    border-bottom: 1px solid var(--light-grey-300);
    flex-wrap: wrap;
    border-left: none !important;
  }
  table th,
  tfoot td {
    border: 1px solid lightgrey;
  }
  .manage-user-table {
    margin-left: 60px;
    margin-right: 60px;
    margin-top: 8px;
    margin-bottom: 30px;
    overflow: auto;
    min-height: 50vh;
  }
  .manage-user-table__wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    height: 100%;
    cursor: pointer;
  }
  .manage-user-table__wrapper-text,
  .manage-user-table__wrapper-action {
    flex: 8;
    height: 100%;
    padding-left: 10px;
    display: flex;
    justify-content: start;
    align-items: center;
    border-right: 1px solid var(--light-grey-300);
    background-color: var(--light-blue-200);
    font-family: var(--font-family-primary);
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: var(--text-grey-black);
  }
  .manage-user-table__wrapper-action {
    cursor: default;
  }
  .manage-user-table__wrapper-text_action {
    width: 8%;
  }
  .manage-user-table__wrapper-column {
    width: 15%;
  }
  .manage-user-table__wrapper-sort {
    height: 100%;
    width: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 800px) {
    .manage-user-table__wrapper-sort {
      height: 100%;
      width: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .table {
      overflow: hidden;
    }
  }
  .manage-user-table__row-text {
    font-family: var(--font-family-primary);
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: var(--light-grey-600);
    max-width: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .manage-user-table__row-text-email {
    width: 80%;
    max-width: 200px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media screen and (min-width: 2250px) {
    .manage-user-table__row-text-email {
      width: 100%;
      max-width: 500px;
      -webkit-line-clamp: 1;
    }
  }
  .manage-user-table__row-text_edit {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .manage-user-table__wrapper-page {
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
  .manage-user-table__wrapper-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 16px;
  }
  .manage-user-table__wrapper-pagination-view-text {
    font-family: var(--font-family-primary);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: var(--light-grey-600);
    text-align: center;
    line-height: 28px;
  }
  .manage-user-table__page-number-button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .manage-user-table__result-count {
    height: 28px;
    font-family: var(--font-family-primary);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    margin-top: 6px;
    color: var(--light-grey-600);
    margin-right: 15px;
  }
  @media screen and (min-device-width: 768px) and (max-device-width: 1020px) {
    .manage-user-table {
      margin-left: 15px;
      margin-right: 15px;
    }
  }
`

const StyledNoRecordsFound = styled.td`
  text-align: center !important;
  font-family: var(--font-family-primary);
  color: var(--black);
`

const StyledTBody = styled.tbody`
  vertical-align: top !important;
`

const CLEAR_FILTERS = 'clearFilters'

interface ManageUsersTableColumnListInterface {
  Header: string
  accessor: string
  disableGlobalFilter?: boolean
}
interface ManageUserTableInterface {
  columnsList: ManageUsersTableColumnListInterface[]
  userDataList: any
  showLoader: boolean
  clearAllFilters: boolean
  pageNum: number
  selected: number
  setShowLoader: React.Dispatch<React.SetStateAction<boolean>>
  setSelected: React.Dispatch<React.SetStateAction<number>>
}

const ManageUserTable = ({
  clearAllFilters,
  columnsList,
  pageNum,
  selected,
  setSelected,
  showLoader,
  userDataList,
}: ManageUserTableInterface): ReactElement => {
  const { setSelectedPageNum } = useContext(UserContext)
  const [filterDataList, setFilterDataList] = useState(userDataList)
  const { t } = useTranslation(namespaces.pages.manageUser)
  const columns = useMemo(() => columnsList, [columnsList])
  const data = useMemo(() => filterDataList, [filterDataList])
  const [checkboxClickStatus, setCheckboxClickStatus] = useState(true)
  const [count, setCount] = useState<number>(0)
  const [selectedView, setSelectedView] = useState(25)
  const [dataLength, setDataLength] = useState(data?.length)
  const [filterValue, setFilterValue] = useState('')
  const [roleArray, setRoleArray] = useState<string[]>([])
  const [stateArray, setStateArray] = useState<string[]>([])
  const [pageSize, setPageSizeCount] = useState(25)
  const actionHeader = 'Action'
  const emailHeader = 'email'
  const perPageArray: number[] = []

  useEffect(() => {
    setFilterDataList(userDataList)
  }, [userDataList])

  const tableInstance = useTable(
    {
      columns,
      sortTypes: {
        alphanumeric: (row1, row2, columnName) => {
          const a = row1.values[columnName].toLowerCase()
          const b = row2.values[columnName].toLowerCase()
          if (a > b) {
            return 1
          }
          if (b > a) {
            return -1
          }
          return 0
        },
      },
      data,
      disableSortRemove: true,
      initialState: {
        pageSize,
        sortBy: [
          {
            id: 'email',
            desc: false,
          },
        ],
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  )
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page,
    gotoPage,
    rows,
    setPageSize,
    setGlobalFilter,
  } = tableInstance
  const { globalFilter } = state

  useEffect(() => {
    clearFilters()
    setFilterValue('')
    handleData(t(CLEAR_FILTERS))
  }, [clearAllFilters])

  useEffect(() => {
    setFilterValue('')
  }, [])

  useEffect(() => {
    setDataLength(rows?.length)
  }, [rows])

  useEffect(() => {
    if (!isEmpty(globalFilter)) {
      setFilterValue(globalFilter)
    }
  }, [globalFilter])

  const clickHandler = (selectedPage: number): void => {
    if (selectedPage < selected) {
      gotoPage(selectedPage < 1 ? 0 : selectedPage - 1)
      setSelected(selectedPage)
    } else {
      const goToPageNum = selectedPage === 1 ? 1 : selectedPage - 1
      gotoPage(goToPageNum)
      setSelected(selectedPage)
    }
    void sleep(200).then(() => {
      scrollToTop()
    })
  }

  const adjustNumber = (num: number): number => {
    if (num < 1) {
      return 1
    } else if (num === 1) {
      return 1
    } else {
      const integerPart = Math.floor(num)
      const adjustedInteger = integerPart + 1
      const adjustedNumber = adjustedInteger

      return adjustedNumber
    }
  }

  const clearFilters = (): void => {
    setSelected(1)
    setRoleArray([])
    setStateArray([])
    setFilterDataList(userDataList)
    setCount(count + 1)
  }

  const handleData = (buttonName: string): void => {
    gotoPage(0)

    if (buttonName === t('applyFilters')) {
      setSelected(1)
      setCheckboxClickStatus(true)
      filterData()
    } else if (buttonName === t(CLEAR_FILTERS)) {
      setFilterValue('')
      setGlobalFilter('')
      setCheckboxClickStatus(false)
      clearFilters()
    }
  }

  const handleSendCheckBoxData = (data: string, isSelected: boolean): void => {
    setCheckboxClickStatus(true)
    if (
      data === 'Active' ||
      data === 'Pending' ||
      data === 'Password Reset' ||
      data === 'Locked Out'
    ) {
      isSelected
        ? setStateArray([...stateArray, data])
        : stateArray.splice(stateArray.indexOf(data), 1)
    } else {
      isSelected
        ? setRoleArray([...roleArray, data])
        : roleArray.splice(roleArray.indexOf(data), 1)
    }
  }

  const filterData = (): any => {
    let dataToFilter = [...userDataList]

    if (!isEmpty(filterValue)) {
      dataToFilter = userDataList?.filter((rowItem: any) => {
        const searchRegex = new RegExp(filterValue, 'ig')

        const checkFilter =
          // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
          rowItem?.first_name?.match(searchRegex) ||
          // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
          rowItem?.last_name?.match(searchRegex) ||
          // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
          rowItem?.role?.match(searchRegex) ||
          // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
          rowItem?.email?.match(searchRegex) ||
          // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
          rowItem?.state?.match(searchRegex)

        return checkFilter
      })
    }

    const roleFilteredData = dataToFilter.filter(
      (item: any) =>
        item.role !== null &&
        (item.role?.replace('_', ' ')?.toLowerCase() ===
          roleArray[0]?.replace('_', ' ')?.toLowerCase() ||
          item.role?.replace('_', ' ')?.toLowerCase() ===
            roleArray[1]?.replace('_', ' ')?.toLowerCase())
    )

    const stateFilteredData = dataToFilter.filter(
      (item: any) =>
        item.state?.toLowerCase() === stateArray[0]?.toLowerCase() ||
        item.state?.toLowerCase() === stateArray[1]?.toLowerCase() ||
        item.state?.toLowerCase() === stateArray[2]?.toLowerCase() ||
        item.state?.toLowerCase() === stateArray[3]?.toLowerCase()
    )

    const scopingOptionsDataFiltering = (): any => {
      if (!isEmpty(stateFilteredData) && !isEmpty(roleFilteredData)) {
        return roleFilteredData.filter((value: any) =>
          stateFilteredData.includes(value)
        )
      } else if (!isEmpty(roleFilteredData) && isEmpty(stateArray)) {
        return roleFilteredData
      } else if (!isEmpty(stateFilteredData) && isEmpty(roleArray)) {
        return stateFilteredData
      } else {
        return []
      }
    }

    const filteredData = scopingOptionsDataFiltering

    !isEmpty(roleArray) || !isEmpty(stateArray)
      ? setFilterDataList(filteredData)
      : setFilterDataList(userDataList)
  }

  const renderCell = (cell: any): ReactNode => {
    const inputString = cell?.value
    let modifiedString = ''
    if (!isEmpty(inputString) && cell?.column?.id !== actionHeader) {
      modifiedString = inputString
    }

    return (
      <div>
        <div className="manage-user-table__row-text-email">
          {modifiedString}
        </div>
      </div>
    )
  }

  const renderPageSize = (): ReactNode => {
    let numbersList
    const pageSize = 25
    if (dataLength >= 25) {
      numbersList = Math.ceil(dataLength / pageSize)
      const countCheck = numbersList > 5 ? 5 : numbersList
      for (let i = 1; i < countCheck; i++) {
        perPageArray.push(i * 25)
      }

      perPageArray.push(dataLength)

      return perPageArray.map((item: any, index: Key) => (
        <PageCountButton
          onClick={(e: any) => {
            setSelectedView(item)
            const calculatedLastPageNum = Math.ceil(dataLength / item)

            if (pageNum > calculatedLastPageNum) {
              setSelectedPageNum(calculatedLastPageNum)
            }

            if (selectedView >= e?.target?.value || selected >= 2) {
              setSelected(1)
              void sleep(200).then(() => {
                scrollToTop()
              })
            }
            gotoPage(0)
            setPageSize(Number(e.target.value))
            setPageSizeCount(e.target.value)
            setSelectedPageNum(1)
          }}
          value={item}
          key={index?.toString()}
          isSelected={selectedView?.toString() === item?.toString()}
        >
          {item === dataLength ? t('all') : item}
        </PageCountButton>
      ))
    }
  }

  const handleCellTitle = (cell: any): string | undefined => {
    let cellTitle
    if (isEmpty(cell)) cellTitle = 'false'
    if (cell?.column?.id === emailHeader) {
      if (cell?.value?.length > 15) {
        cellTitle = cell?.value
      }
    } else if (window.innerWidth < 1205) {
      if (cell?.value?.length > 15) {
        cellTitle = cell?.value
      } else if (cell?.value?.length > 35) {
        cellTitle = cell?.value
      } else {
        cellTitle = 'false'
      }
    } else if (cell?.value?.length > 20) {
      cellTitle = cell?.value
    } else {
      cellTitle = 'false'
    }
    return cellTitle
  }

  return (
    <>
      <StyledManageUserTable>
        <ManageUserHeader
          text={t('manageUsers')}
          filter={filterValue}
          setFilter={setFilterValue}
          setGlobalFilter={setGlobalFilter}
        />
        <ScopingOptions
          sendData={handleData}
          sendCheckBoxData={handleSendCheckBoxData}
          checkboxClickStatus={checkboxClickStatus}
        />
        <UserHeadingText
          text={`${dataLength as string} ${t('result')}`}
          fontWeight={'600'}
        />
        <div className="manage-user-table">
          {showLoader ? (
            <Loader />
          ) : (
            <table {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroups: any, index: Key) => (
                  <tr
                    {...headerGroups.getHeaderGroupProps()}
                    key={index?.toString()}
                  >
                    {headerGroups.headers.map((column: any, index: Key) => (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps({
                            disablesortby: (
                              column.id ===
                              'columnNameToEnableCaseSensitiveSorting'
                            ).toString(),
                          })
                        )}
                        key={index?.toString()}
                        className={
                          column.render('Header') === actionHeader
                            ? 'manage-user-table__wrapper-text_action'
                            : column.render('Header').toLowerCase() ===
                              emailHeader
                            ? 'manage-user-table__wrapper-column email'
                            : 'manage-user-table__wrapper-column'
                        }
                        style={{
                          whiteSpace: 'nowrap',
                        }}
                      >
                        <div
                          className="manage-user-table__wrapper"
                          onClick={() => {
                            setSelected(1)
                          }}
                        >
                          <div
                            className={`manage-user-table__wrapper${
                              column.render('Header') !== actionHeader
                                ? '-text'
                                : '-action'
                            }`}
                          >
                            {t(column.render('Header'))}
                          </div>
                          {column.render('Header') === actionHeader ? null : (
                            <div className="manage-user-table__wrapper-sort">
                              {column.isSorted === true &&
                              column.isSortedDesc === true ? (
                                <UpSortIcon />
                              ) : (
                                <SortIcon />
                              )}
                            </div>
                          )}
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <StyledTBody {...getTableBodyProps()}>
                {(userDataList?.length <= 0 || page?.length <= 0) && (
                  <tr>
                    <StyledNoRecordsFound colSpan={7}>
                      {t('matchingRecords')}
                    </StyledNoRecordsFound>
                  </tr>
                )}
                {page?.map((row: any, index: Key) => {
                  prepareRow(row)
                  return (
                    <tr
                      {...row.getRowProps()}
                      key={index?.toString()}
                      style={{
                        wordWrap: 'normal',
                      }}
                    >
                      {row.cells.map((cell: any, index: Key) => {
                        return (
                          <td
                            {...cell.getCellProps()}
                            key={index?.toString()}
                            style={{
                              wordWrap: 'break-word',
                              borderCollapse: 'inherit',
                              borderSpacing: 0,
                            }}
                          >
                            <div
                              className="manage-user-table__row-text"
                              title={
                                handleCellTitle(cell) !== 'false'
                                  ? cell?.value
                                  : null
                              }
                            >
                              {cell?.column?.id === 'action'
                                ? cell?.render('Cell')
                                : renderCell(cell)}
                            </div>
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
              </StyledTBody>
            </table>
          )}
          {dataLength > 25 ? (
            <div className="manage-user-table__wrapper-page">
              <div className="manage-user-table__wrapper-pagination">
                <p className="manage-user-table__wrapper-pagination-view-text">
                  {t('view')}
                </p>
                {renderPageSize()}
              </div>
              <div className="manage-user-table__page-number-button">
                <span className="manage-user-table__result-count">
                  {t('showingResults')}{' '}
                  {selected === 1
                    ? selected
                    : Number(pageSize) * (selected - 1) + 1}{' '}
                  -{' '}
                  {dataLength < pageSize * selected
                    ? dataLength
                    : pageSize * selected}{' '}
                  {t('from')} {dataLength}
                </span>

                <Pagination
                  count={adjustNumber(dataLength / pageSize)}
                  page={pageNum}
                  variant="outlined"
                  shape="rounded"
                  size="large"
                  onChange={(
                    event: React.ChangeEvent<unknown>,
                    page: number
                  ): void => {
                    clickHandler(page)
                    setSelectedPageNum(page)
                    void sleep(200).then(() => {
                      scrollToTop()
                    })
                  }}
                />
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </StyledManageUserTable>
    </>
  )
}

export default ManageUserTable
