import {
  type ReactElement,
  useLayoutEffect,
  useState,
  useEffect,
  lazy,
  Suspense,
  useContext,
} from 'react'
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import AlertMessage from '../../components/ui/alert'
import MainLayout from '../../layouts/main/main.layout'
import { namespaces } from '../../core/i18n/i18n.constants'
import SearchIcon from '../../assets/img/search-icon.svg'
import Loader from '../../components/ui/loader'
import { api } from '../../services'
import { useAppDispatch, useAppSelector } from '../../store'
import { setSearchedDevices, setSelectedDevice } from './search-devices-slice'
import SEARCH_DEVICES from '../../services/queries/search-devices'
import { Mixpanel, isMixpanelAllowed } from '../../core/analytics'
import './search-devices.css'
import { useOktaAuth } from '@okta/okta-react'
import {
  DEVICE_SEARCH_CHAR_ALLOWED,
  DEVICE_SEARCH_CHAR_SPECIALCASE,
  FORMAT_DEVICE_SEARCH_PHONE_NUMBER,
  PHONE_NUMBER_VALIDATION,
} from '../../global'
import { checkAuthError, getErrorMessage } from '../../utils/common-methods'
import { RootContext } from '../dashboard'

// eslint-disable-next-line @typescript-eslint/promise-function-async
const ResultsTable = lazy(() => import('./results-table'))

const tableHeaders = [
  {
    id: 1,
    name: 'clientId',
    sortable: false,
  },
  {
    id: 2,
    name: 'model',
    sortable: false,
  },
  {
    id: 3,
    name: 'application',
    sortable: false,
  },
  {
    id: 4,
    name: 'lineNumber',
    sortable: false,
  },
  {
    id: 5,
    name: 'lastCheckIn',
    sortable: false,
  },
]

const SearchDevices = (): ReactElement => {
  const { authState } = useOktaAuth()
  const { path: PATH } = useRouteMatch()
  const location = useLocation()
  const { t } = useTranslation(namespaces.pages.searchDevices) // get specific translation for this page
  const { t: t1 } = useTranslation([namespaces.common.server])
  const history = useHistory()
  const dispatch = useAppDispatch()
  const { searchedDevices = [] } = useAppSelector(store => store?.searchDevices)
  const { user } = useAppSelector(store => store?.dashboard)

  const [searchValidationSuccess, setSearchValidationSuccess] = useState(false)
  const [searchPhone, setSearchPhone] = useState('')
  const [searchFormattedPhone, setSearchFormattedPhone] = useState('')
  const [searchData, setSearchData] = useState<[]>([])
  const [searching, setSearching] = useState<boolean>(false)
  const [searched, setSearched] = useState<boolean>(false)
  const [recentlyViewedDevices, setRecentlyViewedDevices] = useState<[]>([])
  const [domain, setDomain] = useState<string | null>(null)
  const [openAlert, setOpenAlert] = useState<boolean>(false)

  const { handleAuthError } = useContext(RootContext)

  useLayoutEffect(() => {
    const domainURL = window?.origin
    setDomain(domainURL)
    const listUserViewedDevices =
      searchedDevices?.find(
        item => item?.sub === user?.id && item?.domain === domainURL
      )?.searchedDevicesList ?? []
    setRecentlyViewedDevices(listUserViewedDevices)
  }, [user, searchedDevices])

  const addDevicesToStore = (
    data: any,
    position: number,
    device: any
  ): void => {
    const payload = {
      domain,
      sub: user?.id ?? user?.sub,
      searchedDevicesList: data,
    }

    const viewedDevicesList: any = [...searchedDevices]?.filter(
      (item: any) => item !== null || item !== undefined
    )

    viewedDevicesList[position] = payload
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    dispatch(
      setSearchedDevices(
        viewedDevicesList?.filter(
          (item: any) => item !== null || item !== undefined
        )
      )
    )

    const path = `${PATH}/${device.id as string}/overview/status`
    history.push({
      pathname: path,
      state: {
        id: device.id,
      },
    })
  }

  const selectDevice = (device: any): void => {
    // adding timestamp to manage the latest recently viewed device
    const updatedDevice = { ...device, timestamp: Date.now() }
    const viewedDevicesList: any = [...recentlyViewedDevices]
    if (viewedDevicesList?.length >= 10) {
      viewedDevicesList.shift()
    }
    dispatch(setSelectedDevice(updatedDevice))

    viewedDevicesList.push(updatedDevice)

    const updatedViewedDevices: any = [...recentlyViewedDevices]?.filter(
      (item: any) => item !== null || item !== undefined
    )

    viewedDevicesList?.forEach((viewedDevice: any) => {
      const existingItemIndex = updatedViewedDevices.findIndex(
        (item: any) => item?.id === viewedDevice?.id
      ) // end-findIndex
      if (existingItemIndex < 0) {
        updatedViewedDevices.push(updatedDevice)
      }
    }) // end-forEach

    const viewedDeviceIndex = updatedViewedDevices.findIndex(
      (viewedDevice: any) => viewedDevice?.id === updatedDevice?.id
    )

    // updating the device with the latest timestamp to manage the recently viewed devices
    updatedViewedDevices[viewedDeviceIndex] = updatedDevice

    if (searchedDevices.length > 0) {
      const index = searchedDevices?.findIndex(
        item => item?.sub === user?.id && item?.domain === domain
      )

      if (index > -1) {
        const viewedDevicesList: any = [...searchedDevices]
        const updatedViewedDevicesList = viewedDevicesList?.map(
          (viewedDevice: any, viewedDeviceIndex: number) => {
            if (viewedDeviceIndex === index) {
              viewedDevice = {
                domain,
                sub: viewedDevice?.sub,
                searchedDevicesList: updatedViewedDevices?.filter(
                  (item: any, index: number) => {
                    const stringObj = JSON.stringify(item)
                    return (
                      index ===
                      updatedViewedDevices?.findIndex(
                        (deviceItem: any) =>
                          JSON.stringify(deviceItem) === stringObj
                      )
                    )
                  }
                ),
              }
            }

            return viewedDevice
          }
        )

        dispatch(
          setSearchedDevices(
            updatedViewedDevicesList?.filter(
              (item: any) => item !== null || item !== undefined
            )
          )
        )

        const deviceId: string = device?.id

        const path = `${PATH}/${deviceId}/overview/status`

        // Mixpanel
        if (isMixpanelAllowed()) {
          Mixpanel.track('User selected device to view details', {
            device_id: deviceId,
          })
        }

        history.push({
          pathname: path,
          state: {
            id: device.id,
          },
        })
      } else {
        addDevicesToStore(
          updatedViewedDevices,
          searchedDevices?.length + 1,
          device
        )
      }
    } else {
      addDevicesToStore(updatedViewedDevices, 0, device)
    }
  }

  const handleSearchChange = (event: any): void => {
    const searchValue = event?.target?.value

    if (searchValue.length < 1) {
      setSearched(false)
      setSearchPhone('')
      setSearchData([])
      setSearchValidationSuccess(false)

      return
    }

    if (searchValue.length < 10) {
      setSearchValidationSuccess(false)
    }

    if (
      searchValue.match(DEVICE_SEARCH_CHAR_ALLOWED) !== null &&
      searchValue.match(DEVICE_SEARCH_CHAR_SPECIALCASE) === null
    ) {
      setSearchPhone(searchValue)
      if (
        searchValue.match(PHONE_NUMBER_VALIDATION) !== null &&
        searchValue.length >= 10
      ) {
        setSearchFormattedPhone(
          searchValue.replace(FORMAT_DEVICE_SEARCH_PHONE_NUMBER, '')
        )
        setSearchValidationSuccess(true)
      } else {
        setSearchFormattedPhone('')
        setSearchValidationSuccess(false)
      }
    }
  }

  const handleEnter = (event: any): void => {
    if (event.key === 'Enter') {
      handleSearch()
    }
  }

  const handleSearch = (): void => {
    if (!searchValidationSuccess) {
      return
    }

    setSearching(true)

    api
      .query({
        query: SEARCH_DEVICES,
        context: {
          headers: {
            authorization: authState?.accessToken?.accessToken,
          },
        },
        variables: {
          input: {
            lineNumber: searchFormattedPhone,
          },
        },
        fetchPolicy: 'network-only',
      })
      .then((response: any) => {
        setSearchData(response?.data?.clientsByLineNumber)
        setSearching(false)
        setSearched(true)
        if (isMixpanelAllowed()) {
          Mixpanel.track('Device search tapped', {
            'Total items': response?.data?.clientsByLineNumber.length,
          })
        }
      })
      .catch((error: any) => {
        setOpenAlert(true)

        console.error('ERROR Fetching Serch device data: ', error)
        if (isMixpanelAllowed()) {
          Mixpanel.track('Agent dashboard request failed', {
            Request: 'Device search',
            'Failure reason': getErrorMessage(error),
          })
        }

        if (checkAuthError(error)) {
          handleAuthError()
        } else {
          setSearching(false)
        }
      })
  }

  useEffect(() => {
    // Mixpanel
    if (isMixpanelAllowed()) {
      try {
        Mixpanel.track('User active in Search Devices page')
      } catch (e) {
        Mixpanel.track('Not able to track User in Search Devices page')
      }
    }

    setSearched(false)
    setSearchData([])
    setSearchPhone('')
    setSearchFormattedPhone('')
    setSearchValidationSuccess(false)
  }, [location.key])

  return (
    <MainLayout>
      <div className="parent-wrapper">
        <div className="header-wrapper">
          <div className="title">{t('title')}</div>

          <div className="search-wrapper">
            <div className="icon-placeholder">
              <input
                type="text"
                placeholder={t('searchPlaceholder') ?? ''}
                className={`search-input-field ${searching ? 'disabled' : ''}`}
                onChange={handleSearchChange}
                disabled={searching}
                value={searchPhone}
                maxLength={17}
                onKeyDown={handleEnter}
              />
              <div className="icon">
                <img src={SearchIcon} />
              </div>
            </div>
            <button
              onClick={handleSearch}
              className={
                searching || !searchValidationSuccess ? 'disabled-button' : ''
              }
              disabled={searching || !searchValidationSuccess}
            >
              {t('search')}
            </button>
          </div>
        </div>

        <div className="content-wrapper">
          <AlertMessage
            open={openAlert}
            autoClose={true}
            severity={'warning'}
            message={t1('genericAPIErrorMessage')}
            messageFormatting={true}
            onClose={(): void => {
              setSearched(false)
              setSearchData([])
              setOpenAlert(false)
            }}
          />
          {searching && <Loader />}
          {searchData != null && searched && (
            <>
              <div className="page-title">{t('searchResults')}</div>

              <Suspense fallback={<Loader />}>
                <ResultsTable
                  tableData={searchData}
                  tableHeaders={tableHeaders}
                  selectDevice={selectDevice}
                />
              </Suspense>
            </>
          )}
          {searchData?.length <= 0 && !searched && (
            <>
              <div className="page-title">{t('recentlyViewed')}</div>

              <Suspense fallback={<Loader />}>
                <ResultsTable
                  selectDevice={selectDevice}
                  tableData={recentlyViewedDevices}
                  tableHeaders={tableHeaders}
                  recentlyViewed={true}
                />
              </Suspense>
            </>
          )}
        </div>
      </div>
    </MainLayout>
  )
}

export default SearchDevices
