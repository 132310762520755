export const initReducer = (oldProps: any, newProps: any): any => ({
  ...oldProps,
  ...newProps,
})

export const capitalizeEachFirstLetter = (str: string): string => {
  const arr = str.split(' ')

  for (let i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1)
  }

  return arr.join(' ')
}

/**
 * This method test if a `target` variable is empty.
 * @param target could be a undefined, empty string, null, empty array, empty object
 * @returns true | false
 */
export const isEmpty = (
  target: undefined | string | object | null | number
): boolean => {
  if (target === undefined) {
    return true
  }

  if (typeof target === 'string' && target.trim() === '') {
    return true
  }

  if (typeof target === 'object') {
    if (target === null) {
      return true
    }

    // instance types
    if (target?.constructor?.name !== 'Object' && !(target instanceof Array)) {
      return false
    }

    if (Array.isArray(target) && target.length === 0) {
      return true
    }

    if (Object.keys(target).length === 0) {
      return true
    }
  }

  return false
}

export const removeKebabCase = (str: string): string => {
  if (isEmpty(str)) return str

  const arr = str.split('_')

  for (let i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1)
  }

  return arr.join(' ')
}

/* Handle cookies */
export const setCookie = (
  name: string,
  val: string,
  daysToExpiry: number = 7
): void => {
  const date = new Date()
  const value = val

  // Set it expire in 7 days
  date.setTime(date.getTime() + daysToExpiry * 24 * 60 * 60 * 1000)

  // Set it
  document.cookie =
    name + '=' + value + '; expires=' + date.toUTCString() + '; path=/'
}
export const getCookie = (name: string): any => {
  let cookie = ''

  const value = '; ' + document.cookie
  const parts = value.split('; ' + name + '=')
  if (parts !== undefined && parts?.length === 2) {
    cookie = parts?.pop()?.split(';')?.shift() ?? ''
  }

  return cookie
}
export const deleteCookie = (name: string): void => {
  const date = new Date()

  // Set it expire in -1 days
  date.setTime(date.getTime() + -1 * 24 * 60 * 60 * 1000)

  // Set it
  document.cookie = name + '=; expires=' + date.toUTCString() + '; path=/'
}
export const hasCookie = (name: string): boolean => {
  if (isEmpty(name)) return false
  const documentCookie: string = document?.cookie?.trim()?.toLocaleLowerCase()
  const target: string = name?.trim()?.toLocaleLowerCase()
  return documentCookie.includes(target)
}
export const getCookieName = (name: string): string => {
  if (isEmpty(name)) return ''

  const cookieNameArray: string[] = document.cookie.split(';')
  for (let i = 0; i < cookieNameArray.length; i++) {
    const cookieKeyValuePair = cookieNameArray[i].split('=')

    const cookieKey = cookieKeyValuePair[0].trim()?.toLocaleLowerCase()
    const target = name.trim().toLocaleLowerCase()

    if (cookieKey.includes(target)) {
      return cookieKey
    }
  }

  return ''
}
export const isCookieValid = (name: string): boolean => {
  if (isEmpty(name)) return false

  // search for cookieName
  const cookieName = getCookieName(name) // get cookie Key
  const cookieValue = getCookie(cookieName) // get cookie value

  return !isEmpty(cookieName) && !isEmpty(cookieValue)
}

export const scrollToTop = (): any => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  })
}

export const sleep = (ms: number | undefined): any =>
  new Promise(resolve => setTimeout(resolve, ms))

/* Check if logged user a Manager */
export const isCSRM = (user: any): boolean => {
  return user?.role?.toLocaleLowerCase()?.includes('manager') ?? false
}

export const isPtp = (user: any): boolean => {
  return user?.group?.toLocaleLowerCase()?.includes('ptp') ?? false
}

export const isValidEmailInput = (value: string): boolean => {
  return /^[A-Z0-9._%+-]+@[A-Z0-9-]+\.[A-Z]{2,4}$/i.test(value)
}
export const isValidNameInput = (value: string): boolean => {
  return /^[a-zA-ZáéíóúâêôãõçÁÉÍÓÚÂÊÔÃÕÇüñÜÑ ]+$/i.test(value)
}

export const isIphone = (device: any): boolean => {
  return device?.buildModel?.toLocaleLowerCase().includes('iphone') ?? false
}

export const removeSubstrings = (
  fullString: string,
  substring: string
): string => {
  if (isEmpty(fullString) || isEmpty(substring)) {
    return ''
  }

  const res = fullString.split(substring).join('')
  return res
}

/**
 *
 * @param target : e.g. ADB__john.doe@mail.com
 * @param prefix : to be removed e.g 'ADB__'
 * @returns : john.doe@mail.com
 */
export const removePrefix = (target: string, prefix: string): string => {
  if (isEmpty(target) || isEmpty(prefix)) return ''
  return removeSubstrings(target, prefix)
}

/* Function to get the window origin url */
export const getDomainUrl = (): string => {
  return window?.origin
}

export const getErrorCode = (error: any): string => {
  const code = error?.graphQLErrors?.[0]?.extensions?.code ?? ''
  return code.toLocaleLowerCase()
}

export const getErrorMessage = (error: any): string => {
  const message = error?.graphQLErrors?.[0]?.message ?? 'error'
  return message
}

export const checkAuthError = (error: any): boolean => {
  const code = getErrorCode(error)?.toLocaleLowerCase() ?? ''

  return code === 'unauthorized'
}

export const formatBytes = (bytes: number, decimals: number = 2): string => {
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  if (!+bytes) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))
  const calculatedBytes = bytes / Math.pow(k, i)

  return `${parseFloat(calculatedBytes?.toFixed(dm))} ${sizes[i]}`
}
