import { createSlice } from '@reduxjs/toolkit'

interface LoginState {
  token: string
}

// Define the initial state using that type
const initialState: LoginState = {
  token: 'Existing Token',
}

export const loginSlice = createSlice({
  name: 'login',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    storeToken: (state, action) => {
      state.token = action.payload
    },
  },
})
export default loginSlice.reducer

export const { storeToken } = loginSlice.actions
