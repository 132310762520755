import { configureStore } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import appReducer from '../pages/app/app-slice'
import dashboardReducer from '../pages/dashboard/dashboard-slice'
import loginReducer from '../pages/login/login-slice'
import searchDevicesReducer from '../pages/search-devices/search-devices-slice'
import {
  type TypedUseSelectorHook,
  useDispatch,
  useSelector,
} from 'react-redux'

const appStateName = 'pocketGeekAppState'

const persistConfig = {
  key: 'pocketGeekAppStateRoot',
  storage,
}

interface GetState {
  getState: any
}

/**
 * localStorageMiddleware
 * This method allows us to store the redux data in the local storage of the browser
 * It can be further used by reHydrateStore method to fetch and preload the redux state
 */
const localStorageMiddleware = (reduxMethods: GetState): any | null => {
  return (next: any) => (action: any) => {
    const result = next(action)
    localStorage?.setItem(
      appStateName,
      JSON.stringify(reduxMethods?.getState())
    )
    return result
  }
}

/**
 * reHydrateStore
 * This method pre-loads the redux state with the data fetched from local storage of browser
 * This initialises the redux state once and not always
 */
const reHydrateStore = (): any | null => {
  if (localStorage?.getItem(appStateName) !== null) {
    const fetchedData = JSON.parse(localStorage?.getItem(appStateName) ?? '{}')
    return fetchedData
  }
}

const reducer = {
  // Add reducers here
  app: appReducer,
  dashboard: dashboardReducer,
  login: loginReducer,
  searchDevices: persistReducer(persistConfig, searchDevicesReducer),
}

const store = configureStore({
  reducer,
  devTools: true,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error:configuration to enable local storage population initially
  preloadState: reHydrateStore(),
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(localStorageMiddleware),
})

export const useAppDispatch: () => typeof store.dispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<
  ReturnType<typeof store.getState>
> = useSelector

export default store

export const persistor = persistStore(store)
