import React, { type ReactElement } from 'react'
import { TextField } from '@mui/material'
import UserRoleMenuItem from './text-field-menu-items'
import styled from 'styled-components'
import { ReactComponent as ModalDropDown } from '../../../assets/img/modal-drop-down.svg'

interface UserRolesInterface {
  userRoleValue: string
  userRoleLabel: string
}
interface HandleTextFieldChangeInterface {
  name: string
  value: string
}
interface FormValuesInterface {
  firstName: string
  lastName: string
  email: string
  userRole: string
}
interface UserRoleFormFieldInterface {
  formValues: FormValuesInterface
  roles: UserRolesInterface[]
  handleTextFieldChange: (e: { target: HandleTextFieldChangeInterface }) => void
  disabled: boolean
  placeholder: string
}

const StyledUserRoleFormField = styled.div`
  .user-role-form-field__select-text-field {
    width: 420px;
    height: 44px;
    background-color: var(--light-grey-100);
    border: 1px solid var(--cyan);
    border-radius: 6px;
  }
  .user-role-form-field__select-text-field.disabled {
    background-color: var(--light-grey-50);
    cursor: not-allowed;
  }
  .user-role-form-field__select-text-field__style {
    font-family: var(--font-family-primary);
    color: var(--light-grey);
    padding-left: 12px;
    padding-top: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
  }
  .user-role-form-field__drop-down {
    width: 16px;
    height: 16px;
    margin-right: 14px;
  }
`

const DropdownIndicator = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): ReactElement => (
  <ModalDropDown
    {...props}
    className={`${props.className ?? 'zero'} user-role-form-field__drop-down`}
  />
)

const UserRoleFormField = ({
  formValues,
  roles,
  handleTextFieldChange,
  disabled,
  placeholder,
}: UserRoleFormFieldInterface): ReactElement => {
  return (
    <StyledUserRoleFormField>
      <TextField
        variant="standard"
        select
        size="small"
        className={`user-role-form-field__select-text-field ${
          disabled ? 'disabled' : ''
        }`}
        name="userRole"
        value={formValues.userRole}
        onChange={handleTextFieldChange}
        SelectProps={{
          IconComponent: DropdownIndicator,
          displayEmpty: true,
          renderValue:
            formValues.userRole.length === 0
              ? () => `${placeholder}`
              : () => formValues.userRole,
        }}
        InputProps={{
          classes: {
            input: 'user-role-form-field__select-text-field__style',
          },
          disableUnderline: true,
        }}
        disabled={disabled}
      >
        {Array.isArray(roles) &&
          roles.length > 0 &&
          roles.map(option => UserRoleMenuItem(option))}
      </TextField>
    </StyledUserRoleFormField>
  )
}

export default UserRoleFormField
