import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { namespaces } from '../../core/i18n/i18n.constants'
import { ReactComponent as AssurantLogo } from '../../assets/img/assurant-logo.svg'
import { ReactComponent as TrustArcLogo } from '../../assets/img/trustarc-logo.svg'
import { handleUpdateLanguage } from '../../core/i18n'

interface FooterLinkInterface {
  id: number
  name: string
  link: string
}
interface FooterSectionInterface {
  footerLink: FooterLinkInterface[]
}

const StyledFooterSection = styled.div`
  .footer {
    background-color: var(--primary-blue);
    box-shadow: 0 1px 1px 0 var(--light-grey-250);
    box-sizing: border-box;
    width: 100%;
    text-align: left;
    font-size: 16px;
    font-style: normal;
    font-family: var(--font-family-primary);
    padding: 20px 30px;
    bottom: 0;
    width: 100%;
  }
  @media screen and (min-width: 2880px) {
    .footer {
      padding: 30px 60px;
      width: 100%;
      margin: 0 auto;
    }
    .dashbord__tab {
      min-height: 73vh !important;
   }
  }

  @media screen and (min-width: 3840px) {
    .footer {
      padding: 30px 120px;
      width: 100%;
      margin: 0 auto;
    }
  }
  .footer__logo,
  .footer__content,
  .footer__language {
    font-style: normal;
    max-width: 100%;
    margin: 0 auto;
    align-items: center;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    color: var(--white);
  }
  .footer__logo__assurant,
  .footer__logo__trustarc,
  .footer__content__copyright,
  .footer__content__nav,
  .footer__language__section {
    display: flex;
    margin-bottom: 10px;
  }
  .footer__logo__trustarc{
    cursor: pointer;
  }
  .footer__logo__assurant{
    pointer-events: none;
  }
  .footer__content__copyright {
    display: flex;
    flex-direction: column;
  }
  .footer__content__copyright__text{
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
  }
  .footer__content__nav__list {
    list-style-type: none;
    text-align: center;
    display: flex;
  }
  .footer__content__nav__list li {
    list-style-type: none;
    text-align: center;
    display: flex;
    margin-right: 1em;
  }
  .footer__content__nav__list li a {
    color: var(--white);
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  }
  .footer__language__section__selector {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  }
  .switch__language{
    color:color: var(--white);
    text-decoration: none;
    line-height: inherit;
    margin: 0px 10px;
    cursor: pointer;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  }
  .selected__language {
    color: var(--primary-blue);
    background-color: var(--white);
    padding: 2px 10px;
    font-weight: bold;
    border-radius: 3px;
  }
  .header__section__logo__img-arc{
    margin-right: 1em;
  }
  @media screen and (min-width: 2880px) {
    .footer {
      padding: 30px 60px;
      width: auto;
      margin: 0 auto;
    }
    .dashboard__tab {
      min-height: 73vh !important;
    }
  }
  @media screen and (min-width: 3840px) {
    .footer {
      padding: 30px 120px;
      width: 3840px;
      margin: 0 auto;
    }
  }
`
const FooterSection = (props: FooterSectionInterface): ReactElement => {
  const { i18n } = useTranslation()
  const { t } = useTranslation(namespaces.pages.footer)
  const today = new Date()
  const year = today.getFullYear()
  const { footerLink = [{ id: 0, name: '', link: '' }] } = props

  return (
    <StyledFooterSection>
      <footer className="footer">
        <div className="footer__logo">
          <div className="footer__logo__assurant">
            <AssurantLogo className="header__section__logo__img" />
          </div>
          <div className="footer__logo__trustarc">
            <a
              href="https://privacy.truste.com/privacy-seal/validation?rid=414ca734-eea5-4ccf-be7b-2f6fd2393b8b"
              rel="noopener noreferrer"
              target="_blank"
            >
              <TrustArcLogo className="header__section__logo__img-arc" />
            </a>
          </div>
        </div>
        <div className="footer__content">
          <div className="footer__content__copyright">
            <div className="footer__content__copyright__text">
              &copy;&nbsp;{year}&nbsp;
              {t('assurant_copyright')}
            </div>
          </div>
          <nav className="footer__content__nav">
            <ul className="footer__content__nav__list">
              {footerLink.map(({ name, link, id }) => {
                return (
                  <li key={id?.toString()}>
                    <a
                      key={id?.toString()}
                      href={link}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {t(name)}
                    </a>
                  </li>
                )
              })}
            </ul>
          </nav>
        </div>
        <div className="footer__language">
          <div className="footer__language__section">
            <div className="footer__language__section__selector">
              {t('select_language')}
              <a
                className={`switch__language ${
                  i18n?.language === 'en' ? 'selected__language' : ''
                }`}
                onClick={() => {
                  location.reload()
                  handleUpdateLanguage('en')
                }}
              >
                English
              </a>
              |
              <a
                className={`switch__language ${
                  i18n?.language === 'es' ? 'selected__language' : ''
                }`}
                onClick={() => {
                  location.reload()
                  handleUpdateLanguage('es')
                }}
              >
                Español
              </a>
            </div>
          </div>
        </div>
      </footer>
    </StyledFooterSection>
  )
}

export default FooterSection
