import React, {
  type ReactElement,
  useState,
  useContext,
  useEffect,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useOktaAuth } from '@okta/okta-react'
import { namespaces } from '../../core/i18n/i18n.constants'
import { api } from '../../services'
import CREATE_AGENT from '../../services/user-administration/mutations/create-agent'
import {
  isEmpty,
  isValidEmailInput,
  isValidNameInput,
} from '../../utils/common-methods'
import UPDATE_AGENT from '../../services/user-administration/mutations/update-agent'
import { UserContext } from '.'
import AlertMessage from '../../components/ui/alert'
import ModalManageUsers from '../../components/ui/modal'
import CreateEditUserForm from './create-edit-user-form'

interface CreateEditUserModalInterface {
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  tableProps?: any
  modalOpen: boolean
}

interface ErrorsInterface {
  firstNameError: string
  lastNameError: string
  emailError: string
  userRoleError: string
}

interface HandleTextFieldChangeInterface {
  name: string
  value: string
}

const CreateEditUserModal = ({
  modalOpen,
  tableProps,
  setModalOpen,
}: CreateEditUserModalInterface): ReactElement => {
  const { t } = useTranslation(namespaces.pages.manageUser)
  const { t: t1 } = useTranslation([namespaces.common.server])
  const { authState } = useOktaAuth()
  const [alertOpen, setAlertOpen] = useState(false)
  const { fetchUsers, clearFilters } = useContext(UserContext)
  const [modalOpened, setModalOpened] = useState(true)
  const [autoClose, setAutoClose] = useState(true)

  let initialValues
  if (tableProps != null) {
    initialValues = {
      firstName: tableProps.row.original.first_name,
      lastName: tableProps.row.original.last_name,
      email: tableProps.row.original.email,
      userRole: tableProps.row.original.role,
    }
  } else {
    initialValues = { firstName: '', lastName: '', email: '', userRole: '' }
  }

  const agentId = tableProps?.row?.original?.id ?? ''
  const isCreateUserFlow = isEmpty(agentId)
  const [loading, setLoading] = useState(false)
  const [refreshList, setRefreshList] = useState(false)
  const [apiMessage, setApiMessage] = useState('')
  const [severityStatus, setSeverityStatus] = useState('')
  const [formValues, setFormValues] = useState(initialValues)
  const [formErrors, setFormErrors] = useState({
    firstNameError: '',
    lastNameError: '',
    emailError: '',
    userRoleError: '',
  })

  const handleTextFieldChange = (e: {
    target: HandleTextFieldChangeInterface
  }): void => {
    const { name, value } = e.target
    setFormValues({ ...formValues, [name]: value })
    setFormErrors({ ...formErrors, [`${name}Error`]: '' })

    if (!isEmpty(apiMessage)) {
      setApiMessage('')
    }
  }

  const handleCreateAgent = (formData: any): void => {
    setLoading(true)
    const roleValue = formData.userRole.replace(/ /g, '_').toUpperCase()
    api
      .mutate({
        mutation: CREATE_AGENT,
        context: {
          headers: {
            authorization: authState?.accessToken?.accessToken,
          },
        },
        variables: {
          input: {
            firstName: formData?.firstName,
            lastName: formData?.lastName,
            email: formData?.email,
            role: roleValue,
          },
        },
        fetchPolicy: 'network-only',
      })
      .then((response: any) => {
        setApiMessage(
          response?.data?.createAgent?.success === true
            ? 'userCreatedSuccess'
            : 'userAlreadyExists'
        )
        if (response?.data?.createAgent?.success === true) {
          setAlertOpen(true)
          setRefreshList(true)
        }
        setLoading(false)
      })
      .catch((error: any) => {
        console.dir(error)
        setLoading(false)
        setApiMessage('genericAPIErrorMessage')
        setAlertOpen(true)
        setRefreshList(false)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleUpdateAgent = (formData: any): void => {
    setLoading(true)
    const roleValue = formData.userRole.replace(/ /g, '_').toUpperCase()
    api
      .mutate({
        mutation: UPDATE_AGENT,
        context: {
          headers: {
            authorization: authState?.accessToken?.accessToken,
          },
        },
        variables: {
          input: {
            id: agentId,
            firstName: formData?.firstName,
            lastName: formData?.lastName,
            role: roleValue,
          },
        },
        fetchPolicy: 'network-only',
      })
      .then((response: any) => {
        setLoading(false)
        setApiMessage(
          response?.data?.updateAgent?.success === true
            ? 'userUpdatedSuccess'
            : ''
        )
        response?.data?.updateAgent?.success === true && setAlertOpen(true)
        setRefreshList(true)
      })
      .catch((error: any) => {
        console.dir(error)
        setLoading(false)
        setApiMessage('genericAPIErrorMessage')
        setAlertOpen(true)
        setRefreshList(false)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleSubmit = (e: { preventDefault: () => void }): void => {
    e.preventDefault()
    const formValidation = validate(formValues)
    setFormErrors(formValidation)
    const { firstNameError, lastNameError, emailError, userRoleError } =
      formValidation
    if (
      firstNameError === '' &&
      lastNameError === '' &&
      emailError === '' &&
      userRoleError === ''
    ) {
      console.info('Submitting form!')
      isEmpty(agentId)
        ? handleCreateAgent(formValues)
        : handleUpdateAgent(formValues)
    }
  }

  const validate = (values: {
    firstName: string
    lastName: string
    email: string
    userRole: string
  }): ErrorsInterface => {
    const errors = {
      firstNameError: '',
      lastNameError: '',
      emailError: '',
      userRoleError: '',
    }

    /* Validate Role Field */
    if (isEmpty(values.userRole)) {
      errors.userRoleError = `${t('userRoleRequired')}`
    }

    /* Validate First Name Field */
    if (isEmpty(values.firstName)) {
      errors.firstNameError = `${t('firstNameRequired')}`
    } else {
      if (!isValidNameInput(values.firstName)) {
        errors.firstNameError = `${t('firstName')} ${t('isNotValid')}`
      }
    }

    /* Validate Last Name Field */
    if (isEmpty(values.lastName)) {
      errors.lastNameError = `${t('lastNameRequired')}`
    } else {
      if (!isValidNameInput(values.lastName)) {
        errors.lastNameError = `${t('lastName')} ${t('isNotValid')}`
      }
    }

    /* Validate Last Name Field */
    if (isCreateUserFlow) {
      if (isEmpty(values.email)) {
        errors.emailError = `${t('emailRequired')}`
      } else {
        if (!isValidEmailInput(values.email)) {
          errors.emailError = `${t('email')} ${t('isNotValid')}`
        }
      }
    }

    return errors
  }

  useEffect(() => {
    if (apiMessage === 'genericAPIErrorMessage') {
      setSeverityStatus('warning')
      setAutoClose(false)
    } else {
      setSeverityStatus('success')
      setAutoClose(true)
    }
    if (!modalOpened) {
      setModalOpen(!modalOpen)
    }
    return () => {
      if (refreshList) {
        fetchUsers()
        clearFilters(true)
      }
    }
  }, [refreshList, apiMessage, modalOpened])

  if (!isEmpty(apiMessage) && apiMessage !== 'userAlreadyExists') {
    return (
      <AlertMessage
        autoClose={autoClose}
        severity={severityStatus}
        open={alertOpen}
        message={t1(apiMessage)}
        messageFormatting={true}
        onClose={(): void => {
          clearFilters(true)
          setModalOpened(false)
          setAlertOpen(false)
          severityStatus === 'success' && fetchUsers()
        }}
      />
    )
  } else {
    return (
      <ModalManageUsers
        modalOpen={modalOpened}
        agentId={agentId}
        closeModal={(): void => {
          setModalOpened(false)
        }}
      >
        <CreateEditUserForm
          agentId={agentId}
          handleSubmit={handleSubmit}
          formErrors={formErrors}
          formValues={formValues}
          handleTextFieldChange={handleTextFieldChange}
          apiMessage={apiMessage}
          spinner={loading}
          closeModal={(): void => {
            setModalOpened(false)
          }}
        />
      </ModalManageUsers>
    )
  }
}

export default CreateEditUserModal
